import React, { ReactChild, ReactElement, PureComponent, CSSProperties } from "react";
import classNames from "classnames";

export interface ControlProps {
  className?: string;
  style?: CSSProperties;
  expanded?: boolean;
  loading?: boolean;
  iconsLeft?: React.ReactNode;
  iconsRight?: React.ReactNode;
  innerRef?(): void;
}

class Control extends PureComponent<ControlProps> {
  render() {
    const {
      className,
      expanded,
      loading,
      children,
      iconsLeft,
      iconsRight,
      innerRef,
      style
    } = this.props;

    const classes = classNames("control", className, {
      "is-loading": loading,
      "is-expanded": expanded,
      "has-icons-left": iconsLeft ? true : false,
      "has-icons-right": iconsRight ? true : false
    });

    const toRender = React.Children.map(children, (element: ReactChild) => {
      if (React.isValidElement(element)) {
        return React.cloneElement(element as ReactElement<any>);
      } else {
        return null;
      }
    });

    return (
      <div ref={innerRef} className={classes} style={style}>
        {iconsLeft}
        {toRender}
        {iconsRight}
      </div>
    );
  }
}

export const withControl = (props: ControlProps) => (component: JSX.Element) => (
  <Control {...props}>{component}</Control>
);

export default Control;
