import React, { Component, SFC, PureComponent, CSSProperties, ComponentClass } from "react";
import classNames from "classnames";
import { getColorsClasses } from "../common";

interface FormProps {
  horizontal: boolean;
}

interface FieldAddonsProps {
  addons?: boolean;
  centered?: boolean;
  right?: boolean;
}

interface FieldProps {
  className?: string;
  classNameLabel?: string;
  htmlFor?: any;
  label?: string | JSX.Element | JSX.Element[];
  help?: string | JSX.Element | JSX.Element[];
  helpState?: string;
  required?: boolean;
  flexGrowBody?: number;
  style?: CSSProperties;
  styleLabel?: CSSProperties;
  innerRef?(el: HTMLDivElement): void;
}

export class FieldVertical extends PureComponent<FieldProps & FieldAddonsProps> {
  render() {
    const {
      htmlFor,
      className,
      classNameLabel,
      label,
      help,
      addons = false,
      centered,
      right,
      style,
      styleLabel,
      children
    } = this.props;

    const classes = classNames("field", className, {
      "has-addons": addons || centered || right,
      "has-addons-centered": centered,
      "has-addons-right": right
    });

    const labelRender = label ? (
      <label
        htmlFor={htmlFor}
        className={classNames("label", classNameLabel)}
        data-required={this.props.required || false}
        style={styleLabel}
      >
        {label}
      </label>
    ) : null;

    const helpColor = getColorsClasses(this.props.helpState);
    const helpClasses = classNames("help", helpColor);
    const helpRender = help ? <p className={helpClasses}>{help}</p> : null;

    return (
      <div ref={this.props.innerRef} className={classes} style={style}>
        {labelRender}
        {children}
        {helpRender}
      </div>
    );
  }
}

export class FieldHorizontal extends PureComponent<FieldProps & FieldAddonsProps> {
  render() {
    const {
      htmlFor,
      className,
      classNameLabel,
      label,
      help,
      addons = false,
      centered,
      right,
      style,
      styleLabel,
      children
    } = this.props;

    const classes = classNames("field is-horizontal", className, {
      "has-addons": addons || centered || right,
      "has-addons-centered": centered,
      "has-addons-right": right
    });

    const labelRender = label ? (
      <label
        htmlFor={htmlFor}
        className={classNames("label", classNameLabel)}
        data-required={this.props.required || false}
      >
        {label}
      </label>
    ) : null;

    return (
      <div ref={this.props.innerRef} className={classes} style={style}>
        <div className="field-label is-normal has-text-left" style={styleLabel}>
          {labelRender}
        </div>
        <div className="field-body" style={{ flexGrow: this.props.flexGrowBody }}>
          <FieldVertical htmlFor={htmlFor} help={help} helpState={this.props.helpState}>
            {children}
          </FieldVertical>
        </div>
      </div>
    );
  }
}

export type FieldAllProps = { isHorizontal?: boolean } & FieldProps & FieldAddonsProps;
export const Field: SFC<FieldAllProps> = ({ isHorizontal = false, ...restProps }) => {
  if (isHorizontal) {
    return <FieldHorizontal {...restProps} />;
  } else {
    return <FieldVertical {...restProps} />;
  }
};

class Form extends Component<FormProps> {
  static Field: ComponentClass<FieldProps & FieldAddonsProps>;

  render() {
    return (
      <form>
        <div>{this.props.children}</div>
      </form>
    );
  }
}

Form.Field = FieldHorizontal;

export default Form;
