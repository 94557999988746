import { call, takeLatest, select, all, put } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import * as actionNames from "constant/satellite";
import * as api from "api/satellites";
import Action from "reducers/Action";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import { RESET_GALAXY } from "constant/galaxy";

function* watchFetchExtensionCount(action: any) {
  yield call(callApi, action, api.getExtensionCount, actionNames.FETCH_EXTENSIONS_COUNT_SUCESS);
}
function* watchFetchCommentaireCount(action: any) {
  yield call(callApi, action, api.getCommentaireCount, actionNames.FETCH_COMMENTAIRES_COUNT_SUCESS);
}
function* watchFetchDocumentCount(action: any) {
  yield call(callApi, action, api.getDocumentCount, actionNames.FETCH_DOCUMENTS_COUNT_SUCESS);
}
function* watchFetchConfigurateurCount(action: any) {
  yield call(
    callApi,
    action,
    api.getConfigurateurCount,
    actionNames.FETCH_CONFIGURATEURS_COUNT_SUCESS
  );
}
function* watchFetchArticulationCount(action: any) {
  yield call(
    callApi,
    action,
    api.getArticulationCount,
    actionNames.FETCH_ARTICULATIONS_COUNT_SUCESS
  );
}
function* watchFetchValidationCount(action: any) {
  yield call(callApi, action, api.getValidationCount, actionNames.FETCH_VALIDATIONS_COUNT_SUCESS);
}
function* watchFetchMailCount(action: any) {
  yield call(callApi, action, api.getMailCount, actionNames.FETCH_MAILS_COUNT_SUCESS);
}

function* watchFetchAllSatelliteCount(action: any) {
  yield all([
    yield call(callApi, action, api.getExtensionCount, actionNames.FETCH_EXTENSIONS_COUNT_SUCESS),
    yield call(
      callApi,
      action,
      api.getCommentaireCount,
      actionNames.FETCH_COMMENTAIRES_COUNT_SUCESS
    ),
    yield call(callApi, action, api.getDocumentCount, actionNames.FETCH_DOCUMENTS_COUNT_SUCESS),
    yield call(
      callApi,
      action,
      api.getConfigurateurCount,
      actionNames.FETCH_CONFIGURATEURS_COUNT_SUCESS
    ),
    yield call(
      callApi,
      action,
      api.getArticulationCount,
      actionNames.FETCH_ARTICULATIONS_COUNT_SUCESS
    ),
    yield call(callApi, action, api.getValidationCount, actionNames.FETCH_VALIDATIONS_COUNT_SUCESS),
    yield call(callApi, action, api.getMailCount, actionNames.FETCH_MAILS_COUNT_SUCESS)
  ]);
}

function* callApi(
  action: Action<{ tableName: string; id: number }>,
  apiFunction: any,
  fetchName: string
) {
  try {
    const tableName = action.payload.tableName;
    const id = action.payload.id;

    if (tableName === undefined || id === undefined) {
      yield put({
        type: fetchName,
        payload: 0
      });
      return;
    }

    const response: AxiosResponse<number> = yield call(
      apiFunction,
      action.payload.tableName,
      action.payload.id
    );

    // Remplacer par le retour de l'api
    const count = response.data;
    yield put({
      type: fetchName,
      payload: count
    });
  } catch {
    console.error(`Erreur lors de l'appel à ${apiFunction}`);
    const message: Message = {
      code: "",
      message: t("commun_erreur_processus"),
      type: "DANGER",
      target: "GLOBAL"
    };
    yield put(addMessage(message));
  }
}

// export d'une liste qui est utilisée dans l'index des saga
export default [
  takeLatest(actionNames.FETCH_SATELLITES_ALL_COUNT, watchFetchAllSatelliteCount),
  takeLatest(actionNames.FETCH_COMMENTAIRES_COUNT, watchFetchCommentaireCount),
  takeLatest(actionNames.FETCH_EXTENSIONS_COUNT, watchFetchExtensionCount),
  takeLatest(actionNames.FETCH_DOCUMENTS_COUNT, watchFetchDocumentCount),
  takeLatest(actionNames.FETCH_CONFIGURATEURS_COUNT, watchFetchConfigurateurCount),
  takeLatest(actionNames.FETCH_ARTICULATIONS_COUNT, watchFetchArticulationCount),
  takeLatest(actionNames.FETCH_VALIDATIONS_COUNT, watchFetchValidationCount),
  takeLatest(actionNames.FETCH_MAILS_COUNT, watchFetchMailCount),
  takeLatest(RESET_GALAXY, watchFetchAllSatelliteCount)
];
