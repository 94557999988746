import Analytics, { AnalyticsPlugin, AnalyticsInstance } from "analytics";

import { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import history from "customHistory";

import { URL_ADN } from "customGlobal";
import auth from "auth";

export const analytics = Analytics({
  app: "app:universe",
  version: "2.1.5.13",
  plugins: [analyticsAxinInternal()]
});

history.listen(() => {
  analytics.page();
});

if (process.env.NODE_ENV !== "production") {
  (window as any).analytics = analytics;
}

export function useAnalyticsPluginsActive() {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const disablePluginsListener = analytics.on(
      "disablePlugin",
      ({ payload: { name }, instance }) => {
        const onEventDisabled = typeof name === "string" ? [name] : (name as string[]);
        const ana = instance as AnalyticsInstance;

        const mapPlugins = ana.getState("plugins");
        const pluginsName = Object.keys(mapPlugins);
        let isActive = false;

        for (let pluginName of pluginsName) {
          if (mapPlugins[pluginName].enabled && !onEventDisabled.includes(pluginName)) {
            isActive = true;
          }
        }

        setIsActive(isActive);
      }
    );

    const enablePluginsListener = analytics.on(
      "enablePlugin",
      ({ payload: { name }, instance }) => {
        const onEventEnabled = typeof name === "string" ? [name] : (name as string[]);
        const ana = instance as AnalyticsInstance;

        const mapPlugins = ana.getState("plugins");
        const pluginsName = Object.keys(mapPlugins);
        let isActive = true;
        for (let pluginName of pluginsName) {
          if (!mapPlugins[pluginName].enabled && !onEventEnabled.includes(pluginName)) {
            isActive = false;
          }
        }

        setIsActive(isActive);
      }
    );

    return () => {
      disablePluginsListener();
      enablePluginsListener();
    };
  }, []);

  return isActive;
}

export function track(
  event: string,
  payload?: string | Record<string, any>,
  callback?: (...params: any[]) => any
) {
  const options = typeof payload === "string" ? { status: payload } : payload;
  analytics.track(event, options, callback);
}

export function disablePlugins() {
  const plugins = Object.keys(analytics.getState("plugins"));
  analytics.disablePlugin(plugins);
}

export function enablePlugins() {
  const plugins = Object.keys(analytics.getState("plugins"));
  analytics.enablePlugin(plugins);
}

interface AnalyticsPageEvent {
  timestamp: string;
  userId: string;
  module: string | null;
}

interface AnalyticsTrackEvent {
  event: string;
  module: string | null;
  timestamp: string;
  userId: string;
  options: Record<string, any>;
}

interface AnalyticsAxinInternalConfig {
  queuePages: AnalyticsPageEvent[];
  queueTracks: AnalyticsTrackEvent[];
}

function analyticsAxinInternal(
  config: AnalyticsAxinInternalConfig = { queuePages: [], queueTracks: [] }
): AnalyticsPlugin {
  const MAX_LENGTH_EVENTS = 20;

  let status: "idle" | "pending" = "idle";
  function sendAnalytics() {
    if (status === "pending") return;

    async function send(
      type: "pages" | "tracks",
      toSend: (AnalyticsPageEvent | AnalyticsTrackEvent)[]
    ): Promise<"done" | "error" | "cancelled"> {
      if (toSend.length === 0) return "cancelled";

      status = "pending";

      const blob = new Blob([JSON.stringify(toSend)], { type: "application/json" });

      try {
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + auth.token);
        headers.append("Content-type", "application/json");

        const res = await fetch(`${URL_ADN()}/tracking/${type}`, {
          method: "POST",
          keepalive: true,
          headers: headers,
          body: blob
        });
        const data = await res.json();
        return data === true ? "done" : "error";
      } catch {
        console.error("[error:analytics:axin-internal] during sync of analytics ");
        return "error";
      } finally {
        status = "idle";
      }
    }

    const pages = config.queuePages.slice();
    const tracks = config.queueTracks.slice();
    Promise.all([send("pages", pages), send("tracks", tracks)]).then(([pagesDone, tracksDone]) => {
      // si on a bien receptionné les pages, on vide
      if (pagesDone === "done") {
        config.queuePages = config.queuePages.filter(page => {
          // on peut faire un === parce que la copie avec .slice pointe sur le même objet en mémoire.
          return pages.findIndex(old => old === page) === -1;
        });

        if (process.env.NODE_ENV !== "production") {
          console.log("[log:analytics:axin-internal] clear queue pages:", config.queuePages);
        }
      }
      // si on a bien receptionné les events, on vide
      if (tracksDone === "done") {
        config.queueTracks = config.queueTracks.filter(page => {
          // on peut faire un === parce que la copie avec .slice pointe sur le même objet en mémoire.
          return tracks.findIndex(old => old === page) === -1;
        });

        if (process.env.NODE_ENV !== "production") {
          console.log("[log:analytics:axin-internal] clear queue tracks:", config.queueTracks);
        }
      }
    });
  }

  return {
    name: "axin-internal",
    config,
    initialize: () => {
      window.addEventListener("beforeunload", function beforeUnloadPagesEvents() {
        sendAnalytics();
      });

      window.addEventListener("visibilitychange", function visibilityChangePagesEvents() {
        if (document.hidden) {
          sendAnalytics();
        }
      });
    },
    page: ({ payload, instance }: any) => {
      const timestamp = new Date(payload.meta.timestamp).toISOString();
      const url = payload.properties.path;
      const userId = instance.getState("user.userId");

      const data: AnalyticsPageEvent = { timestamp, userId, module: getModuleByPath(url) };
      if (data.module != null) config.queuePages.push(data);

      if (config.queuePages.length > MAX_LENGTH_EVENTS) {
        sendAnalytics();
      }
    },
    track: ({ payload, instance }) => {
      const page = instance.getState("page");
      const path = page.path;
      const module = getModuleByPath(path);
      const userId = instance.getState("user.userId");

      const data: AnalyticsTrackEvent = {
        event: payload.event,
        module,
        timestamp: new Date(payload.meta.timestamp).toISOString(),
        userId,
        options: payload.properties
      };
      if (data.module != null && data.event != null) config.queueTracks.push(data);

      if (config.queueTracks.length > MAX_LENGTH_EVENTS) {
        sendAnalytics();
      }
    },
    ready: () => {
      console.log("[axin-internal]: ready");
    }
  };
}

function getModuleByPath(url: string) {
  const matchPage = matchPath<{ module: string }>(url, {
    path: "/page/:module",
    strict: false,
    exact: false
  });
  const matchHome = matchPath(url, { path: "/", strict: true, exact: true });

  if (matchHome != null) {
    return "HOME";
  } else if (matchPage != null) {
    return matchPage.params.module;
  } else return null;
}
