import React, { FC, useRef, useLayoutEffect } from "react";
import { Draggable } from "@fullcalendar/interaction";
import GenericMarkdownDisplay from "composants/genericDisplay/GenericMarkdownDisplay";
import { Task } from "containers/scheduler/FullCalendarContainer";
import { Button, Stack } from "@axin-org/comet";
import { useTranslation } from "react-i18next";

/**
 * Composant qui créer une carte dans la lsite de suggestion et la lsite pin.
 * Le style par défaut dans la librairie est "has-background-info has-text-white".
 * On copie donc ici ce fonctionnement.
 *
 * @param props = {task} Les propriétées de la tache en cour.
 */
const DraggableElement: FC<{
  task: Task;
  cancelPinTask?(task: Task): void;
}> = props => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [t] = useTranslation();

  useLayoutEffect(() => {
    let draggableItem: Draggable | null = null;
    if (ref.current != null) {
      draggableItem = new Draggable(ref.current);
    }
    return () => {
      draggableItem != null && draggableItem.destroy();
    };
  }, []);

  const eventInfo = JSON.stringify(props.task);

  return (
    <div
      ref={ref}
      id={props.task.id}
      data-event={eventInfo}
      className={`media has-background-info has-text-white ${
        props.task.className ? props.task.className : ""
      }`}
      style={{ cursor: "grab", padding: "0.5em", borderRadius: "5px" }}
    >
      <Stack>
        <GenericMarkdownDisplay value={props.task.title} />
        {props.cancelPinTask && (
          <Button
            variant="text"
            intent="secondary"
            onClick={() => props.cancelPinTask && props.cancelPinTask(props.task)}
          >
            {t("commun_annuler")}
          </Button>
        )}
      </Stack>
    </div>
  );
};

/**
 * Composant de création d'une liste de carte.
 * Utiliser pour le pin.
 */
export const SchedulerDndList: FC<{
  events: Task[];
  disabled: boolean;
  cancelPinTask?(task: Task): void;
}> = props => {
  return (
    <div className="box" style={{ minHeight: "2em", margin: "0.1em", padding: 0 }}>
      {props.disabled &&
        props.events.map(e => (
          <div
            key={e.key}
            id={e.id}
            className={`media has-background-info has-text-white ${e.className ? e.className : ""}`}
            style={{ padding: "0.5em", borderRadius: "5px", cursor: "progress" }}
          >
            <GenericMarkdownDisplay value={e.title} />
          </div>
        ))}
      {!props.disabled &&
        props.events.map(e => (
          <DraggableElement task={e} key={e.key} cancelPinTask={props.cancelPinTask} />
        ))}
    </div>
  );
};

/**
 * Composant de création d'une carte.
 * Utiliser pour les suggestion.
 */
export const SchedulerDndCell: FC<{
  task: Task;
  disabled: boolean;
  cancel?(task: Task): void;
}> = props => {
  return (
    <div style={{ minHeight: "2em", margin: "0.1em", padding: 0 }}>
      {props.disabled && (
        <div
          id={props.task.id}
          className={`media ${props.task.className ? props.task.className : ""}`}
          style={{ padding: "0.5em", borderRadius: "5px", cursor: "progress" }}
        >
          <GenericMarkdownDisplay value={props.task.title} />
        </div>
      )}
      {!props.disabled && <DraggableElement task={props.task} cancelPinTask={props.cancel} />}
    </div>
  );
};
