import React, { Component, useRef, useCallback } from "react";

import { ReducerState } from "reducers";
import { withTranslation, WithTranslation } from "react-i18next";
import { EmailDestinataireState, EmailState } from "types/Processus";
import { connect } from "react-redux";

import { convertValue } from "utils/entities.utils";
import classNames from "classnames";
import { URL_DATA, URL_DOWNLOAD_VIA_UTILS } from "customGlobal";
import Cookie from "js-cookie";
import { RouteComponentProps } from "react-router-dom";

/*************** ACTIONS **************/
import {
  sendEmail,
  closeEmail,
  addDestinataire,
  onSubjectChange,
  onBodyChange,
  clearEmailReducer,
  addAdditionnalAttachment,
  deleteAdditionalAttachment,
  deleteAttachment,
  setMailIndex,
  loadMailTemplate
} from "actions/email.action";

/************** COMPOSANTS ************/
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Input } from "composants/input/Input";
import { Field } from "../form";
import Control from "../form/Control";
import FileUpload from "../file/FileUpload";
import Modal from "composants/Modal/Modal";
import { Pagination } from "composants/datatable/Pagination";

/************* API *******************/
import { getDestinataires, getMailTemplate } from "api/email";
import RafComponent from "../RafComponent";
import { getUIContext } from "api/common";
import TextEditor from "../RichTextEditor/TextEditor";
import { t } from "utils/i18n";
import ModalViewver from "composants/Modal/ModalViewer";
import { Row, Col } from "composants/Layout";
import { GedFileSelector } from "composants/satellite/GedFileSelector";
import { Document } from "composants/satellite/TabDocument";
import SelectLazyList from "composants/select/SelectLazyList";
import Fuse from "fuse.js";
import { debounce } from "lodash-es";
import auth from "auth";

interface MailState {
  options: EmailDestinataireState[];
  // ce sont des pièces jointes ajoutées par l'utilisateur et qui peuvent etre supprimé sur le serveur
  displayMailAttachmentPath: string | null;
  body: string;
}

interface MailProps {
  currentMailIndex: number;
  selectedOptionTo: EmailDestinataireState[] | null;
  selectedOptionCc: EmailDestinataireState[] | null;
  selectedOptionBcc: EmailDestinataireState[] | null;
  subject: string;
  body: string;
  isLoading: boolean;
  tableName: string | null;
  piecesJointes: Record<string, string>;
  additionnalAttachment: Record<string, { name: string; fromGED: boolean }>;
  nbMail: number;
  entityId: string | null;
}

interface MailReduxFn {
  sendEmail(updateBody: string): void;
  closeEmail(): void;
  addDestinataire(typeDest: string, dest: EmailDestinataireState[]): void;
  onSubjectChange(value: string): void;
  onBodyChange(value: string): void;
  clearEmailReducer(): void;
  addAdditionnalAttachment(response: Record<string, { name: string; fromGED: boolean }>): void;
  deleteAdditionalAttachment(key: string, fromGED: boolean): void;
  deleteAttachment(key: string): void;
  setMailIndex(index: number): void;
  loadMailTemplate(mailTable: string, mailTableId: string, mailTemplate: string): void;
}

type MailAllProps = MailProps & MailReduxFn & WithTranslation & RouteComponentProps;

class Mail extends Component<MailAllProps, MailState> {
  state: MailState = {
    options: [],
    displayMailAttachmentPath: null,
    body: this.props.body
  };

  private fuse = new Fuse<EmailDestinataireState, {}>([], {
    shouldSort: true,
    tokenize: true,
    threshold: 0.33,
    keys: [
      {
        name: "label",
        weight: 0.8
      },
      { name: "value", weight: 0.2 }
    ]
  });

  handleChangeTo = (selectedOption: EmailDestinataireState[]) => {
    this.props.addDestinataire("TO", selectedOption);
  };

  handleChangeCc = (selectedOption: EmailDestinataireState[]) => {
    this.props.addDestinataire("CC", selectedOption);
  };

  handleChangeBcc = (selectedOption: EmailDestinataireState[]) => {
    this.props.addDestinataire("BCC", selectedOption);
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    if (
      urlParams.has("mailTemplate") &&
      urlParams.has("mailTable") &&
      urlParams.has("mailTableId")
    ) {
      this.props.loadMailTemplate(
        urlParams.get("mailTable") ?? "",
        urlParams.get("mailTableId") ?? "",
        urlParams.get("mailTemplate") ?? ""
      );
    }

    getDestinataires()
      .then(res => {
        this.setState({ options: res.data });
        this.fuse.setCollection(res.data);
      })
      .catch(() => {
        console.error("error during fetch destinataires");
        this.setState({
          options: []
        });
      });
  }

  componentDidUpdate(prevProps: MailAllProps) {
    if (prevProps.body !== this.props.body && this.props.body !== this.state.body) {
      this.setState({ body: this.props.body });
    }
  }

  textEditorChange = (currentText: string) => {
    this.setState({ body: currentText }, () => {
      this.props.onBodyChange(currentText);
    });
  };

  selectGedDocument = (selected: Document) => {
    // Création d'un objet au format attendu par le reducer à partir d'un document
    const selectedDoc = { [selected.url]: { name: selected.nom, fromGED: true } };
    this.props.addAdditionnalAttachment(selectedDoc);
  };

  filterOptions = debounce((inputValue: string, callback: (param: any) => void) => {
    let list: EmailDestinataireState[];
    if (inputValue === "") {
      list = this.state.options;
    } else {
      list = this.fuse.search(inputValue);
    }
    callback(list);
  }, 300);

  getSelected = () => {
    let selected: string[] = [];
    if (this.props.additionnalAttachment) {
      selected = selected.concat(Object.keys(this.props.additionnalAttachment));
    }
    if (this.props.piecesJointes) {
      selected = selected.concat(
        Object.keys(this.props.piecesJointes).map(key => this.props.piecesJointes[key])
      );
    }
    return selected;
  };

  onLoadFilepond = (response: string) => {
    const data = JSON.parse(response);
    let r: Record<string, { name: string; fromGED: boolean }> = {};

    Object.keys(data).forEach(key => (r[key] = { name: data[key], fromGED: false }));
    this.props.addAdditionnalAttachment(r);
  };

  render() {
    const loadingClass = classNames("email", {
      "is-loading": this.props.isLoading
    });

    const disableFooterButtons = this.props.isLoading;
    const baseUploadUrl = URL_DATA() + "/mail";
    const uploadUrlProcess = `/uploadFile`;

    return (
      <Modal
        show
        onClose={(e: any) => {
          this.props.clearEmailReducer();
          this.props.closeEmail();
        }}
        onValidate={(e: any) => {
          this.props.sendEmail(this.state.body);
        }}
        title="Email"
        minWidth="30vw"
        minHeight="30vh"
        disableFooterButton={disableFooterButtons}
        valideButtonTitle="commun_envoyer"
      >
        {this.props.nbMail > 1 ? (
          <Pagination
            totalRecords={this.props.nbMail - 1}
            first={this.props.currentMailIndex}
            pageSize={1}
            isCentered
            paginationChange={this.onPaginationChange}
          />
        ) : null}
        <div className={loadingClass}>
          <Field label={this.props.t("commun_destinataireTo")} isHorizontal flexGrowBody={5}>
            <Control>
              <AsyncCreatableSelect
                placeholder={t("commun_recherche")}
                isClearable
                defaultOptions={this.state.options}
                cacheOptions
                loadOptions={this.filterOptions}
                value={this.props.selectedOptionTo}
                onChange={this.handleChangeTo}
                options={this.state.options}
                components={{ MenuList: SelectLazyList }}
                isMulti
              />
            </Control>
          </Field>
          <Field label={this.props.t("commun_destinataireCc")} isHorizontal flexGrowBody={5}>
            <Control>
              <AsyncCreatableSelect
                placeholder={t("commun_recherche")}
                defaultOptions={this.state.options}
                cacheOptions
                loadOptions={this.filterOptions}
                value={this.props.selectedOptionCc}
                onChange={this.handleChangeCc}
                options={this.state.options}
                components={{ MenuList: SelectLazyList }}
                isMulti
              />
            </Control>
          </Field>
          <Field label={this.props.t("commun_destinataireBcc")} isHorizontal flexGrowBody={5}>
            <Control>
              <AsyncCreatableSelect
                placeholder={t("commun_recherche")}
                defaultOptions={this.state.options}
                cacheOptions
                loadOptions={this.filterOptions}
                value={this.props.selectedOptionBcc}
                onChange={this.handleChangeBcc}
                options={this.state.options}
                components={{ MenuList: SelectLazyList }}
                isMulti
              />
            </Control>
          </Field>
          <Field label={this.props.t("commun_sujet")} isHorizontal flexGrowBody={5}>
            <Control>
              <input
                className="input"
                value={this.props.subject}
                onChange={e => this.props.onSubjectChange(convertValue(e))}
              />
            </Control>
          </Field>
          <TextEditor
            className="commentaire-editor"
            toolbar="BOTTOM"
            value={this.state.body}
            onValueChange={this.textEditorChange}
          />

          <Field className="is-grouped is-grouped-multiline" style={{ minHeight: 35 }}>
            {this.props.piecesJointes &&
              Object.keys(this.props.piecesJointes).map((key: string) => {
                return (
                  <Control key={key + "field"}>
                    <div className="tags has-addons">
                      <a
                        className="tag is-link"
                        key={key}
                        onClick={() => {
                          this.setState({
                            displayMailAttachmentPath: this.props.piecesJointes[key]
                          });
                        }}
                      >
                        {key}
                      </a>
                      <a
                        className="tag is-delete"
                        title="Supprimer"
                        onClick={() => this.onDeletelAttachment(key)}
                      />
                    </div>
                  </Control>
                );
              })}

            {this.props.additionnalAttachment &&
              Object.keys(this.props.additionnalAttachment).map((filePath: string) => {
                return (
                  <Control key={filePath + "field"}>
                    <div className="tags has-addons">
                      <a
                        key={filePath}
                        className="tag is-link"
                        onClick={() => {
                          this.setState({ displayMailAttachmentPath: filePath });
                        }}
                      >
                        {this.props.additionnalAttachment[filePath].name}
                      </a>
                      <a
                        className="tag is-delete"
                        title="Supprimer"
                        onClick={() =>
                          this.onDeleteAdditionnalAttachment(
                            filePath,
                            this.props.additionnalAttachment[filePath].fromGED
                          )
                        }
                      />
                    </div>
                  </Control>
                );
              })}
          </Field>
          <hr className="dropdown-divider" />

          <Row>
            <Col span={6}>
              <FileUpload
                tableName={this.props.tableName as string}
                baseURL={baseUploadUrl}
                customRoute={uploadUrlProcess}
                onLoad={this.onLoadFilepond}
              />
            </Col>
            <Col>
              <GedFileSelector
                tableName={this.props.tableName}
                entityId={this.props.entityId}
                selected={this.getSelected()}
                onSelect={this.selectGedDocument}
              />
            </Col>
          </Row>
          {this.state.displayMailAttachmentPath && (
            <ModalViewver onClose={this.onClose}>{this.ondisplayAttachment()}</ModalViewver>
          )}
        </div>
      </Modal>
    );
  }

  onPaginationChange = (index: number) => {
    this.props.setMailIndex(index);
  };

  onDeletelAttachment = (key: string) => {
    this.props.deleteAttachment(key);
  };

  onDeleteAdditionnalAttachment = (filePath: string, fromGED: boolean) => {
    this.props.deleteAdditionalAttachment(filePath, fromGED);
  };

  onClose = () => {
    this.setState({ displayMailAttachmentPath: null });
  };

  ondisplayAttachment = () => {
    const params = getUIContext();
    params.append(
      "path",
      this.state.displayMailAttachmentPath ? this.state.displayMailAttachmentPath : ""
    );

    const renderBody = () => {
      if (
        this.state.displayMailAttachmentPath &&
        this.state.displayMailAttachmentPath.includes(".pdf")
      ) {
        return (
          <object
            data={`${URL_DOWNLOAD_VIA_UTILS()}/document?${params}?access_token=${auth.token}`}
            type="application/pdf"
            style={{ height: "90vh", width: "100%" }}
          >
            {t("commun_impossible_d_afficher_le_document")}
          </object>
        );
      } else {
        return (
          <img
            src={`${URL_DOWNLOAD_VIA_UTILS()}/document?${params}&access_token=${auth.token}`}
            style={{ height: "90vh", maxWidth: "100%" }}
          />
        );
      }
    };

    let body = <RafComponent>{renderBody}</RafComponent>;
    return body;
  };
}

function mapStateToProps(state: ReducerState) {
  const mailStruct = state.email.mailStruct;
  const currentMailIndex = state.email.currentIndex;
  let selectedOptionTo = null;
  let selectedOptionCc = null;
  let selectedOptionBcc = null;
  let subject = "";
  let body = "";
  let tableName: string | null = null;
  let piecesJointes = {};
  const isLoading = state.email.isLoading;
  let additionnalAttachment = {};
  let nbMail = 0;
  let entityId: string | null = null;
  if (mailStruct && mailStruct[currentMailIndex]) {
    selectedOptionTo = mailStruct[currentMailIndex].selectedEmailTo;
    selectedOptionCc = mailStruct[currentMailIndex].selectedEmailCc;
    selectedOptionBcc = mailStruct[currentMailIndex].selectedEmailBcc;
    subject = mailStruct[currentMailIndex].sujet;
    body = mailStruct[currentMailIndex].corps ?? "";
    tableName = mailStruct[currentMailIndex].tableName;
    piecesJointes = mailStruct[currentMailIndex].piecesJointes;
    additionnalAttachment = mailStruct[currentMailIndex].additionnalAttachment;
    nbMail = mailStruct.length;
    entityId = mailStruct[currentMailIndex].entityId;
  }

  if (tableName === null && state.satellites.context.tableName) {
    tableName = state.satellites.context.tableName;
  }

  if (entityId === null && state.satellites.context.id) {
    entityId = state.satellites.context.id;
  }

  return {
    currentMailIndex,
    selectedOptionTo,
    selectedOptionCc,
    selectedOptionBcc,
    subject,
    body,
    isLoading,
    tableName,
    piecesJointes,
    additionnalAttachment,
    nbMail,
    entityId
  };
}

export default withTranslation()(
  connect<MailProps, MailReduxFn>(mapStateToProps, {
    sendEmail,
    closeEmail,
    addDestinataire,
    onSubjectChange,
    onBodyChange,
    clearEmailReducer,
    addAdditionnalAttachment,
    deleteAdditionalAttachment,
    deleteAttachment,
    setMailIndex,
    loadMailTemplate
  })(Mail)
);
