import { call, put, takeLatest, select, fork } from "redux-saga/effects";
import {
  ADMIN_CREATOR_GET_TABLE,
  ADMIN_CREATOR_GET_DEFINITION,
  ADMIN_CREATOR_SET_BLOCKS,
  CREATOR_SAVE_SUCESS,
  SAVE_CREATOR_PICKLIST_RESULT,
  CREATOR_SAVE_ERROR
} from "constant/adminCreator";
import Action from "reducers/Action";
import { AxiosResponse } from "axios";
import { Pojo } from "types/Galaxy";
import { findAll, savePickListResultApi } from "api";
import { FIND_ONE_SUCCESS, ADD_ALL_ENTITIES, GET_CREATOR_ASSOCIATIONS } from "constant/entities";
import { GET_ALL_ROLE, GET_ALL_USER } from "constant/common";
import { addAllEntities, removeEntities } from "actions";
import { getCreatorAssociations } from "api/adminCreator";

function* fetchTable(action: Action<{ tableName: string }>) {
  try {
    const response: AxiosResponse<{ data: Pojo[]; meta: { totalRecords: number } }> = yield call(
      findAll,
      {
        tableName: "syjTables",
        filter: "q=sjtaName==" + action.payload.tableName
      }
    );

    // On attend une seule réponse
    if (response.data.meta && response.data.meta.totalRecords) {
      yield put({
        type: FIND_ONE_SUCCESS,
        payload: {
          sjmoCode: "ADMIN_CREATOR",
          value: response.data.data,
          tableName: action.payload.tableName,
          cle: response.data.data[0].id
        }
      });
    }
  } catch (e) {
    console.log(`erreur lors de la récupération de la table ${action.payload.tableName}`);
  }
}

function* fetchDefinition(action: Action<{ focusId: number }>) {
  try {
    const response: AxiosResponse<{ data: Pojo[]; meta: { totalRecords: number } }> = yield call(
      findAll,
      {
        tableName: "syjCreatorFocusCreator",
        includeJoinParent: true,
        filter: "q=syjCreatorFocusId.id==" + action.payload.focusId
      }
    );

    yield put({
      type: ADD_ALL_ENTITIES,
      payload: {
        sjmoCode: "ADMIN_CREATOR",
        tableName: "syjCreatorFocusCreator",
        pojos: response.data.data,
        reset: true
      }
    });
    yield put({
      type: ADMIN_CREATOR_SET_BLOCKS,
      payload: response.data.data.map(pojo => pojo.syjCreatorId.id)
    });
  } catch (e) {
    console.log(
      `Une erreur s'est produite lors de la récupération de la définition du focus ${
        action.payload.focusId
      }`,
      e
    );
  }
}

function* fetchAssociations(action: Action<{ sjmoCode: string; focusId: string }>) {
  try {
    const { sjmoCode, focusId } = action.payload;
    const response = yield call(getCreatorAssociations, sjmoCode, focusId);
    if (response.data && response.data.GLOBAL.length > 0) {
      yield put(addAllEntities(sjmoCode, "syjCreatorFocusGlobal", response.data.GLOBAL, false));
    }
    if (response.data && response.data.ROLE.length > 0) {
      yield put(addAllEntities(sjmoCode, "syjCreatorFocusRole", response.data.ROLE, false));
    }
    if (response.data && response.data.USER.length > 0) {
      yield put(addAllEntities(sjmoCode, "syjCreatorFocusUser", response.data.USER, false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchAllRole(action: Action<{ sjmoCode: string }>) {
  try {
    const response = yield call(findAll, {
      sjmoCode: action.payload.sjmoCode,
      tableName: "sysMenuGroupe",
      size: 1000
    });

    yield put(addAllEntities(action.payload.sjmoCode, "sysMenuGroupe", response.data.data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchAllUser(action: Action<{ sjmoCode: string }>) {
  try {
    const response = yield call(findAll, {
      sjmoCode: action.payload.sjmoCode,
      tableName: "personnel",
      size: 1000
    });

    yield put(addAllEntities(action.payload.sjmoCode, "personnel", response.data.data));
  } catch (e) {
    console.log(e);
  }
}

function* watchSaveCreatorPickListResult(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    chosen: Pojo[];
    oldIdToDelete: string[];
  }>
) {
  const { sjmoCode, tableName, chosen, oldIdToDelete } = action.payload;
  try {
    const response: AxiosResponse<Pojo[]> = yield call(
      savePickListResultApi,
      sjmoCode,
      tableName,
      chosen,
      oldIdToDelete
    );

    yield put(removeEntities(sjmoCode, tableName, oldIdToDelete));
    yield put(addAllEntities(sjmoCode, tableName, response.data, true));
    yield put({ type: CREATOR_SAVE_SUCESS, payload: undefined });
  } catch (e) {
    console.log(e);
    yield put({ type: CREATOR_SAVE_ERROR, payload: undefined });
  }
}

export default [
  takeLatest(ADMIN_CREATOR_GET_TABLE, fetchTable),
  takeLatest(ADMIN_CREATOR_GET_DEFINITION, fetchDefinition),
  takeLatest(GET_CREATOR_ASSOCIATIONS, fetchAssociations),
  takeLatest(GET_ALL_ROLE, fetchAllRole),
  takeLatest(GET_ALL_USER, fetchAllUser),
  takeLatest(SAVE_CREATOR_PICKLIST_RESULT, watchSaveCreatorPickListResult)
];
