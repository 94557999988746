import immer from "immer";
import Action from "reducers/Action";

import {
  SET_EMAIL_STRUCT,
  ADD_DESTINATAIRE,
  ON_SUBJECT_CHANGE,
  ON_BODY_CHANGE,
  START_EMAIL_LOADER,
  STOP_EMAIL_LOADER,
  ADD_ADDITIONNAL_ATTACHMENT,
  DELETE_ADDTIONNAL_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT,
  SET_MAIL_INDEX,
  CLEAR_EMAIL_REDUCER,
  REMOVE_SENDED_EMAIL,
  LOAD_MAIL_TEMPLATE
} from "constant/email";
import { EmailState } from "types/Processus";

// State redux d'un email (peut etre contextualisé par un processus)
export interface EmailReduxState {
  mailStruct: EmailState[] | null;
  currentIndex: number;
  isLoading: boolean;
}

// type alias pour l'action dans le reducer de dashboard
type EmailAction = Action<any>;

const initialState = {
  mailStruct: null,
  currentIndex: 0,
  isLoading: false
};

export default function reducer(
  state: EmailReduxState = initialState,
  action: EmailAction
): EmailReduxState {
  switch (action.type) {
    case SET_EMAIL_STRUCT:
      return setStruct(state, action);

    case ADD_DESTINATAIRE:
      return addDestinataire(state, action);

    case ON_SUBJECT_CHANGE:
      return changeSubject(state, action);

    case ON_BODY_CHANGE:
      return changeBody(state, action);

    case START_EMAIL_LOADER:
      return immer(state, draft => {
        draft.isLoading = true;
      });

    case STOP_EMAIL_LOADER:
      return immer(state, draft => {
        draft.isLoading = false;
      });

    case ADD_ADDITIONNAL_ATTACHMENT:
      return addAdditionnalAttachment(state, action);

    case DELETE_ADDTIONNAL_ATTACHMENT_SUCCESS:
      return deleteAdditionnalAttachment(state, action);

    case DELETE_ATTACHMENT:
      return deleteAttachment(state, action);

    case SET_MAIL_INDEX:
      return immer(state, draft => {
        draft.currentIndex = action.payload.index;
      });

    case CLEAR_EMAIL_REDUCER:
      return immer(state, draft => {
        return initialState;
      });

    case REMOVE_SENDED_EMAIL:
      return immer(state, draft => {
        if (draft.mailStruct) {
          draft.mailStruct.splice(draft.currentIndex, 1);
        }
      });

    case LOAD_MAIL_TEMPLATE:
      return immer(state, draft => {
        draft.mailStruct = action.payload;
        draft.currentIndex = 0;
      });

    default:
      return state;
  }
}

function deleteAttachment(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const key = action.payload.key;
  return immer(state, draft => {
    if (draft.mailStruct) {
      delete draft.mailStruct[draft.currentIndex].piecesJointes[key];
    }
  });
}

function deleteAdditionnalAttachment(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const fileKey = action.payload.fileKey;
  return immer(state, draft => {
    if (draft.mailStruct) {
      delete draft.mailStruct[draft.currentIndex].additionnalAttachment[fileKey];
    }
  });
}

function addAdditionnalAttachment(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const response = action.payload.response;
  return immer(state, draft => {
    if (!draft.mailStruct) {
      draft.mailStruct = [];
    }

    if (!draft.mailStruct[draft.currentIndex]) {
      draft.mailStruct[draft.currentIndex] = {} as any;
    }

    if (!draft.mailStruct[draft.currentIndex].additionnalAttachment) {
      draft.mailStruct[draft.currentIndex].additionnalAttachment = {};
    }

    draft.mailStruct[draft.currentIndex].additionnalAttachment = {
      ...draft.mailStruct[draft.currentIndex].additionnalAttachment,
      ...response
    };
  });
}

function changeBody(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const body = action.payload.value;
  return immer(state, draft => {
    if (!draft.mailStruct) {
      draft.mailStruct = [];
    }

    if (!draft.mailStruct[draft.currentIndex]) {
      draft.mailStruct[draft.currentIndex] = {} as any;
    }
    draft.mailStruct[draft.currentIndex].corps = body;
  });
}

function changeSubject(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const subject = action.payload.value;
  return immer(state, draft => {
    if (!draft.mailStruct) {
      draft.mailStruct = [];
    }

    if (!draft.mailStruct[draft.currentIndex]) {
      draft.mailStruct[draft.currentIndex] = {} as any;
    }

    draft.mailStruct[draft.currentIndex].sujet = subject;
  });
}

function addDestinataire(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const type = action.payload.typeDest;
  const destinataire = action.payload.dest;
  return immer(state, draft => {
    if (!draft.mailStruct) {
      draft.mailStruct = [];
    }

    if (!draft.mailStruct[draft.currentIndex]) {
      draft.mailStruct[draft.currentIndex] = {} as any;
    }
    if (type === "TO") {
      draft.mailStruct[draft.currentIndex].selectedEmailTo = destinataire;
    }

    if (type === "CC") {
      draft.mailStruct[draft.currentIndex].selectedEmailCc = destinataire;
    }

    if (type === "BCC") {
      draft.mailStruct[draft.currentIndex].selectedEmailBcc = destinataire;
    }
  });
}

function setStruct(state: EmailReduxState, action: EmailAction): EmailReduxState {
  const emailStruct = action.payload.mailStruct;
  return immer(state, draft => {
    draft.mailStruct = emailStruct;
  });
}
