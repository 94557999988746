import React, { SyntheticEvent, FC } from "react";
import { Transition } from "react-spring/renderprops";

import "./css/modal.css";
import { t } from "utils/i18n";
import { Portal, PortalProvider } from "../Portal";
import { uuidv4 } from "utils/uuid.utils";
import { useLockBodyScroll } from "./useLockBodyScroll";

interface ModalProps {
  id?: string;
  title?: React.ReactNode;
  show?: boolean;
  hideFooter?: boolean;
  hideBackground?: boolean;
  hideValidate?: boolean;
  hideCancel?: boolean;
  width?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  height?: string | number;
  disableFooterButton?: boolean;
  animation?: boolean;
  validateComponent?: React.ReactNode;
  valideButtonTitle?: string;
  onValidate?(e: SyntheticEvent<HTMLElement>): void;
  onClose(e: SyntheticEvent<HTMLElement>): void;
}

const Modal: FC<ModalProps> = ({
  id,
  show,
  width,
  height,
  minWidth,
  minHeight,
  hideBackground,
  hideFooter,
  hideValidate,
  hideCancel,
  title,
  onClose,
  onValidate,
  validateComponent,
  children,
  disableFooterButton,
  valideButtonTitle,
  animation
}) => {
  useLockBodyScroll();

  // Mise en place d'une ombre uniquement si il n'y a pas de background
  const boxShadow = hideBackground ? "0 4px 8px 0 rgba(0, 0, 0, 0.2)" : "";

  return (
    <Portal>
      <PortalProvider domRef={id}>
        <Transition
          native
          items={show as any}
          immediate={!animation}
          config={{ duration: 200 }}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          delay={200}
        >
          {showTrs =>
            showTrs
              ? styles => {
                  return (
                    <div id={id} className="modal is-active">
                      {!hideBackground && <div className="modal-background" />}
                      <div
                        className="modal-card"
                        style={{
                          minWidth,
                          width,
                          boxShadow,
                          ...styles
                        }}
                      >
                        <header className="modal-card-head">
                          <div className="modal-card-title">{title}</div>
                          <button
                            className="delete"
                            aria-label="close"
                            onClick={onClose}
                            disabled={disableFooterButton}
                          />
                        </header>
                        <section
                          id="modalContent"
                          className="modal-card-body background-light-grey"
                          style={{ minHeight, height, position: "relative" }}
                        >
                          {children}
                        </section>
                        <footer className="modal-card-foot">
                          {!hideFooter && (
                            <>
                              {!hideValidate &&
                                (validateComponent ? (
                                  validateComponent
                                ) : (
                                  <button
                                    className="button is-success"
                                    onClick={onValidate}
                                    disabled={disableFooterButton}
                                    title={t("commun_valider")}
                                  >
                                    {valideButtonTitle ? t(valideButtonTitle) : t("commun_valider")}
                                  </button>
                                ))}

                              {!hideCancel && (
                                <button
                                  className="button"
                                  onClick={onClose}
                                  disabled={disableFooterButton}
                                >
                                  {t("commun_annuler")}
                                </button>
                              )}
                            </>
                          )}
                        </footer>
                      </div>
                    </div>
                  );
                }
              : () => null
          }
        </Transition>
      </PortalProvider>
    </Portal>
  );
};

Modal.defaultProps = {
  id: uuidv4(),
  title: "",
  show: true,
  hideBackground: false,
  hideFooter: false,
  disableFooterButton: false,
  animation: true
};

export default Modal;
