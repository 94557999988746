import { RSQLCriteria } from "rsql-criteria-typescript";
import { AxiosPromise } from "axios";
import { getUIContext, api, apiAdn } from "./common";
import { MapLookProps, SchedulerDefinition, TimeSpan } from "containers/scheduler/Scheduler";
import { format } from "date-fns";
import { Task, Resource } from "containers/scheduler/FullCalendarContainer";
import { Message } from "types/Message";
import { FindViewParams } from "./entities";
import { PagedResource } from "types/Search";
import { Pojo } from "types/Galaxy";

export function getSchedulerDefinition(sjmoCode: string): AxiosPromise<SchedulerDefinition> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/scheduler/definition?${params}`);
}

export function getSchedulerTasks(args: {
  sjmoCode: string;
  source: string;
  start?: Date;
  end?: Date;
  interactionFilter?: RSQLCriteria;
}): AxiosPromise<Task[]> {
  const { sjmoCode, source, start, end, interactionFilter } = args;
  const params = getUIContext({ sjmoCode });
  params.append("taskSource", source);

  if (start) {
    params.append("startDate", format(start));
  }
  if (end) {
    params.append("endDate", format(end));
  }

  return apiAdn.get(
    `/scheduler/task?${params}${interactionFilter ? "&" + interactionFilter.build() : ""}`
  );
}

export function getSchedulerPaginatedTask({
  sjmoCode,
  tableName,
  filter,
  first = 0,
  size = 15
}: FindViewParams): AxiosPromise<PagedResource<Pojo>> {
  const params = getUIContext({
    sjmoCode
  });
  params.append("taskSource", tableName);
  params.append("first", first.toString());
  params.append("size", size.toString());

  return apiAdn.get(`/scheduler/task/list?${params}${filter ? "&" + filter : ""}`);
}

export function getSchedulerMarkedAarea(args: {
  sjmoCode: string;
  source: string;
  timeSpan: TimeSpan;
  start: Date;
  end: Date;
  interactionFilter?: RSQLCriteria;
}): AxiosPromise<Task[]> {
  const { sjmoCode, source, timeSpan, start, end, interactionFilter } = args;
  const params = getUIContext({ sjmoCode });
  params.append("logicClass", source);
  params.append("timeSpan", timeSpan);
  params.append("startDate", format(start));
  params.append("endDate", format(end));
  return api.get(
    `/scheduler/marked-area?${params}${interactionFilter ? "&" + interactionFilter.build() : ""}`
  );
}

export function getSchedulerResources(args: {
  sjmoCode: string;
  source: string;
  interactionFilter?: RSQLCriteria;
}): AxiosPromise<Resource[]> {
  const { sjmoCode, source, interactionFilter } = args;
  const params = getUIContext({ sjmoCode });
  params.append("resourceSource", source);

  return apiAdn.get(
    `/scheduler/resource?${params}${interactionFilter ? "&" + interactionFilter.build() : ""}`
  );
}

export function addSchedulerTask(
  task: Task,
  start: Date,
  end: Date,
  context: {
    sjmoCode: string;
    logicClassName: string;
    taskSource: string;
    interactionFilter?: RSQLCriteria;
  }
): AxiosPromise<{ tasks: Task[]; message: Message }> {
  const params = getUIContext({ sjmoCode: context.sjmoCode });
  params.append("logicClassName", context.logicClassName);
  params.append("taskSource", context.taskSource);
  params.append("startDate", format(start));
  params.append("endDate", format(end));

  return api.post(
    `/scheduler/task?${params}${
      context.interactionFilter ? "&" + context.interactionFilter.build() : ""
    }`,
    task
  );
}

export function changeSchedulerTask(
  task: Task,
  start: Date,
  end: Date,
  context: {
    sjmoCode: string;
    logicClassName: string;
    taskSource: string;
    interactionFilter?: RSQLCriteria;
  }
): AxiosPromise<{ tasks: Task[]; message: Message }> {
  const params = getUIContext({ sjmoCode: context.sjmoCode });
  params.append("logicClassName", context.logicClassName);
  params.append("taskSource", context.taskSource);
  params.append("startDate", format(start));
  params.append("endDate", format(end));

  return api.put(
    `/scheduler/task?${params}${
      context.interactionFilter ? "&" + context.interactionFilter.build() : ""
    }`,
    task
  );
}

export function deleteSchedulerTask(
  task: Task,
  start: Date,
  end: Date,
  context: {
    sjmoCode: string;
    logicClassName: string;
    taskSource: string;
    interactionFilter?: RSQLCriteria;
  }
): AxiosPromise<{ tasks: Task[]; message: Message }> {
  const params = getUIContext({ sjmoCode: context.sjmoCode });
  params.append("logicClassName", context.logicClassName);
  params.append("taskSource", context.taskSource);
  params.append("startDate", format(start));
  params.append("endDate", format(end));
  params.append("taskTableName", task.tableName as string);

  return api.delete(
    `/scheduler/task/${task.id}?${params}${
      context.interactionFilter ? "&" + context.interactionFilter.build() : ""
    }`
  );
}

export function getSchedulerHighlight(
  sjmoCode: string,
  schedulerCode: string
): AxiosPromise<{ id: string; label: string; mapLook: MapLookProps[] }[]> {
  const params = getUIContext({ sjmoCode: sjmoCode });
  params.append("schedulerCode", schedulerCode);

  return apiAdn.get(`/scheduler/highlights?${params}`);
}
