import React, { FC } from "react";
import { NotificationIntent } from "types/Notification";
import { Fa } from "composants/Icon";
import { IconName } from "@fortawesome/pro-solid-svg-icons";

function getIconForIntent(intent: NotificationIntent): { icon: IconName; color: string } {
  switch (intent) {
    case "SUCCESS":
      return { icon: "check-circle", color: "has-text-success" };
    case "WARNING":
      return { icon: "exclamation-triangle", color: "has-text-warning" };
    case "DANGER":
      return { icon: "exclamation-circle", color: "has-text-danger" };

    case "INFO":
      return { icon: "info", color: "has-text-link" };
    case "DEFAULT":
    default:
      return { icon: "info", color: "has-text-black" };
  }
}

const Alert: FC<{ intent: NotificationIntent; title: string; onRemove(): void }> = props => {
  const iconInfo = getIconForIntent(props.intent);
  return (
    <div
      className="toast-alert"
      data-intent={props.intent.toLowerCase()}
      style={{ borderRadius: 5 }}
    >
      <span className="toast-alert--icon">
        <Fa icon={["fas", iconInfo.icon]} className={iconInfo.color} />
      </span>
      <span className="flex is-fullwidth">
        <span className="flex-1">
          <header className="toast-alert--header">{props.title}</header>
          {typeof props.children === "string" ? (
            <span
              dangerouslySetInnerHTML={{ __html: props.children }}
              className="toast-alert--content"
            />
          ) : (
            props.children
          )}
        </span>
        <a className="toast-alert--delete delete is-small" onClick={props.onRemove} />
      </span>
    </div>
  );
};

export default Alert;
