import React, { FC, useEffect, useState } from "react";
import { fetchDocumentData } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { useTranslation, Trans } from "react-i18next";
import { addMessage } from "actions/messages";
import { Document } from "composants/satellite/TabDocument";
import { Button } from "composants/button";

export const GedFileSelector: FC<{
  tableName: string | null;
  entityId: string | null;
  selected: string[];
  onSelect(selected: Document): void;
}> = props => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<{ document: Document }[]>([]);

  useEffect(() => {
    if (props.tableName != null && props.entityId != null) {
      fetchDocumentData(props.tableName, props.entityId)
        .then(response => {
          const docs = response.data.reverse().map(d => {
            return { document: d, selected: false };
          });
          setDocuments(docs);
        })
        .catch(e => {
          const er = e as AxiosError;
          if (!er.response) {
            return;
          }

          const message: Message = {
            code: er.response.data.code,
            message: t(er.response.data.message),
            type: er.response.data.type,
            target: er.response.data.target
          };
          addMessage(message);
        });
    }
  }, [props.entityId, props.tableName, t]);

  return (
    <>
      <div>
        <Trans i18nKey="commun_fichier_disponible_ajout" /> :
      </div>
      {documents
        .filter(d => !props.selected.includes(d.document.url))
        .map((d, index) => (
          <Button
            key={index}
            outlined
            onClick={() => props.onSelect(d.document)}
            className="m-8"
            style={{ width: "48%" }}
          >
            {d.document.nom}
          </Button>
        ))}
    </>
  );
};
