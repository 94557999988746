import React, { FC } from "react";
import { Portal, PortalProvider } from "composants/Portal";
import { Button } from "composants/button";
import { Trans } from "react-i18next";

interface ConfirmationModalProps {
  id: string;
  top: number;
  left: number;
  save(): void;
  launchProcess(): void;
  close(): void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  id,
  top,
  left,
  save,
  launchProcess,
  close
}) => {
  // state: ProcessusMenuState = {
  //   isLoading: false,
  //   definition: [],
  //   isActive: false,
  //   showConfirmModal: false
  // };
  return (
    <Portal>
      <PortalProvider domRef={id}>
        <div
          id={id}
          className="box"
          style={{
            width: 300,
            height: 130,
            position: "absolute",
            top,
            left
          }}
        >
          <p className="is-size-7 mb-7">
            <Trans i18nKey="commun_processus_galaxy_dirty" />
          </p>
          <nav className="level">
            <div className="level-item">
              <Button className="button is-small is-success" onClick={save}>
                <Trans key="commun_sauvegarder">Sauvegarder</Trans>
              </Button>
            </div>
            <div className="level-item">
              <Button className="button is-small is-danger" onClick={launchProcess}>
                <Trans key="commun_lancer">Lancer</Trans>
              </Button>
            </div>
            <div className="level-item">
              <Button className="button is-small" onClick={close}>
                <Trans key="commun_annuler">Annuler</Trans>
              </Button>
            </div>
          </nav>
        </div>
      </PortalProvider>
    </Portal>
  );
};

export default ConfirmationModal;
