import React, {
  FC,
  ComponentState,
  useReducer,
  useMemo,
  MouseEvent,
  useCallback,
  useEffect
} from "react";
import LoaderContainer from "composants/LoaderContainer";
import { DataInteractionContext } from "hooks/useInteractions";
import MenuNavigationExpert from "composants/menu/MenuNavigationExpert";
import Modal from "composants/Modal/Modal";
import { Trans, useTranslation } from "react-i18next";
import { SuperUserLink } from "composants/userSettings/SuperUser";
import { useMachine } from "@xstate/react";
import formMachine, { isFormLoadingState } from "machine/FormMachine";
import Loader from "composants/Loader";
import { track } from "tracking";
import { useRouteMatch } from "react-router-dom";
import GroupComponent from "composants/group/GroupComponent";
import Datatable from "containers/datatable/Datatable";
import { ActionTypeData } from "reducers/Action";
import { ComponentGroupState } from "types/ComponentGroup";
import { useDispatch, useSelector } from "react-redux";
import { showContextMenu } from "actions/contextMenu";
import { getDimensionFromEvent, getOptionsByType, getReadonlyValue } from "utils/component.utils";
import { Fa } from "composants/Icon";
import classNames from "classnames";
import { SimpleComponentAndLabel } from "enum";
import { AutocompleteProps } from "composants/autocomplete/AutoComplete";
import { getCompos, convertValue } from "utils/entities.utils";
import { Pojo } from "types/Galaxy";
import { ReducerState } from "reducers";
import { getExpertGroups, getExpertHeader, findOne } from "api";

import "style/grid.css";

interface ExpertDefinitionState {
  groups: Record<string, ComponentGroupState[]>;
  header: ComponentState[];
  activeTab: number;
}

type ExpertdefinitionActions =
  | ActionTypeData<"INIT_HEADER", ComponentState[]>
  | ActionTypeData<"INIT_GROUPS", { expertId: string; groups: ComponentGroupState[] }>
  // | ActionTypeData<"INIT_CACHE", MiniExpertDefinitionState>
  // | ActionTypeData<"INIT_GROUPS", { focusId: string; groups: ComponentGroupState[] }>
  // | ActionTypeData<"CHANGE_SELECTED", string>
  | ActionTypeData<"CHANGE_ACTIVE_TAB", number>;

function reducerDefinition(
  state: ExpertDefinitionState,
  action: ExpertdefinitionActions
): ExpertDefinitionState {
  switch (action.type) {
    case "INIT_HEADER":
      return {
        ...state,
        header: action.payload
      };

    case "INIT_GROUPS":
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.expertId]: action.payload.groups
        },
        activeTab: 0
      };

    case "CHANGE_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload
      };
    default:
      return state;
  }
}

interface ExpertProps {
  sjmoCode: string;
  tableName: string;
  height: string | number;
  onClose(): void;
}

const Expert: FC<ExpertProps> = props => {
  const route = useRouteMatch<{ code: string; id: string; expertId?: string }>();

  const [t] = useTranslation();

  const expertParentProps = useMemo(() => {
    return {
      ctrlKey: "expert",
      tableName: props.tableName
    };
  }, [props.tableName]);

  const menu = useSelector(
    useCallback((state: ReducerState) => state.expert.menu[props.sjmoCode] ?? [], [props.sjmoCode])
  );
  const reduxDispatch = useDispatch();

  const [definition, dispatch] = useReducer(reducerDefinition, {
    groups: {},
    header: [],
    activeTab: 0
  });

  useEffect(() => {
    getExpertHeader(props.sjmoCode).then(res =>
      dispatch({ type: "INIT_HEADER", payload: res.data })
    );
  }, [props.sjmoCode]);

  useEffect(() => {
    const expertId = route.params.expertId;
    if (expertId) {
      getExpertGroups(props.sjmoCode, expertId).then(res =>
        dispatch({ type: "INIT_GROUPS", payload: { expertId: expertId, groups: res.data } })
      );
    }
  }, [props.sjmoCode, route.params.expertId]);

  const currentGroup = useMemo(() => {
    return route.params.expertId && definition.groups[route.params.expertId]
      ? definition.groups[route.params.expertId]
      : [];
  }, [definition.groups, route.params.expertId]);

  const cacheComponents = useMemo(() => {
    const headerCompos = definition.header ?? [];
    const groupCompos = getCompos(currentGroup) ?? [];
    return headerCompos.concat(groupCompos);
  }, [currentGroup, definition.header]);

  const [state, send] = useMachine(formMachine);

  const fetchEntity = useCallback(() => {
    // on met un IF parce que l'admin ne charge pas de donnée
    if (route.params.id) {
      const id = decodeURIComponent(route.params.id);
      findOne({ tableName: props.tableName, id, includeStyle: true }).then(res =>
        send({ type: "LOAD_ENTITY", sjmoCode: props.sjmoCode, entity: res.data })
      );
    }
  }, [props.sjmoCode, props.tableName, route.params.id, send]);

  useEffect(() => {
    fetchEntity();
  }, [fetchEntity]);

  function changeValue(field: string, value: any) {
    let currentCompo = cacheComponents.find(compo => compo.column === field);

    send({
      type: "CHANGE",
      field,
      sjmoCode: props.sjmoCode,
      compo: currentCompo?.typeCompo ?? "I",
      value,
      wvi: currentCompo?.wvi ?? false
    });
  }

  function onChange(e: React.SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value = convertValue(e);
    changeValue(field, value);
  }

  function onBlur(e: React.SyntheticEvent<any>) {
    const field: string = e.currentTarget.name;

    let currentCompo = cacheComponents.find(compo => compo.column === field);

    send({ type: "BLUR", field, sjmoCode: props.sjmoCode, wvi: currentCompo?.wvi ?? false });
  }

  function onItemChange(selectedItem: Pojo | null, field: string) {
    changeValue(field, selectedItem ? selectedItem.id : null);
  }

  function onValueChange(field: string | undefined, value: string) {
    if (!field) return;
    changeValue(field, value);
  }

  const onContextMenu = (field: string) => (event: MouseEvent<any>) => {
    if (event.ctrlKey) {
      return;
    }
    event.preventDefault();
    if (state.context.entity) {
      const dimension = getDimensionFromEvent(event);
      reduxDispatch(
        showContextMenu(
          dimension.x,
          dimension.y,
          props.sjmoCode,
          props.tableName,
          field,
          state.context.entity.id,
          state.context.entity[field],
          null, // genericEntity uniquement pour les listes génériques
          "expert",
          true
        )
      );
    }
  };

  function onClose() {
    if (state.matches("idle")) {
      props.onClose();
    }
  }

  function createHeader() {
    return definition.header.map(compo => {
      const SelectedComponent = SimpleComponentAndLabel[compo.typeCompo];
      const {
        typeCompo,
        contentSize,
        mandatory,
        joinTableName,
        joinListFields,
        joinDisplayedFields,
        additionalClause,
        sortClause,
        wvi,
        readOnly,
        disabled,
        compoVisible,
        isNumber,
        defaultValue,
        hasLov,
        ...restProps
      } = compo;

      const wviState = state.context.result[compo.column];

      const style = state.context.entity?._style ? state.context.entity._style[compo.column] : {};

      // on ajoute la méthode onItemChange spécifique de la GS

      const propsGS: Partial<AutocompleteProps> =
        typeCompo === "GS"
          ? {
              joinTableName,
              joinListFields,
              additionalClause,
              sortClause,
              onItemChange: onItemChange,
              controlProps: { expanded: true },
              sjmoCode: props.sjmoCode,
              parent: expertParentProps,
              styleInput: style,
              tableName: props.tableName,
              hasLov
            }
          : {};

      // on  ajoute la méthode onValueChange spécifique à l'éditeur
      const propsEditor =
        typeCompo === "ED"
          ? {
              onValueChange: onValueChange
            }
          : {};

      const propsToSelectedComponent = {
        style,
        ...restProps,
        ...propsGS,
        ...propsEditor
      };

      const combinedReadOnly =
        getReadonlyValue(readOnly, state.context.entity?.version ?? null) || false;
      const combinedDisable =
        getReadonlyValue(disabled, state.context.entity?.version ?? null) || false;

      const options = getOptionsByType(compo);

      return (
        <SelectedComponent
          key={compo.column}
          name={compo.column}
          onChange={onChange}
          {...propsToSelectedComponent}
          {...options}
          onBlur={onBlur}
          onContextMenu={onContextMenu(compo.column)}
          id={compo.column}
          value={state.context.entity?.[compo.column] ?? null}
          required={mandatory}
          wviState={wviState ? wviState.code : null}
          wviMessage={wviState ? wviState.message : null}
          readOnly={combinedReadOnly}
          disabled={combinedDisable}
          onValueChange={typeCompo === "ED" || typeCompo === "CH" ? onValueChange : undefined}
        />
      );
    });
  }

  function createExpertContent() {
    // on cherche le group actif
    const current = currentGroup.find((_, i) => i === definition.activeTab);

    if (current == null) return null;

    if (current.compos.length > 0) {
      return (
        <GroupComponent
          key={definition.activeTab}
          group={current}
          onChange={onChange}
          onBlur={onBlur}
          onItemChange={onItemChange}
          onValueChange={onValueChange}
          contextMenu={onContextMenu}
          entity={state.context.entity ?? {}}
          groupSize={current.groupSize}
          wviState={state.context.result}
          sjmoCode={props.sjmoCode}
          parent={expertParentProps}
          tableName={props.tableName}
        />
      );
    } else if (current.datatableTableName != null) {
      return (
        <Datatable
          key={current.datatableCtrlKey}
          sjmoCode={props.sjmoCode}
          tableName={current.datatableTableName}
          ctrlkey={current.datatableCtrlKey}
        />
      );
    }

    return null;
  }

  const isLoading = isFormLoadingState(state);
  const isStarting = state.matches({ initial: "slow" }) || state.matches({ initial: "stuck" });
  const isStuck = state.matches({ validating: "stuck" }) || state.matches({ saving: "stuck" });

  const expertContent = isStarting ? <Loader /> : createExpertContent();

  return (
    <Modal
      title={
        <>
          <Trans i18nKey="commun_expert">Expert</Trans>
          <button className="button is-text is-small" onClick={fetchEntity}>
            <span>{t("commun_rafraichir")}</span>
            <span className="icon is-small">
              <Fa icon={["fas", "sync-alt"]} />
            </span>
          </button>
          <SuperUserLink url={`/admin/expert/${props.sjmoCode}`} className="ml-6" />
        </>
      }
      minWidth="90vw"
      minHeight="70vh"
      disableFooterButton={isLoading}
      onValidate={() => {
        send({ type: "SAVE", sjmoCode: props.sjmoCode });
        track("expert::save::click");
      }}
      onClose={() => {
        onClose();
        track("expert::cancel");
      }}
    >
      {isStuck && (
        <div className="flex justify-center">
          <div className="tag is-large is-warning is-light">
            <span style={{ marginRight: ".5em" }}>
              <Fa icon={["fas", "exclamation-triangle"]} />
            </span>
            <span>{t("commun_lenteur_merci_de_patienter")}</span>
          </div>
        </div>
      )}
      <LoaderContainer className="columns" loading={isLoading} style={{ height: props.height }}>
        <DataInteractionContext.Provider value={state.context.entity}>
          <div className="column is-one-quarter">
            <MenuNavigationExpert menu={menu} />
          </div>
          <div className="column is-three-quarters">
            <div className="gridWrapper2Cols">{createHeader()}</div>
            <div className="tabs">
              <ul>
                {currentGroup.map((group, i) => {
                  return (
                    <li
                      key={group.groupLabel}
                      className={classNames({ "is-active": i === definition.activeTab })}
                      onClick={() => {
                        track("expert::tabs::changed");
                      }}
                    >
                      <a onClick={() => dispatch({ type: "CHANGE_ACTIVE_TAB", payload: i })}>
                        {group.groupLabel}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {expertContent}
          </div>
        </DataInteractionContext.Provider>
      </LoaderContainer>
    </Modal>
  );
};

export default Expert;
