import React, { Component } from "react";
import { connect } from "react-redux";

import { ReducerState } from "reducers";
import { AllSatelliteState } from "reducers/modules/satellites";

import { fetchAllSatelliteCount, clearSatelliteCount } from "actions/satellites";

import "./Satellites.css";
import IndicateurBarSattellite from "composants/satellite/IndicateurBarSatellite";
import { openSatellite } from "utils/navigation.utils";
import { selectGalaxyInformation } from "selectors";
import { GALAXIE_DECISIONNEL } from "containers/galaxy/Galaxie";

interface SatelliteFn {
  fetchAllSatelliteCount(tableName: string | undefined, id: string | undefined): void;
  clearSatelliteCount(): void;
}

interface SatellitesProps {
  satellites: AllSatelliteState;
  lang: string;
  isApiDataOk: boolean;
  shouldLoadSatellite: boolean;
}

type SatelliteAllProps = SatelliteFn & SatellitesProps;

class SatellitesIndicateurBar extends Component<SatelliteAllProps> {
  componentDidUpdate(prevProps: SatellitesProps) {
    if (
      this.props.isApiDataOk &&
      prevProps.satellites.context !== this.props.satellites.context &&
      this.props.satellites.context.tableName &&
      this.props.satellites.context.id &&
      this.props.shouldLoadSatellite
    ) {
      this.props.fetchAllSatelliteCount(
        this.props.satellites.context.tableName,
        this.props.satellites.context.id
      );
    } else if (!this.props.shouldLoadSatellite) {
      // Force le clean des donnés satellites sans clear le context
      this.props.clearSatelliteCount();
    }
  }

  render() {
    return (
      this.props.isApiDataOk && (
        <IndicateurBarSattellite
          openSatelliteParams={this.openSatellite}
          satellites={this.props.satellites}
          className="has-text-white"
        />
      )
    );
  }

  onClose = () => {
    this.setState({ showData: false });
  };

  openSatellite = (currentTab: string) => {
    if (this.props.satellites.context.id && this.props.satellites.context.tableName) {
      return openSatellite({
        tableName: this.props.satellites.context.tableName,
        sjmoCode: this.props.satellites.context.sjmoCode || "",
        contextId: this.props.satellites.context.id,
        satelliteName: currentTab
      });
    } else {
      return "";
    }
  };
}

function mapStateToProps(state: ReducerState): SatellitesProps {
  let shouldLoadSatellite = false;
  if (state.satellites.context.sjmoCode) {
    // On clean les données satellites pour les galaxies de type decisionnel et univers
    const galaxyInfo = selectGalaxyInformation(state, state.satellites.context.sjmoCode);
    shouldLoadSatellite = !GALAXIE_DECISIONNEL.includes(galaxyInfo.galaxyType);
  }

  return {
    satellites: state.satellites,
    lang: state.userSettings.lang,
    isApiDataOk: state.apiDataOkReducer.isApiDataOk,
    shouldLoadSatellite
  };
}

export default connect<SatellitesProps, SatelliteFn>(
  mapStateToProps,
  {
    fetchAllSatelliteCount,
    clearSatelliteCount
  }
)(SatellitesIndicateurBar);
