import React, { SFC } from "react";

import AddToFavorite from "AddToFavorite";
import LabelGalaxy from "composants/LabelGalaxy";
import SatellitesIndicateurBar from "containers/satellites/SatellitesIndicateurBar";

import ActiveTabGalaxy from "composants/menu/ActiveTabGalaxy";
import { ModeDebugIcon } from "composants/userSettings/ModeDebug";
import { SuperUserIcon } from "composants/userSettings/SuperUser";

import { LeGrosMenu } from "layout/top/LeGrosMenu";
import { AppGalaxyInfo } from "types/Galaxy";
import { Settings } from "./Settings";
import { SearchGalaxies } from "./SearchGalaxies";
import { NotificationMenu } from "./NotificationMenu";
import Ribbon from "./Ribbon";
import ProcessDelayedMenu from "./ProcessDelayedMenu";

interface TopbarProps {
  favoris: AppGalaxyInfo[];
  galaxies: Record<string, AppGalaxyInfo>;
  changeMenuFavoris(newFavoris: AppGalaxyInfo[]): void;
}

const Topbar: SFC<TopbarProps> = ({ favoris, galaxies, changeMenuFavoris }) => {
  return (
    <>
      <header className="" style={{ backgroundColor: "hsla(205, 90%, 43%)", zIndex: 50 }}>
        <nav className="level" style={{ margin: "0 10px" }}>
          {/*west header*/}
          <div className="level-left">
            <div className="level-item">
              <LeGrosMenu />
            </div>
            <div className="level-item">
              <SearchGalaxies galaxies={galaxies} />
            </div>
            <div className="level-item">
              <ModeDebugIcon />
            </div>
            <div className="level-item">
              <SuperUserIcon />
            </div>
          </div>

          <div
            className="level-item has-text-centered"
            style={{ position: "absolute", left: "calc(50% - 10rem)", width: "20rem" }}
          >
            <AddToFavorite favoris={favoris} afterToggle={changeMenuFavoris} />
            <LabelGalaxy galaxies={galaxies} />
          </div>

          <div className="level-right">
            <div className="level-item">
              <SatellitesIndicateurBar />
            </div>
            <div className="level-item">
              <ProcessDelayedMenu />
            </div>
            <div className="level-item">
              <NotificationMenu />
            </div>
            <div className="level-item">
              <Settings />
            </div>
          </div>
        </nav>
      </header>
      <div className="" style={{ backgroundColor: "hsl(205, 79%, 92%)" }}>
        <ActiveTabGalaxy />
      </div>
      <Ribbon />
    </>
  );
};

export default Topbar;
