import React, { useState, FC } from "react";
import { Trans } from "react-i18next";
import { fetchMessageThread } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import Modal from "../Modal/Modal";
import { Dropdown, DropdownButton, DropdownMenu } from "../DropDown/Dropdown";
import { TEXT_FORMAT } from "../RichTextEditor/TextEditor";
import Control from "composants/form/Control";
import DocumentViewer, { DocumentLightDto } from "./DocumentViewer";

export interface Mail {
  id: string;
  date: Date;
  emmetteurId: string;
  emmetteurPrenom: string;
  emmetteurNom: string;
  destinataireId: string;
  destinatairePrenom: string;
  destinataireNom: string;
  destinataireType: string;
  object: string;
  texte: string;
  parentId?: number;
  attachments?: DocumentLightDto[];
}

interface MailListProps {
  tableName: string;
  mails: Mail[];
  textType: string;
  answer?(selected: Mail): void;
  transfert?(selected: Mail): void;
  delete?(selected: Mail): Promise<any>;
}

interface MailListState {
  showThread: boolean;
  thread: Mail[];
}

const MailList: FC<MailListProps> = props => {
  const [showThread, setShowThread] = useState<boolean>(false);
  const [thread, setThread] = useState<Mail[]>([]);
  const [displayMailAttachment, setDisplayMailAttachment] = useState<DocumentLightDto | null>(null);

  function buildThread(root: Mail) {
    fetchMessageThread(props.tableName, root.parentId as number)
      .then(response => {
        const thread: Mail[] = response.data;

        let copy: Mail = Object.assign({}, root);
        copy.parentId = undefined;
        thread.unshift(copy);

        setThread(response.data);
        setShowThread(true);
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  }

  function buildMessages(ms: Mail[], isEditable: boolean) {
    return ms.map(message => {
      return buildOneMessage(message, isEditable);
    });
  }

  function onClose() {
    setThread([]);
    setShowThread(false);
  }

  function buildOneMessage(mail: Mail, isEditable: boolean) {
    const displayText =
      props.textType === TEXT_FORMAT ? (
        <div dangerouslySetInnerHTML={{ __html: mail.texte }} />
      ) : (
        <pre className="is-paddingless tab-message-pre">{mail.texte}</pre>
      );

    const answerLink =
      props.answer !== undefined ? (
        <a
          className="card-footer-item"
          onClick={() => (props.answer !== undefined ? props.answer(mail) : {})}
        >
          {t("commun_repondre")}
        </a>
      ) : (
        <div />
      );

    const transfertLink =
      props.transfert !== undefined ? (
        <a
          className="card-footer-item"
          onClick={() => (props.transfert !== undefined ? props.transfert(mail) : {})}
        >
          {t("commun_transferer")}
        </a>
      ) : (
        <div />
      );

    const deleteLink =
      props.delete !== undefined ? (
        <div className="card-footer-item">
          <Dropdown autoclose className="is-right">
            <DropdownButton
              render={param => (
                <a ref={param.buttonRef} className="card-footer-item" onClick={param.onOpen}>
                  {t("commun_supprimer")}
                </a>
              )}
            />
            <DropdownMenu
              render={param => (
                <div className="dropdown-item">
                  <p>{t("commun_sur_de_suprimer")}</p>
                  <button
                    className="button is-danger is-fullwidth is-small"
                    onClick={() =>
                      props.delete !== undefined ? props.delete(mail).then(param.onClose) : {}
                    }
                  >
                    {t("commun_oui")}
                  </button>
                </div>
              )}
            />
          </Dropdown>
        </div>
      ) : (
        <div />
      );

    const toolbarMail = (
      <>
        {answerLink}
        {transfertLink}
        {deleteLink}
      </>
    );

    const pjs = (
      <Control className="my-6">
        <div className="tags">
          {mail.attachments
            ? mail.attachments.map(doc => (
                <a
                  key={doc.path}
                  className="tag is-link mx-8"
                  onClick={() => {
                    setDisplayMailAttachment(doc);
                  }}
                >
                  {doc.name}
                </a>
              ))
            : []}
        </div>
      </Control>
    );

    return (
      <>
        <div className="card donnees-satellite-container" key={mail.id} style={{ margin: 10 }}>
          <header className="card-header">
            <p className="card-header-title">{mail.object}</p>
          </header>
          <div className="card-content">
            <div className="content">
              <div className="media">
                <div className="media-content">
                  <div className="message-deale">
                    {displayText}
                    {pjs}
                    <div className="has-text-weight-light">
                      <Trans i18nKey="commun_mail_envoye_de">
                        Message envoyé de {{ issuerName: mail.emmetteurPrenom }}
                        {{ issuerLastName: mail.emmetteurNom }} à
                        {{ receiverName: mail.destinatairePrenom }}
                        {{ receiverLastName: mail.destinataireNom }}, le
                        {{ date: mail.date, format: "date" }}.
                      </Trans>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="card-footer">
            <>
              {toolbarMail}
              {mail.parentId && (
                <a
                  className="card-footer-item"
                  onClick={() => {
                    buildThread(mail);
                  }}
                >
                  {t("commun_voir_conversation")}
                </a>
              )}
            </>
          </footer>
        </div>
        {displayMailAttachment && (
          <DocumentViewer
            document={displayMailAttachment}
            onClose={() => setDisplayMailAttachment(null)}
          />
        )}
      </>
    );
  }

  return (
    <>
      {buildMessages(
        props.mails,
        props.answer !== undefined || props.transfert !== undefined || props.delete !== undefined
      )}
      {showThread && (
        <Modal
          title={t("commun_conversation")}
          show
          height="70vh"
          hideFooter={true}
          onClose={onClose}
        >
          {buildMessages(thread, false)}
        </Modal>
      )}
    </>
  );
};

export default MailList;
