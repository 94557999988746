import React, { Component, SFC, ReactNode, FC } from "react";
import { useTransition, animated, config } from "react-spring";

import { Menu as MenuType } from "types/Menu";

function containsWord(m: MenuType, term?: string) {
  // si term n'est pas présent, tout match
  if (!term) {
    return true;
  }

  // si term est présent dans le label, ça match
  if (m.label.toLowerCase().indexOf(term) !== -1) {
    return true;
    // on vérifie également les enfants
  } else if (m.children !== null) {
    const contains: boolean[] = [];

    for (let child of m.children) {
      contains.push(containsWord(child, term));
    }

    return contains.reduce((acc, curr) => (curr === true ? curr : acc), false);
  }

  // si on arrive ici, rien ne match
  return false;
}

interface MenuProps {
  menu: Array<MenuType>;
  open: Record<string, boolean>;
  isFilter?: boolean;
  classNames?: {
    ulClassName?: string;
    liClassName?: string;
  };
  styles?: {
    ulStyle?: React.CSSProperties;
    liStyle?: React.CSSProperties;
  };
  render(item: MenuType, onClickMenu?: () => void): JSX.Element | JSX.Element[];
  onClickMenu(key: string): void;
}

export const Menu: FC<MenuProps> = ({
  classNames = {},
  styles = {},
  menu = [],
  isFilter = false,
  open,
  render,
  onClickMenu
}) => {
  const transitions = useTransition(menu, item => item.id || item.code, {
    config: config.gentle,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        if (item.children.length === 0) {
          return (
            <animated.li
              className={classNames.liClassName}
              style={{ ...styles.liStyle, ...props }}
              key={key}
            >
              {render(item)}
            </animated.li>
          );
        } else {
          const children =
            isFilter || open[item.code || item.label] ? (
              <animated.ul
                className={classNames.ulClassName}
                style={{
                  ...styles.ulStyle,
                  display: isFilter || open[item.code || item.label] ? "block" : "none",
                  ...props
                }}
              >
                <Menu
                  menu={item.children}
                  isFilter={isFilter}
                  open={open}
                  render={render}
                  onClickMenu={onClickMenu}
                  classNames={classNames}
                />
              </animated.ul>
            ) : null;

          return (
            <animated.li
              key={key}
              className={classNames.liClassName}
              style={{ ...styles.liStyle, ...props }}
            >
              {render(item, () => onClickMenu(item.code || item.label))}
              {children}
            </animated.li>
          );
        }
      })}
    </>
  );
};

interface CollapseComponentProps {
  defaultOpen?: Record<string, boolean>;
  render(props: CollapseComponentRenderProps): JSX.Element | JSX.Element[];
}

interface CollapseComponentRenderProps {
  open: Record<string, boolean>;
  onClickMenu(key: string): void;
}

interface CollapseComponentState {
  open: Record<string, boolean>;
}

export class CollapseComponent extends Component<CollapseComponentProps, CollapseComponentState> {
  state: CollapseComponentState = {
    open: this.props.defaultOpen || {}
  };

  onClickMenuItem = (key: string) => {
    const current = this.state.open[key];
    this.setState({
      open: {
        ...this.state.open,
        [key]: current !== undefined ? !current : true
      }
    });
  };

  render() {
    return this.props.render({ open: this.state.open, onClickMenu: this.onClickMenuItem });
  }
}
