import { Notification, NotificationPriority } from "types/Notification";
import Action, { ActionTypeData } from "reducers/Action";
import { parse, compareDesc, addSeconds } from "date-fns";

export interface NotificationState {
  notifications: Notification[];
}

const initialState: NotificationState = {
  notifications: []
};

type AllActions =
  | ActionTypeData<"FETCH_LIST_NOTIFICATION_SUCCESS", Notification[]>
  | ActionTypeData<"PUSH_NOTIFICATION", Notification>;

export const NOTIFICATION_PRIORITY_VALUE: Record<NotificationPriority, number> = {
  TEMPORARY: 0,
  LOW: 1,
  NORMAL: 2,
  HIGH: 3,
  CRITICAL: 4
};

export const NOTIFICATION_OVERLAY_LIMIT = 2;

function compareNotification(left: Notification, right: Notification): number {
  if (left.validatedAt == null && right.validatedAt != null) return -1;
  else if (left.validatedAt != null && right.validatedAt == null) return 1;

  const priorityLeftValue = NOTIFICATION_PRIORITY_VALUE[left.priority];
  const priorityRightValue = NOTIFICATION_PRIORITY_VALUE[right.priority];

  if (priorityLeftValue > priorityRightValue) {
    return -1;
  } else if (priorityRightValue < priorityLeftValue) {
    return 1;
  } else {
    const createdAtLeft = parse(left.createdAt);
    const createdAtRight = parse(right.createdAt);

    return compareDesc(createdAtLeft, createdAtRight);
  }
}

export default function notificationReducer(
  state: NotificationState = initialState,
  action: AllActions
): NotificationState {
  switch (action.type) {
    case "FETCH_LIST_NOTIFICATION_SUCCESS": {
      // const overlay = getOverlayTiming(action.payload);

      const notifications = [...action.payload];
      notifications.sort(compareNotification);

      return {
        notifications
      };
    }

    case "PUSH_NOTIFICATION": {
      const oldNotifications = state.notifications.filter(notif => action.payload.id !== notif.id);

      const notifications = [action.payload, ...oldNotifications];
      // const overlay = getOverlayTiming([action.payload]);

      notifications.sort(compareNotification);

      return {
        notifications: notifications
        // overlay: {
        //   ...state.overlay,
        //   ...overlay
        // }
      };
    }

    default:
      return state;
  }
}
