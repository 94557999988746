import React, { FC, useRef, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "date-fns";
import FullCalendar, { CustomButtonInput, DatesSetArg, EventClickArg } from "@fullcalendar/react";
import french from "@fullcalendar/core/locales/fr";
import english from "@fullcalendar/core/locales/en-gb";
import deutsch from "@fullcalendar/core/locales/de";
import italian from "@fullcalendar/core/locales/it";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { simpleTaskTemplate } from "./templates/SimpleTaskTemplate";
import { progressTaskTemplate } from "./templates/ProgressTaskTemplate";

import { schedulerView } from "types/Galaxy";
import { PaginationState, SchedulerDefinition, BusinessHours, TimeSpan } from "./Scheduler";
import { agendaTaskTemplate } from "./templates/AgendaTaskTemplate";
/**
 * Obliger de recréer le type car la librairie ne met en frontale que DurationInput
 * qui peut être aussi un string ou un number
 */
export interface DurationObjectInput {
  years?: number;
  year?: number;
  months?: number;
  month?: number;
  weeks?: number;
  week?: number;
  days?: number;
  day?: number;
  hours?: number;
  hour?: number;
  minutes?: number;
  minute?: number;
  seconds?: number;
  second?: number;
  milliseconds?: number;
  millisecond?: number;
  ms?: number;
}

const TEMPLATES = {
  SIMPLE: simpleTaskTemplate,
  PROGRESS: progressTaskTemplate,
  AGENDA: agendaTaskTemplate
};

const plugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
const pluginsTimeline = [resourceTimelinePlugin, interactionPlugin];

const localeByLangCode = {
  fr: french,
  en: english,
  de: deutsch,
  it: italian
};

/**
 * Constantes de mapping permettant de gérer la configuration des vues en fonction du paramétrage.
 */
const CALENDAR_VIEW_BY_TIME_SPAN = {
  DAY: { view: "timeGridDay", duration: { hour: 1 } },
  WEEK: { view: "timeGridWeek", duration: { day: 1 } },
  MONTH: { view: "dayGridMonth", duration: { week: 1 } }
};

export interface Task {
  key: string;
  id?: string;
  tableName?: string;
  title: string;
  tooltip?: string;
  resourceId?: string;
  start: string | undefined;
  end: string | undefined;
  duration?: DurationObjectInput;
  editable: boolean;
  resourceEditable: boolean;
  className?: string;
  textColor?: string;
  backgroundColor?: string;
  // ClassName de sauvegarde au cas où le className de la tache à changé
  originalClassName?: string;
  create?: boolean; // élément technique nécéssaire à l'utilisation de fullcalendar, il faut spécifier create = false pour éviter les bug d'affichage lors d'un drop
  extendedProps?: any;
  display?: "background"; // Utilisé pour les zone marquées
  isSuggest?: boolean;
}

export interface Resource {
  id: string;
  title: string;
  groupId?: string;
  businessHours?: BusinessHours[];
}

interface FullCaldendarContainerProps {
  view: schedulerView;
  definition: SchedulerDefinition;
  locale: any;
  menuOpen?: boolean;
  pagination: PaginationState;
  tasks: Task[];
  resources: Resource[];
  dateClick(arg: DateClickArg): void;
  eventClick(arg: EventClickArg): boolean | void;
  setMenuOpen?(open: boolean): void;
  refresh(): void;
  setPagination(
    pagination: PaginationState | ((pagintion: PaginationState) => PaginationState)
  ): void;
  changeTask(event: any): void;
  addTask(event: any): void;
  setDisableSuggest(disable: boolean): void;
}

/**
 * Calcul la nouvelle duration qu'il faut set dans le state de pagination
 *
 * @param {string} oldTimeSpan Défini l'unité de temps qu'il faut ajouter/retirer (jour, moi, année)
 * @param {DurationObjectInput} oldDuration Donne la valeur initiale auquel il faut ajouter/retirer 1
 * @param {true} isAdding Définit si on ajoute ou si on retire 1
 * @returns
 */
function getPaginationForButton(
  oldTimeSpan: string,
  oldDuration: DurationObjectInput,
  isAdding: Boolean
): PaginationState {
  let durationValue = oldDuration.day as number;
  switch (oldTimeSpan) {
    case "DAY":
      if (isAdding && durationValue < 6) {
        // Si on ajoute et qu'il y a moins d'une semaine on reste en vue jour et on ajoute 1
        return { timeSpan: "DAY", duration: { day: durationValue + 1 } };
      } else if (isAdding) {
        // Si on ajoute et qu'il y a un semaine ou plus
        return { timeSpan: "WEEK", duration: { day: 7 } };
      } else if (durationValue > 1) {
        // Si on retire et qu'il y a plus d'un jour on en retire 1
        return { timeSpan: "DAY", duration: { day: durationValue - 1 } };
      } else {
        // On ne peut pas descendre en dessous de 1 jour
        return { timeSpan: "DAY", duration: { day: 1 } };
      }
    case "WEEK":
      if (isAdding && durationValue < 28) {
        // si on ajoute une semaine et qu'il y a moins de 5 semaines on reste en semaine
        return { timeSpan: "WEEK", duration: { day: durationValue + 7 } };
      } else if (isAdding) {
        // Si on ajoute une 5 ème semaine ou plus on passe en vue mois
        return { timeSpan: "MONTH", duration: { day: 31 } };
      } else if (durationValue < 13) {
        // Si on retire et qu'il y a moins de 13 jours on passe en vue jour avec 6 jours
        return { timeSpan: "DAY", duration: { day: 6 } };
      } else {
        // Si on retire et qu'il y a plus de 13 jours on reste en vue semaine et on retire 7 jours
        return { timeSpan: "WEEK", duration: { day: durationValue - 7 } };
      }
    case "MONTH":
      if (isAdding) {
        // Si on ajoute on ajoute 31 jours
        return { timeSpan: "MONTH", duration: { day: durationValue + 31 } };
      } else if (durationValue < 61) {
        // Si on retire et qu'il y a moins de deux mois on passe en vue semaine avec 4 semaine
        return { timeSpan: "WEEK", duration: { day: 28 } };
      } else {
        // Si on retire et qu'il y a plus de deux mois on retire un mois
        return { timeSpan: "MONTH", duration: { day: durationValue - 31 } };
      }
    default:
      return { timeSpan: "DAY", duration: { day: 1 } };
  }
}

function getSlotIntervalFromTimeSpan(
  timeSpan: string,
  duration: DurationObjectInput
): DurationObjectInput {
  if (timeSpan === "WEEK" || timeSpan === "DAY") {
    return { hour: 1 };
  } else if (timeSpan === "MONTH" && duration.day && duration.day < 63) return { day: 1 };
  else {
    return { week: 1 };
  }
}

function getDateIncrementFromTimeSpan(
  timeSpan: string,
  isSubstracting?: boolean
): DurationObjectInput {
  if (timeSpan === "DAY" || timeSpan === "WEEK") {
    return isSubstracting ? { day: -1 } : { day: 1 };
  } else {
    return isSubstracting ? { week: -1 } : { week: 1 };
  }
}

function getSlotMinWidthFromTimeSpan(timeSpan: string): number {
  if (timeSpan === "DAY" || timeSpan === "WEEK") {
    return 40;
  } else {
    return 80;
  }
}

function getSlotLabelByTimeSpan(timeSpan: TimeSpan) {
  return timeSpan === "MONTH"
    ? [
        { month: "long", year: "numeric" },
        { day: "2-digit", month: "2-digit" }
      ]
    : undefined;
}

const FullCalendarContainer: FC<FullCaldendarContainerProps> = props => {
  const { t } = useTranslation();

  const { menuOpen, setMenuOpen, pagination, setPagination, refresh, setDisableSuggest } = {
    ...props
  };

  const customButtons = useCallback(() => {
    const refreshButton: CustomButtonInput = {
      icon: `fa fa fa-sync`,
      click: function() {
        refresh();
      }
    };

    const openMenu: CustomButtonInput = {
      icon: "fa fa fa-bars",
      click: function() {
        if (setMenuOpen) setMenuOpen(true);
      }
    };

    const [more, less]: CustomButtonInput[] = ["plus", "minus"].map(name => ({
      icon: `fa fa fa-search-${name}`,
      click: function() {
        const duration = getPaginationForButton(
          pagination.timeSpan,
          pagination.duration,
          name !== "plus"
        );

        setPagination({
          ...pagination,
          timeSpan: duration.timeSpan,
          duration: duration.duration
        });
      }
    }));

    const [dayView, weekView, monthView]: CustomButtonInput[] = ["DAY", "WEEK", "MONTH"].map(
      ts => ({
        text: t(`commun_${ts.toLowerCase()}`),
        click: function() {
          setPagination({
            ...pagination,
            timeSpan: ts as TimeSpan,
            duration: { day: ts === "DAY" ? 1 : ts === "WEEK" ? 7 : 31 }
          });
        }
      })
    );

    // Attention à cause d'un bug avec fullcalendar actuellement non corrigé https://github.com/fullcalendar/fullcalendar/issues/4386
    // On est forcé de contourner le problème.
    // Le bouton avance fait un simple next() en prenant en compte l'intervalle custom que l'on a préalablement spécifié à fullcalendar
    const nextButton: CustomButtonInput = {
      icon: `fa fas fa-chevron-right`,
      click: function() {
        setDisableSuggest(true);
        if (calendarRef.current !== null) {
          calendarRef.current.getApi().next();
        }
      }
    };
    // Le bouton retour incrémente la date de -1 intervalle, intervalle que l'on calcul à la volée
    const prevButton: CustomButtonInput = {
      icon: `fa fas fa-chevron-left`,
      click: function() {
        setDisableSuggest(true);
        if (calendarRef.current !== null) {
          const duration = getDateIncrementFromTimeSpan(pagination.timeSpan, true);
          calendarRef.current.getApi().incrementDate(duration);
        }
      }
    };

    return {
      openMenu,
      refreshButton,
      more,
      less,
      dayView,
      weekView,
      monthView,
      prevButton,
      nextButton
    } as { [name: string]: CustomButtonInput };
  }, [refresh, setMenuOpen, pagination, setPagination, t, setDisableSuggest]);

  const template = useCallback(
    info => TEMPLATES[props.definition.template](info, props.definition),
    [props.definition]
  );

  /**
   * Cette fonction permet de déléguer le calcul des dates de début et de fin à fullcalendar
   * Et de mettre à jour la pagination en conséquence
   */
  const onDateChange = useCallback(
    (info: DatesSetArg) => {
      // On ne met à jour le state que si les valeurs changent vraiment, car les dates renvoyées sont de nouvelles instances à chaque fois
      if (
        !pagination.start ||
        !pagination.end ||
        !isEqual(info.view.activeStart, pagination.start) ||
        !isEqual(info.view.activeEnd, pagination.end)
      ) {
        setPagination((pag: PaginationState) => ({
          ...pag,
          start: info.view.activeStart,
          end: info.view.activeEnd
        }));
      }
    },
    [pagination, setPagination]
  );

  const { minTime, maxTime } = useMemo(() => {
    if (props.definition.businessHours && props.definition.businessHours.length === 1) {
      return {
        minTime: props.definition.businessHours[0].startTime,
        maxTime: props.definition.businessHours[0].endTime
      };
    }
    return { minTime: "00:00:00", maxTime: "24:00:00" };
  }, [props.definition.businessHours]);

  const calendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    if (props.view === "TIMELINE") {
      calendarRef?.current?.getApi().changeView("resourceTimeline");
    } else {
      calendarRef?.current
        ?.getApi()
        .changeView(CALENDAR_VIEW_BY_TIME_SPAN[props.pagination.timeSpan].view);
    }
  }, [props.pagination.timeSpan, props.view]);

  return (
    <>
      {props.view === "CALENDAR" && (
        <FullCalendar
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          ref={calendarRef}
          nowIndicator
          headerToolbar={{
            left: `today,refreshButton${
              menuOpen !== undefined && menuOpen === false ? " openMenu " : " "
            }prev,next`,
            center: "title",
            right: "timeGridDay,timeGridWeek,dayGridMonth"
          }}
          locale={localeByLangCode[props.locale]}
          dateClick={props.dateClick}
          eventClick={props.eventClick}
          weekNumbers={true}
          plugins={plugins}
          events={props.tasks}
          eventDrop={props.changeTask}
          eventResize={props.changeTask}
          drop={props.addTask}
          droppable={true}
          height="100%"
          businessHours={props.definition.businessHours}
          datesSet={onDateChange}
          customButtons={customButtons()}
          eventContent={template}
          scrollTimeReset={false}
        />
      )}
      {props.view === "TIMELINE" && (
        <FullCalendar
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          ref={calendarRef}
          nowIndicator
          headerToolbar={{
            left: `today,refreshButton${
              menuOpen !== undefined && menuOpen === false ? " openMenu " : " "
            }more,prevButton,nextButton,less`,
            center: "title",
            right: "dayView,weekView,monthView"
          }}
          locale={localeByLangCode[props.locale]}
          dateClick={props.dateClick}
          eventClick={props.eventClick}
          duration={props.pagination.duration}
          dateIncrement={getDateIncrementFromTimeSpan(props.pagination.timeSpan)}
          slotLabelInterval={getSlotIntervalFromTimeSpan(
            props.pagination.timeSpan,
            props.pagination.duration
          )}
          slotLabelFormat={getSlotLabelByTimeSpan(props.pagination.timeSpan)}
          weekNumbers={true}
          plugins={pluginsTimeline}
          events={props.tasks}
          resourceAreaWidth="15%"
          resourceGroupField="groupId"
          resources={props.resources}
          eventDrop={props.changeTask}
          eventResize={props.changeTask}
          drop={props.addTask}
          droppable={true}
          height="100%"
          businessHours={props.definition.businessHours}
          eventContent={template}
          datesSet={onDateChange}
          customButtons={customButtons()}
          slotMaxTime={maxTime}
          slotMinTime={minTime}
          slotMinWidth={getSlotMinWidthFromTimeSpan(props.pagination.timeSpan)}
          scrollTimeReset={false}
          snapDuration={{ second: 1 }}
        />
      )}
    </>
  );
};

function areEqual(prevProps: FullCaldendarContainerProps, nextProps: FullCaldendarContainerProps) {
  return (
    prevProps.definition === nextProps.definition &&
    prevProps.locale === nextProps.locale &&
    prevProps.menuOpen === nextProps.menuOpen &&
    prevProps.pagination === nextProps.pagination &&
    prevProps.resources === nextProps.resources &&
    prevProps.tasks === nextProps.tasks &&
    prevProps.view === nextProps.view &&
    prevProps.addTask === nextProps.addTask &&
    prevProps.changeTask === nextProps.changeTask &&
    prevProps.dateClick === nextProps.dateClick &&
    prevProps.eventClick === nextProps.eventClick &&
    prevProps.refresh === nextProps.refresh &&
    prevProps.setDisableSuggest === nextProps.setDisableSuggest &&
    prevProps.setMenuOpen === nextProps.setMenuOpen &&
    prevProps.setPagination === nextProps.setPagination
  );
}

export default React.memo(FullCalendarContainer, areEqual);
