import React, { Component, SFC, useContext, FC, useState, useMemo } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import { Fa } from "composants/Icon";
import { Menu } from "composants/DropDown/Menu";

interface SuperUserContextProps {
  superUser: boolean;
  toggleSuperUser(): void;
}

const SuperUserContext = React.createContext<SuperUserContextProps>({
  superUser: false,
  toggleSuperUser: () => console.log("toggle super user")
});

export function useIsSuperUserActive() {
  const superUser = useContext(SuperUserContext);
  return superUser.superUser;
}

export const SuperUserProvider: FC = props => {
  // la valeur initiale est calculé en fonction du session storage, la valeur par défaut est false.
  const [superUser, setSuperUser] = useState(() => sessionStorage.getItem("superUser") === "true");

  const value = useMemo(() => {
    return {
      superUser,
      toggleSuperUser: () => {
        setSuperUser(old => {
          const newValue = !old;
          sessionStorage.setItem("superUser", newValue.toString());
          return newValue;
        });
      }
    };
  }, [superUser]);

  return <SuperUserContext.Provider value={value}>{props.children}</SuperUserContext.Provider>;
};

const SuperUserInternal: SFC<WithTranslation> = props => {
  return (
    <SuperUserContext.Consumer>
      {({ superUser, toggleSuperUser }) => {
        return (
          <a
            key={2}
            className={`has-text-${superUser ? "success" : "danger"}`}
            onClick={toggleSuperUser}
          >
            <span className="icon">
              <Fa icon="user-alien" fixedWidth />
            </span>

            {props.t("commun_mode_super_utilisateur")}
          </a>
        );
      }}
    </SuperUserContext.Consumer>
  );
};
export const SuperUser = withTranslation()(SuperUserInternal);

export const SuperUserIcon: SFC = () => {
  return (
    <SuperUserContext.Consumer>
      {({ superUser }) => {
        return superUser && <Fa icon="user-alien" size="2x" className="has-text-grey-light" />;
      }}
    </SuperUserContext.Consumer>
  );
};

export const SuperUserLink: SFC<{ url: string; className?: string }> = props => {
  return (
    <SuperUserContext.Consumer>
      {({ superUser }) => {
        return (
          superUser && (
            <NavLink to={props.url} className={props.className}>
              <span className="icon">
                <Fa icon="cog" fixedWidth />
              </span>

              {props.children}
            </NavLink>
          )
        );
      }}
    </SuperUserContext.Consumer>
  );
};

type SuperUserMenuProps = {
  sjmoCode: string;
};

export const SuperUserMenu: FC<SuperUserMenuProps> = props => {
  const path = "/admin";
  return (
    <SuperUserContext.Consumer>
      {({ superUser }) => {
        return (
          superUser && (
            <Menu>
              <Menu.Button>
                <div className="button">
                  <span className="icon">
                    <Fa icon="cog" fixedWidth />
                  </span>
                </div>
              </Menu.Button>
              <Menu.Overlay>
                <Menu.Item
                  key={path + "/galaxie"}
                  as={Link}
                  to={path + `/galaxie/${props.sjmoCode}`}
                  target="_blank"
                >
                  Dashboard et panels
                </Menu.Item>
                <Menu.Item
                  key={path + "/expert"}
                  as={Link}
                  to={path + `/expert/${props.sjmoCode}`}
                  target="_blank"
                >
                  Expert
                </Menu.Item>
                <Menu.Item key={path + "/udt"} as={Link} to={path + `/udt`} target="_blank">
                  Udt
                </Menu.Item>
                <Menu.Item key={path + "/creator"} as={Link} to={path + `/creator`} target="_blank">
                  Creator
                </Menu.Item>
                <Menu.Item key={path + "/search"} as={Link} to={path + `/search`} target="_blank">
                  Recherche
                </Menu.Item>
                <Menu.Item
                  key={path + "/interaction"}
                  as={Link}
                  to={path + `/interaction/${props.sjmoCode}`}
                  target="_blank"
                >
                  Interaction
                </Menu.Item>
                <Menu.Item
                  key={path + "/processus"}
                  as={Link}
                  to={path + `/processus`}
                  target="_blank"
                >
                  Processus
                </Menu.Item>
                <Menu.Item
                  key={path + "/colonnes"}
                  as={Link}
                  to={path + `/colonnes`}
                  target="_blank"
                >
                  Colonnes
                </Menu.Item>
              </Menu.Overlay>
            </Menu>
          )
        );
      }}
    </SuperUserContext.Consumer>
  );
};
