import React, { Component, ComponentState, SyntheticEvent } from "react";
import { connect as reduxConnect } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import ReactGridLayout, { WidthProvider, Layout } from "react-grid-layout";
import { ReducerState } from "reducers";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { AutoSizer } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars";
import classNames from "classnames";
import { t } from "utils/i18n";
import { convertValue, convertToMap } from "utils/entities.utils";

/**************** TYPES ***********************/
import { PanelState } from "types/Dashboard";
import { Pojo } from "types/Galaxy";
import { GensearchClause, Item } from "types/Component";
import { DashboardFocusState } from "reducers/modules/dashboard/DashboardReducer";

/**************** COMPOSANTS ******************/

import Panel from "composants/dashboard/Panel";
import { Field } from "composants/form/Form";
import { Control } from "composants/form";
import Modal from "composants/Modal/Modal";
import InputTextAndLabel from "composants/input/InputTextAndLabel";
import SysDomaineAndLabel from "composants/select/SysDomaineAndLabel";
import { Dropdown, DropdownButton, DropdownMenu } from "composants/DropDown/Dropdown";
import FocusMenu from "composants/focus/FocusMenu";
import AutoComplete from "composants/autocomplete/AutoComplete";
import { Row, Col } from "composants/Layout";
import { Button } from "composants/button";
import ModalCreateGalaxyFocus from "./ModalCreateGalaxyFocus";
import ModalEditGalaxyFocus from "./ModalEditGalaxyFocus";
import ModalDuplicationFocus from "./ModalDuplicationFocus";
import ModalCreationGalaxie from "./ModalCreationGalaxie";
import ModalDuplicationPanel from "./ModalDuplicationPanel";
import ModalAssociationFocus from "./ModalAssociationFocus";

/**************** ACIONS ***********************/
import {
  initGalaxyInAdministration,
  saveAllAdmin,
  setDirty,
  createNewPanel,
  onCreateNewGalaxy,
  onCreateOrUpdateGalaxyFocus,
  deleteSelectedFocus,
  deletePanel,
  refreshFocusList
} from "actions/adminGalaxy.action";

import { onValueChange, addEntity } from "actions/actionEntities";

import {
  addPanelToFocus,
  fetchDashboardPanelAndChangeFocus,
  layoutChange,
  closePanel,
  fetchPanels
} from "actions/dashboard";

/***************** API *********************/
import { find, createMany, preRecord, findAll, deleteMany } from "api";
import AutoCompleteAndLabel from "composants/autocomplete/AutoCompleteAndLabel";
import { getColumnDefinition } from "api/column";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import { TypeComplexComponent } from "enum";
import ModalPanelTable from "./ModalPanelTable";
import { Helmet } from "react-helmet-async";
import { Fa } from "composants/Icon";
import { BlockComponent } from "composants/group/GroupComponent";
import { GetComponent } from "composants/GetComponent";
import Loader2 from "composants/Loader";
import { initRsqlCriteria, GSBuilder } from "utils/query.utils";
import { Operators, RSQLFilterExpression } from "rsql-criteria-typescript";
import { changeAdminPanelTitle } from "api/adminGalaxy";
/***************** SELECTORS ***************/

export const ADMIN_GALAXIE = "ADMINGALAXY";
const GS_SEARCH = GSBuilder.Comparison("sjmoCode", "OPER_LIKE_START", "");

const associationTypes: ("GLOBAL" | "ROLE" | "USER")[] = ["GLOBAL", "ROLE", "USER"];

/************************* Gestion de la création d'un panel ********************/

interface ModalCreatePanelProps {
  sjmoCode: string;
  onClose(): void;
  createPanel(title: string, code: string, type: string): void;
}
interface ModalCreatePanelState {
  title: string;
  panelCode: string;
  panelTypes: Item[];
  panelTypeVal: string;
}

class ModalPanelCreation extends Component<ModalCreatePanelProps, ModalCreatePanelState> {
  state: ModalCreatePanelState = {
    title: "",
    panelCode: "",
    panelTypeVal: "",
    panelTypes: []
  };

  render() {
    return (
      <Modal
        title={t("commun_creation_panel")}
        onClose={e => this.props.onClose()}
        show={true}
        onValidate={() =>
          this.props.createPanel(this.state.title, this.state.panelCode, this.state.panelTypeVal)
        }
      >
        {this.renderForm()}
      </Modal>
    );
  }

  componentDidMount() {
    getColumnDefinition(this.props.sjmoCode, "syjPanel", ["sjpaType"])
      .then(res => {
        const definitions = convertToMap(res.data, el => el.column);
        const sjpaTypes: ComponentState = definitions.sjpaType;
        this.setState({ panelTypes: sjpaTypes.sysDomaineChoices });
      })
      .catch(() => console.error("cannot resolve definition of columns"));
  }

  renderForm = () => {
    return (
      <>
        <SysDomaineAndLabel
          id="changePanelType"
          sysDomaineChoices={this.state.panelTypes || []}
          value={this.state.panelTypeVal}
          label={t("commun_type_panel")}
          onChange={e => this.setState({ panelTypeVal: convertValue(e) })}
        />

        <InputTextAndLabel
          label={t("commun_titre")}
          id="panelTitle"
          value={this.state.title}
          placeholder="ex : Mini expert"
          onChange={e => {
            const title = convertValue(e);
            this.setState({ title });
          }}
          required={true}
        />

        <InputTextAndLabel
          label={t("commun_code_panel")}
          id="panelCode"
          placeholder="ex : oaff001ArboDoc"
          value={this.state.panelCode}
          onChange={e => {
            const panelCode = convertValue(e);
            this.setState({ panelCode });
          }}
          required={true}
        />
      </>
    );
  };
}

interface ModalCreationPanelInfoProps {
  panelId: string;
  onClose(): void;
}

interface ModalCreationPanelInfoState {
  syjPanelInformation: Pojo | null;
}

class ModalCreationPanelInfo extends Component<
  ModalCreationPanelInfoProps,
  ModalCreationPanelInfoState
> {
  state: ModalCreationPanelInfoState = { syjPanelInformation: null };

  preRecordEntity = () => {
    preRecord({
      sjmoCode: ADMIN_GALAXIE,
      tableName: "syjPanelInformation",
      context: { syjPanelId: this.props.panelId }
    })
      .then(res => {
        this.setState({
          syjPanelInformation: res.data
        });
      })
      .catch(() => {
        console.error("error pre-record of syjPanelInformation");
      });
  };

  findEntityOrPrerecord = async () => {
    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("syjPanelId.id", Operators.Like, this.props.panelId));
    try {
      const res = await findAll({
        tableName: "syjPanelInformation",
        filter: rsql.build()
      });

      if (res.data.data.length <= 0) {
        this.preRecordEntity();
      } else {
        this.setState({
          syjPanelInformation: res.data.data[0]
        });
      }
    } catch {
      console.error("error during the fetch of the entity");
    }
  };

  componentDidMount() {
    this.findEntityOrPrerecord();
  }

  onChange = (e: SyntheticEvent<any>) => {
    if (this.state.syjPanelInformation !== null) {
      const field = e.currentTarget.name;
      const value = convertValue(e);

      const newPojo = {
        ...this.state.syjPanelInformation,
        [field]: value
      };

      this.setState({
        syjPanelInformation: newPojo
      });
    }
  };

  onValueChange = (field: string | undefined, value: any) => {
    if (!field) return;

    if (this.state.syjPanelInformation !== null) {
      const newPojo = {
        ...this.state.syjPanelInformation,
        [field]: value
      };

      this.setState({
        syjPanelInformation: newPojo
      });
    }
  };

  onItemChange = (pojo: Pojo | null, field: string) => {
    if (this.state.syjPanelInformation !== null) {
      const value = pojo !== null ? pojo.id : null;

      const newPojo = {
        ...this.state.syjPanelInformation,
        [field]: value
      };

      this.setState({
        syjPanelInformation: newPojo
      });
    }
  };

  save = () => {
    if (this.state.syjPanelInformation) {
      createMany("syjPanelInformation", [this.state.syjPanelInformation], ADMIN_GALAXIE)
        .then(() => {
          this.props.onClose();
        })
        .catch(() => {
          console.error("error during save of syjPanelInformation");
        });
    }
  };

  delete = () => {
    if (this.state.syjPanelInformation) {
      deleteMany(
        "syjPanelInformation",
        [this.state.syjPanelInformation.id],
        ADMIN_GALAXIE
      ).then(() => this.preRecordEntity());
    }
  };

  render() {
    return (
      <Modal
        title={
          <>
            <span>
              <Trans i18nKey="commun_creation_association_panel_package" />
            </span>
            <span className="is-pulled-right">
              <Button
                className="is-text"
                onClick={this.delete}
                size="small"
                disabled={
                  this.state.syjPanelInformation === null ||
                  this.state.syjPanelInformation.version === null
                }
              >
                <Trans i18nKey="commun_supprimer" />
              </Button>
            </span>
          </>
        }
        onClose={e => this.props.onClose()}
        onValidate={this.save}
      >
        <GetComponent
          tableName="syjPanelInformation"
          sjmoCode={ADMIN_GALAXIE}
          columns={["syjPanelId", "sjifPkName", "sjifTemplateName"]}
        >
          {({ columns }) => {
            return this.state.syjPanelInformation ? (
              <BlockComponent
                sjmoCode={ADMIN_GALAXIE}
                entity={this.state.syjPanelInformation}
                compos={columns}
                wviState={{}}
                contextMenu={() => {
                  /* todo */
                }}
                onBlur={() => {
                  /* todo */
                }}
                onChange={this.onChange}
                onValueChange={this.onValueChange}
                onItemChange={this.onItemChange}
              />
            ) : (
              <Loader2 />
            );
          }}
        </GetComponent>
      </Modal>
    );
  }
}

interface ModalUpdatePanelTitleProps {
  panel: PanelState;
  onClose(): void;
}
interface ModalUpdatePanelTitleState {
  panelTitle: string | null;
}
class ModalUpdatePanelTitle extends Component<
  ModalUpdatePanelTitleProps,
  ModalUpdatePanelTitleState
> {
  state: ModalUpdatePanelTitleState = {
    panelTitle: null
  };

  componentDidMount() {
    this.setState({ panelTitle: this.props.panel.panelLabel });
  }

  onValidate = () => {
    if (this.state.panelTitle) {
      changeAdminPanelTitle("SYJ_PANEL", this.props.panel.panelId, this.state.panelTitle).then(
        () => {
          this.props.onClose();
        }
      );
    }
  };

  render() {
    return (
      <Modal
        onClose={this.props.onClose}
        onValidate={this.onValidate}
        title={
          <>
            <span>
              <Trans i18nKey="commun_changement_titre_panel" />
            </span>
          </>
        }
      >
        <InputTextAndLabel
          label={t("commun_titre")}
          id="panelTitle"
          value={this.state.panelTitle}
          onChange={e => {
            const title = convertValue(e);
            this.setState({ panelTitle: title });
          }}
          required={true}
        />
      </Modal>
    );
  }
}

interface ModalCreationCreatorPanelProps {
  panelId: string;
  onClose(): void;
}
interface ModalCreationCreatorPanelState {
  syjCreatorPanel: Pojo | null;
}
class ModalCreationCreatorPanel extends Component<
  ModalCreationCreatorPanelProps,
  ModalCreationCreatorPanelState
> {
  state: ModalCreationCreatorPanelState = {
    syjCreatorPanel: null
  };

  preRecordEntity = () => {
    preRecord({
      sjmoCode: ADMIN_GALAXIE,
      tableName: "syjCreatorPanel",
      context: { syjPanelId: this.props.panelId }
    })
      .then(res => {
        this.setState({
          syjCreatorPanel: res.data
        });
      })
      .catch(() => {
        console.error("error pre-record of syjCreatorPanel");
      });
  };

  findEntityOrPrerecord = async () => {
    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("syjPanelId.id", Operators.Like, this.props.panelId));
    try {
      const res = await findAll({
        tableName: "syjCreatorPanel",
        filter: rsql.build()
      });

      if (res.data.data.length <= 0) {
        this.preRecordEntity();
      } else {
        this.setState({
          syjCreatorPanel: res.data.data[0]
        });
      }
    } catch {
      console.error("error during the fetch of the entity");
    }
  };

  componentDidMount() {
    this.findEntityOrPrerecord();
  }

  onChange = (e: SyntheticEvent<any>) => {
    if (this.state.syjCreatorPanel !== null) {
      const field = e.currentTarget.name;
      const value = convertValue(e);

      const newPojo = {
        ...this.state.syjCreatorPanel,
        [field]: value
      };

      this.setState({
        syjCreatorPanel: newPojo
      });
    }
  };
  onValueChange = (field: string | undefined, value: any) => {
    if (!field) return;

    if (this.state.syjCreatorPanel !== null) {
      const newPojo = {
        ...this.state.syjCreatorPanel,
        [field]: value
      };

      this.setState({
        syjCreatorPanel: newPojo
      });
    }
  };

  onItemChange = (pojo: Pojo | null, field: string) => {
    if (this.state.syjCreatorPanel !== null) {
      const value = pojo !== null ? pojo.id : null;

      const newPojo = {
        ...this.state.syjCreatorPanel,
        [field]: value
      };

      this.setState({
        syjCreatorPanel: newPojo
      });
    }
  };

  save = () => {
    if (this.state.syjCreatorPanel) {
      createMany("syjCreatorPanel", [this.state.syjCreatorPanel], ADMIN_GALAXIE)
        .then(() => {
          this.props.onClose();
        })
        .catch(() => {
          console.error("error during save of syjCreatorPanel");
        });
    }
  };

  delete = () => {
    if (this.state.syjCreatorPanel) {
      deleteMany("syjCreatorPanel", [this.state.syjCreatorPanel.id], ADMIN_GALAXIE).then(() =>
        this.preRecordEntity()
      );
    }
  };

  render() {
    return (
      <Modal
        title={
          <>
            <span>
              <Trans i18nKey="commun_creation_association_creator_panel" />
            </span>
            <span className="is-pulled-right">
              <Button
                className="is-text"
                onClick={this.delete}
                size="small"
                disabled={
                  this.state.syjCreatorPanel === null || this.state.syjCreatorPanel.version === null
                }
              >
                <Trans i18nKey="commun_supprimer" />
              </Button>
            </span>
          </>
        }
        onClose={e => this.props.onClose()}
        onValidate={this.save}
      >
        <GetComponent
          tableName="syjCreatorPanel"
          sjmoCode={ADMIN_GALAXIE}
          columns={["syjPanelId", "syjTablesId"]}
        >
          {({ columns }) => {
            return this.state.syjCreatorPanel ? (
              <BlockComponent
                sjmoCode={ADMIN_GALAXIE}
                entity={this.state.syjCreatorPanel}
                compos={columns}
                wviState={{}}
                contextMenu={() => {
                  /* todo */
                }}
                onBlur={() => {
                  /* todo */
                }}
                onChange={this.onChange}
                onValueChange={this.onValueChange}
                onItemChange={this.onItemChange}
              />
            ) : (
              <Loader2 />
            );
          }}
        </GetComponent>
      </Modal>
    );
  }
}

const ResponsiveReactGridLayout = WidthProvider(ReactGridLayout);

interface AdminGalaxyProps {
  galaxyFocuses: DashboardFocusState[];
  selectedFocus: string;
  panels: PanelState[];
  isDirty: boolean;
  galaxyProperties: Pojo;
  currentSjtaName: string;
}

interface AdminGalaxyState {
  currentSjmoId: string | null;
  openPanelCreation: boolean;
  openGalaxyFocusAssociation: boolean;
  openFocusDuplication: boolean;
  openFocusDuplicationSQL: boolean;
  openPanelCreationCreatorPanel: boolean;
  openPanelDuplication: boolean;
  openPanelDuplicationSQL: boolean;
  panelClicked: PanelState | null;
  openPanelInfoCreation: boolean;
  openPanelUpdateTitle: boolean;
  panelIdForCreationCreatorPanel: string | null;
  panelIdForCreationPanelInfo: string | null;
  panelForUpdatePanelTitle: PanelState | null;
  createAction?: "USER" | "ROLE" | "GLOBAL";
  editAction: boolean;
  openPanelTable: boolean;
  currentPanelId?: string;
}

interface AdminGalaxyPropsFn {
  addMessage(message: Message): void;
  initGalaxyInAdministration(sjmoCode: string): void;
  layoutChange(sjmoCode: string, layouts: Layout[]): void;
  saveAllAdmin(sjmoCode: string): void;
  addPanelToFocus(sjmoCode: string, sjpaId: string): void;
  fetchDashboardPanelAndChangeFocus(sjmoCode: string, focusId: string): void;
  setDirty(isDirty: boolean): void;
  closePanel(sjmoCode: string, sjpaId: string): void;
  createNewPanel(
    sjmoCode: string,
    title: string,
    code: string,
    type: string,
    focusId: string
  ): Promise<any>;
  deletePanel(
    sjmoCode: string,
    tableName: string,
    panel: PanelState,
    callback?: () => void
  ): Promise<any>;
  onValueChange(
    sjmoCode: string,
    tableName: string,
    key: string,
    ctrlkey: string,
    value: any
  ): Promise<any>;
  addEntity(
    sjmoCode: string,
    tableName: string,
    key: string, // id
    value: any,
    reset?: boolean
  ): Promise<void>;

  onCreateNewGalaxy(
    galaxyTitle: string,
    newSjmoCode: string,
    newSjmoIcon: string,
    sjgaType: string,
    syjTablesId: string | null
  ): Promise<any>;

  onCreateOrUpdateGalaxyFocus(params: {
    currentSjmoCode?: string;
    title: string;
    id?: string;
    privilegie?: boolean;
    code: string;
    position?: number;
    resourceKey?: string;
    syjModuleId?: string;
    sysMenuGroupeId?: string;
    personnelId?: string;
    callback: () => void;
  }): void;
  deleteSelectedFocus(focusId: string, sjmoCode: string): void;
  fetchPanels(sjmoCode: string, focusId: string): void;
  refreshFocusList(sjmoCode: string): void;
}

type AdminGalaxyAllProps = AdminGalaxyProps &
  AdminGalaxyPropsFn &
  RouteComponentProps<{ id: any }> &
  WithTranslation;

class AdminGalaxy extends Component<AdminGalaxyAllProps, AdminGalaxyState> {
  state: AdminGalaxyState = {
    currentSjmoId: null,
    openPanelCreation: false,
    openGalaxyFocusAssociation: false,
    panelClicked: null,
    editAction: false,
    openPanelTable: false,
    openPanelCreationCreatorPanel: false,
    openPanelDuplication: false,
    openPanelDuplicationSQL: false,
    openFocusDuplication: false,
    openFocusDuplicationSQL: false,
    openPanelInfoCreation: false,
    openPanelUpdateTitle: false,
    panelIdForCreationCreatorPanel: null,
    panelIdForCreationPanelInfo: null,
    panelForUpdatePanelTitle: null
  };

  get mainEntityId() {
    return this.state.currentSjmoId;
    // return this.props.match.params.id ? this.props.match.params.id : null;
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      // sjmoCode
      find("syjModule", "q=sjmoCode==" + this.props.match.params.id).then(res => {
        this.setState({ currentSjmoId: res.data.data[0] ? res.data.data[0].id : null }, () =>
          this.init()
        );
      });
    }
  }

  componentDidUpdate(prevProps: AdminGalaxyAllProps) {
    // initialisation de l'administration de la galaxie sélectionnée
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.init();
    }
  }

  init() {
    this.props.initGalaxyInAdministration(this.props.match.params.id);
  }

  render() {
    return (
      <div>
        <Helmet title="Galaxie" />
        <Row>
          {/*west header*/}
          <Col span={4}>{this.createLeftButtonBar()}</Col>
          <Col span={4}>{this.createMainGenSearch()}</Col>
          <Col span={2}>
            {/*east header*/}
            {this.createFocusMenu()}
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Button className="is-link" outlined onClick={this.onOpenPanelCreation}>
              {t("commun_nouveau_panel")}
            </Button>
          </Col>
        </Row>
        <div style={{ height: "calc(100vh - 105px)" }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <Row>
                <Col span={2}>
                  <div className="box list_compo" style={{ height }}>
                    <Field style={{ marginRight: "1em", display: "inline-flex" }} addons>
                      <Control>
                        <div className="has-text-centered has-text-weight-semibold">
                          <Trans i18nKey="commun_panels_dispo" />
                        </div>
                      </Control>
                    </Field>
                    <Scrollbars autoHide style={{ height }}>
                      {this.createHiddenPanels(this.props.panels)}
                    </Scrollbars>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="box">{this.createDashboard()}</div>
                </Col>
              </Row>
            )}
          </AutoSizer>
        </div>
        {this.state.openPanelCreation && (
          <ModalPanelCreation
            sjmoCode={this.props.match.params.id}
            onClose={() => {
              this.setState({ openPanelCreation: false });
            }}
            createPanel={this.onCreateNewPanel}
          />
        )}
        {this.state.openGalaxyFocusAssociation && (
          <ModalAssociationFocus
            sjmoCode={this.props.match.params.id}
            focusId={this.props.selectedFocus}
            onClose={() => {
              this.setState({ openGalaxyFocusAssociation: false });
              this.props.refreshFocusList(this.props.match.params.id);
            }}
          />
        )}
        {this.state.openPanelDuplication && this.state.panelClicked?.panelType !== "MINI_EXPERT" && (
          <ModalDuplicationPanel
            sqlMode={"duplicate"}
            sjmoCode={this.props.match.params.id}
            selectedFocus={this.props.selectedFocus}
            panel={this.state.panelClicked}
            syjModuleId={this.props.galaxyProperties.syjModuleId}
            galaxyFocuses={this.props.galaxyFocuses}
            createAction={this.state.createAction ? this.state.createAction : "GLOBAL"}
            onClose={() => {
              this.setState({ openPanelDuplication: false });
            }}
            onCreateOrUpdateGalaxyFocus={this.props.onCreateOrUpdateGalaxyFocus}
            addMessage={this.props.addMessage}
          />
        )}
        {this.state.openPanelDuplicationSQL &&
          this.state.panelClicked?.panelType !== "MINI_EXPERT" && (
            <ModalDuplicationPanel
              sqlMode={"scriptsql"}
              sjmoCode={this.props.match.params.id}
              selectedFocus={this.props.selectedFocus}
              panel={this.state.panelClicked}
              syjModuleId={this.props.galaxyProperties.syjModuleId}
              galaxyFocuses={this.props.galaxyFocuses}
              createAction={this.state.createAction ? this.state.createAction : "GLOBAL"}
              onClose={() => {
                this.setState({ openPanelDuplicationSQL: false });
              }}
              onCreateOrUpdateGalaxyFocus={this.props.onCreateOrUpdateGalaxyFocus}
              addMessage={this.props.addMessage}
            />
          )}
        {this.state.createAction && (
          <ModalCreateGalaxyFocus
            sjmoCode={this.props.match.params.id}
            createAction={this.state.createAction ? this.state.createAction : "GLOBAL"}
            onClose={() => {
              this.setState({ createAction: undefined });
            }}
            onCreateOrUpdateGalaxyFocus={this.props.onCreateOrUpdateGalaxyFocus}
            addMessage={this.props.addMessage}
          />
        )}
        {this.state.editAction && (
          <ModalEditGalaxyFocus
            sjmoCode={this.props.match.params.id}
            selectedFocus={this.props.selectedFocus}
            galaxyFocuses={this.props.galaxyFocuses}
            createAction={this.state.createAction ? this.state.createAction : "GLOBAL"}
            onClose={() => {
              this.setState({ editAction: false });
            }}
            onCreateOrUpdateGalaxyFocus={this.props.onCreateOrUpdateGalaxyFocus}
            addMessage={this.props.addMessage}
          />
        )}
        {this.state.openFocusDuplication && (
          <ModalDuplicationFocus
            sqlMode={"duplicate"}
            sjmoCode={this.props.match.params.id}
            selectedFocus={this.props.selectedFocus}
            onClose={() => {
              this.setState({ openFocusDuplication: false });
            }}
            onCreateOrUpdateGalaxyFocus={this.props.onCreateOrUpdateGalaxyFocus}
            addMessage={this.props.addMessage}
          />
        )}
        {this.state.openFocusDuplicationSQL && (
          <ModalDuplicationFocus
            sqlMode={"scriptsql"}
            sjmoCode={this.props.match.params.id}
            selectedFocus={this.props.selectedFocus}
            onClose={() => {
              this.setState({ openFocusDuplicationSQL: false });
            }}
            onCreateOrUpdateGalaxyFocus={this.props.onCreateOrUpdateGalaxyFocus}
            addMessage={this.props.addMessage}
          />
        )}
        {this.state.openPanelTable && this.state.currentPanelId && (
          <ModalPanelTable
            idPanel={this.state.currentPanelId}
            addMessage={this.props.addMessage}
            sjmoCode={this.props.match.params.id}
            onClose={() => this.setState({ openPanelTable: false, currentPanelId: undefined })}
          />
        )}
        {this.state.openPanelCreationCreatorPanel && this.state.panelIdForCreationCreatorPanel && (
          <ModalCreationCreatorPanel
            panelId={this.state.panelIdForCreationCreatorPanel}
            onClose={() => {
              this.setState({
                openPanelCreationCreatorPanel: false,
                panelIdForCreationCreatorPanel: null
              });
            }}
          />
        )}
        {this.state.openPanelInfoCreation && this.state.panelIdForCreationPanelInfo && (
          <ModalCreationPanelInfo
            panelId={this.state.panelIdForCreationPanelInfo}
            onClose={() => {
              this.setState({
                openPanelInfoCreation: false,
                panelIdForCreationPanelInfo: null
              });
            }}
          />
        )}
        {this.state.openPanelUpdateTitle && this.state.panelForUpdatePanelTitle && (
          <>
            <ModalUpdatePanelTitle
              panel={this.state.panelForUpdatePanelTitle}
              onClose={() => {
                this.setState(
                  {
                    openPanelUpdateTitle: false,
                    panelForUpdatePanelTitle: null
                  },
                  () => {
                    this.mainEntityId &&
                      this.props.fetchDashboardPanelAndChangeFocus(
                        this.props.match.params.id,
                        this.props.selectedFocus
                      );
                  }
                );
              }}
            />
          </>
        )}
      </div>
    );
  }

  openInNewTab = (url: string) => {
    const win = window.open(url, "_blank");
    if (win) {
      win.focus();
    }
  };

  onCreateNewPanel = (title: string, code: string, type: string) => {
    this.props
      .createNewPanel(this.props.match.params.id, title, code, type, this.props.selectedFocus)
      .then(() => {
        this.setState({ openPanelCreation: false });
      });
  };
  createDashboard = () => {
    return (
      <ResponsiveReactGridLayout
        className="layout"
        cols={12}
        rowHeight={30}
        onLayoutChange={this.onLayoutChange}
        measureBeforeMount
        draggableHandle=".card > .card-header-draggable"
      >
        {this.createVisiblePanels(this.props.panels)}
      </ResponsiveReactGridLayout>
    );
  };

  onOpenPanelCreation = () => {
    this.setState({ openPanelCreation: true });
  };

  openCreateCreatorPanel = (panelId: string) => {
    this.setState({ openPanelCreationCreatorPanel: true, panelIdForCreationCreatorPanel: panelId });
    // TODO: toggle le openCreateCreatorPanel
  };

  openPanelInfo = (panelId: string) => {
    this.setState({ openPanelInfoCreation: true, panelIdForCreationPanelInfo: panelId });
    // TODO: toggle le openCreateCreatorPanel
  };

  onAccessToAdminIR = (panel: PanelState) => {
    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("syjPanelId.id", Operators.Equal, panel.panelId));
    find("syjInteractiveReportPanel", rsql.build()).then(res => {
      let url = "";
      if (res.data.data.length > 0) {
        url = `/admin/interactivereport/${res.data.data[0].syjInteractiveReportId}`;
      } else {
        url = `/admin/interactivereport/new/${this.props.galaxyProperties.syjModuleId}/${panel.panelId}`;
      }
      this.openInNewTab(url);
    });
  };

  onAccesToAdminDgl = (panel: PanelState) => {
    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("syjPanelId.id", Operators.Equal, panel.panelId));
    find("syjPanelDgList", rsql.build()).then(res => {
      let url = "";
      if (res.data.data.length > 0) {
        url = `/admin/dgl/${res.data.data[0].syjDgListId}`;
      } else {
        url = `/admin/dgl`;
      }
      this.openInNewTab(url);
    });
  };

  updateNavCardPanel = (panel: PanelState) => {};

  updateInteractivePanelTitle = (panel: PanelState) => {
    this.setState({ openPanelUpdateTitle: true, panelForUpdatePanelTitle: panel });
  };
  /**
   * Sélection des panels appartenant au focus sélectionné
   */
  createVisiblePanels = (panels: PanelState[]) => {
    if (panels.length <= 0) {
      return null;
    }

    const listPanel = panels
      .filter(panel => panel.visible)
      .map(panel => {
        const layout = {
          i: `${this.props.selectedFocus}_${panel.panelId}`,
          x: panel.posX,
          y: panel.posY,
          w: panel.width,
          h: panel.height
        };
        return (
          <div key={layout.i} data-grid={layout}>
            <Panel
              title={panel.panelLabel}
              onPanelClose={this.onPanelClose}
              style={{ height: "inherit" }}
              panel={panel}
              leftHeader={
                <>
                  <a
                    style={{ marginLeft: "5px" }}
                    onClick={() => this.updateInteractivePanelTitle(panel)}
                    title="Changement du titre du panel"
                  >
                    <Fa icon="marker" />
                  </a>
                  <a
                    onClick={() => {
                      this.setState({
                        openPanelDuplication: true,
                        panelClicked: panel
                      });
                    }}
                  >
                    <Fa icon="clone" title={"clone"} fixedWidth />
                  </a>
                  <a
                    onClick={() => {
                      this.setState({
                        openPanelDuplicationSQL: true,
                        panelClicked: panel
                      });
                    }}
                  >
                    <Fa icon="sign-out-alt" title={"Extraction SQL"} fixedWidth />
                  </a>
                </>
              }
            >
              <div className="flex justify-center items-center" style={{ height: "100%" }}>
                <div>
                  <div className="has-text-weight-light is-size-4" style={{ textAlign: "center" }}>
                    {panel.panelType}
                  </div>
                  <div className="buttons">
                    <Button
                      className="is-link"
                      outlined
                      title={t("commun_association_creator_panel")}
                      onClick={() => this.openCreateCreatorPanel(panel.panelId)}
                    >
                      {t("commun_association_creator_panel")}
                    </Button>

                    {panel.panelType === TypeComplexComponent.INFORMATION ? (
                      <Button
                        className="is-link"
                        outlined
                        title="Association Package - Panel"
                        onClick={() => this.openPanelInfo(panel.panelId)}
                      >
                        {t("commun_package")}
                      </Button>
                    ) : null}
                    {panel.panelType === TypeComplexComponent.NAV_CARDS ||
                    panel.panelType === TypeComplexComponent.DGL ||
                    panel.panelType === TypeComplexComponent.SAS ||
                    panel.panelType === TypeComplexComponent.INDICATEUR ||
                    panel.panelType === TypeComplexComponent.ARBO ||
                    panel.panelType === TypeComplexComponent.DATATABLE ||
                    panel.panelType === TypeComplexComponent.MINI_EXPERT ? (
                      <Button
                        title="Accès à l'écran d'admin correspondant au type de panel"
                        className="is-link"
                        outlined
                        onClick={() => this.onAccessToAdmin(panel)}
                      >
                        {t("commun_definition")}
                      </Button>
                    ) : null}
                    {panel.panelType === TypeComplexComponent.INTERACTIVE_REPORT ? (
                      <Button
                        title="Accès à l'écran d'admin correspondant au type de panel"
                        className="is-link"
                        outlined
                        onClick={() => this.onAccessToAdminIR(panel)}
                      >
                        {t("commun_definition")}
                      </Button>
                    ) : null}
                    {panel.panelType === TypeComplexComponent.DATATABLE ? (
                      <Button
                        title="Association Datatable - Panel"
                        className="is-link"
                        outlined
                        onClick={() => this.onOpenLink(panel)}
                      >
                        {t("commun_proprietes")}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center"></div>
            </Panel>
          </div>
        );
      });

    return listPanel;
  };

  onAccessToAdmin = (panel: PanelState) => {
    if (panel.panelType === TypeComplexComponent.DATATABLE) {
      const url = `/admin/datatable/${panel.panelTableName}`;
      this.openInNewTab(url);
    } else if (panel.panelType === TypeComplexComponent.MINI_EXPERT) {
      // il faut récupérer l'id de syjTables pour contextualiser le mini-expert
      const url = `/admin/miniexpert/${this.props.galaxyProperties.syjTablesId}`;
      this.openInNewTab(url);
    } else if (panel.panelType === TypeComplexComponent.NAV_CARDS) {
      const url = `/admin/navcard/${panel.panelId}`;
      this.openInNewTab(url);
    } else if (panel.panelType === TypeComplexComponent.DGL) {
      this.onAccesToAdminDgl(panel);
    } else if (panel.panelType === TypeComplexComponent.SAS) {
      const url = `/admin/sas/${panel.panelId}`;
      this.openInNewTab(url);
    } else if (panel.panelType === TypeComplexComponent.INDICATEUR) {
      const url = `/admin/indicateur/${panel.panelId}`;
      this.openInNewTab(url);
    } else if (panel.panelType === TypeComplexComponent.ARBO) {
      const url = `/admin/arborescence/${panel.panelId}`;
      this.openInNewTab(url);
    }
  };

  onOpenLink = (panel: PanelState) => {
    this.setState({ openPanelTable: true, currentPanelId: panel.panelId });
  };

  /**
   * Sélection des panels n'appartenant pas au focus sélectionné
   */
  createHiddenPanels = (panels: PanelState[]) => {
    if (panels.length <= 0) {
      return null;
    }

    const listPanel = panels
      .filter(panel => !panel.visible)
      .map(panel => {
        return (
          <div
            className="card"
            key={panel.panelId.toString()}
            style={{ overflow: "hidden", borderRadius: 5, margin: "10px" }}
          >
            <div className="card-content">
              <div className="content">{panel.panelLabel}</div>
            </div>
            <footer className="card-footer">
              <div className="card-footer-item">
                <Dropdown autoclose>
                  <DropdownButton
                    render={param => (
                      <Button
                        style={{ border: "none" }}
                        title={t("commun_suprimer")}
                        ref={param.buttonRef}
                        outlined
                        color="link"
                        onClick={param.onOpen}
                      >
                        <span className="icon">
                          <Fa icon="trash" />
                        </span>
                      </Button>
                    )}
                  />
                  <DropdownMenu
                    render={param => (
                      <div className="dropdown-item">
                        <p>{t("commun_sur_de_suprimer")}</p>
                        <button
                          className="button is-danger is-fullwidth is-small"
                          onClick={() => {
                            param.onClose();
                            this.props.deletePanel(ADMIN_GALAXIE, "syjPanel", panel, () =>
                              this.props.fetchPanels(
                                this.props.match.params.id,
                                this.props.selectedFocus
                              )
                            );
                          }}
                        >
                          {t("commun_oui")}
                        </button>
                      </div>
                    )}
                  />
                </Dropdown>
              </div>
              <a
                className="card-footer-item"
                onClick={(e: any) => {
                  this.onPanelAddedToFocus(panel.panelId);
                }}
              >
                <span className="icon">
                  <Fa icon="arrow-right" />
                </span>
              </a>
            </footer>
          </div>
        );
      });

    return listPanel;
  };

  onPanelClose = (sjpaId: string) => {
    this.props.closePanel(this.props.match.params.id, sjpaId);
  };

  onLayoutChange = (layouts: Layout[]) => {
    this.props.layoutChange(this.props.match.params.id, layouts);
  };

  onPanelAddedToFocus = (sjpaId: string) => {
    this.props.addPanelToFocus(this.props.match.params.id, sjpaId);
  };

  createMainGenSearch = () => {
    return (
      <AutoComplete
        controlProps={{
          expanded: true,
          iconsLeft: (
            <span className="icon is-small is-left">
              <Fa icon="table" />
            </span>
          )
        }}
        sjmoCode={ADMIN_GALAXIE}
        id="tSjmoMainGenSearch"
        joinTableName={"syjModule"}
        joinListFields={"sjmoCode"}
        additionalClause={GS_SEARCH}
        value={this.mainEntityId}
        onItemChange={this.onChangeGS}
      />
    );
  };

  onChangeGS = (pojo: Pojo | null) => {
    if (pojo != null) {
      this.setState({ currentSjmoId: pojo.id }, () => {
        const url = pojo ? `/admin/galaxie/${pojo.sjmoCode}` : `/admin/galaxie`;
        this.props.history.push(url);
      });
    } else {
      this.setState({ currentSjmoId: null });
      const url = `/admin/galaxie`;
      this.props.history.push(url);
    }
    // ** ici faire une action qui met a jour sjmoCode dans le reducer
  };

  createLeftButtonBar = () => {
    return (
      <div>
        {/*west header*/}

        <Field style={{ marginRight: "1em", display: "inline-flex" }} addons>
          <Control>
            <Button
              title="Sauvegarder"
              className={classNames({
                "is-danger": this.props.isDirty,
                "is-link": !this.props.isDirty
              })}
              outlined
              color="link"
              onClick={this.saveAll}
            >
              <span className="icon">
                <Fa icon="save" />
              </span>
            </Button>
          </Control>
          <Control>
            <Link
              to={`/page/${this.props.match.params.id}`}
              title="Naviguer"
              className="button is-link is-outlined"
              target="_blank"
            >
              Naviguer vers la galaxie &rarr;
            </Link>
          </Control>
        </Field>
      </div>
    );
  };

  saveAll = () => {
    this.props.saveAllAdmin(this.props.match.params.id);
  };

  createFocusMenu = () => {
    const { galaxyFocuses, selectedFocus } = this.props;
    return (
      <div>
        <Field addons>
          <FocusMenu
            focuses={galaxyFocuses}
            currentFocus={selectedFocus}
            onFocusChange={this.onFocusChange}
            personnalisable={false}
          />
          <Control>
            <Dropdown autoclose>
              <DropdownButton
                render={param => (
                  <Button
                    title={t("commun_nouveau_focus")}
                    outlined
                    color="link"
                    ref={param.buttonRef}
                    onClick={param.onOpen}
                    disabled={!this.state.currentSjmoId || this.state.currentSjmoId == null}
                  >
                    <span className="icon">
                      <Fa icon="plus" />
                    </span>
                  </Button>
                )}
              />

              <Button
                title={t("commun_edition_focus")}
                outlined
                color="link"
                // ref={param.buttonRef}
                onClick={() => {
                  this.setState({ editAction: true });
                }}
                disabled={!this.state.currentSjmoId || this.state.currentSjmoId == null}
              >
                <span className="icon">
                  <Fa icon="pen" />
                </span>
              </Button>
              <Button
                title={t("Duplication du focus vers une autre galaxie")}
                outlined
                color="link"
                // ref={param.buttonRef}
                onClick={() => {
                  this.setState({ openFocusDuplication: true });
                }}
                disabled={!this.state.currentSjmoId || this.state.currentSjmoId == null}
              >
                <span className="icon">
                  <Fa icon="clone" />
                </span>
              </Button>
              <Button
                title={t("Extraction SQL du focus")}
                outlined
                color="link"
                // ref={param.buttonRef}
                onClick={() => {
                  this.setState({ openFocusDuplicationSQL: true });
                }}
                disabled={!this.state.currentSjmoId || this.state.currentSjmoId == null}
              >
                <span className="icon">
                  <Fa icon="sign-out-alt" />
                </span>
              </Button>

              <DropdownMenu
                render={param => (
                  <div className="dropdown-item">
                    {associationTypes.map(type => {
                      return (
                        <a
                          className="dropdown-item"
                          key={type}
                          onClick={() => {
                            this.setState({ createAction: type }, param.onClose);
                          }}
                        >
                          {t("commun_focus_" + type)}
                        </a>
                      );
                    })}
                  </div>
                )}
              />
            </Dropdown>
          </Control>
          <Control>
            <Dropdown autoclose>
              <DropdownButton
                render={param => (
                  <Button
                    title={t("commun_supprimer_focus")}
                    outlined
                    color="link"
                    ref={param.buttonRef}
                    onClick={param.onOpen}
                    disabled={!this.state.currentSjmoId || this.state.currentSjmoId == null}
                  >
                    <span className="icon">
                      <Fa icon="trash" />
                    </span>
                  </Button>
                )}
              />
              <DropdownMenu
                render={param => (
                  <div className="dropdown-item">
                    <p>{t("commun_sur_de_suprimer")}</p>
                    <Row>
                      <Col>
                        <button
                          className="button is-danger  is-small"
                          onClick={() => {
                            if (this.props.selectedFocus) {
                              this.props.deleteSelectedFocus(
                                this.props.selectedFocus,
                                this.props.match.params.id
                              );
                              param.onClose();
                            }
                          }}
                        >
                          {t("commun_oui")}
                        </button>
                      </Col>
                      <Col>
                        <button className="button  is-small" onClick={param.onClose}>
                          {t("commun_non")}
                        </button>
                      </Col>
                    </Row>
                  </div>
                )}
              />
            </Dropdown>
          </Control>
          <Control>
            <Button
              className="is-link"
              outlined
              title={t("commun_tootlip_galaxy_association")}
              disabled={this.props.selectedFocus === undefined || this.props.selectedFocus === null}
              onClick={() => {
                this.setState({ openGalaxyFocusAssociation: true });
              }}
            >
              {t("commun_associations")}
            </Button>
          </Control>
        </Field>
      </div>
    );
  };

  onFocusChange = (focusId: string) => {
    this.props.fetchDashboardPanelAndChangeFocus(this.props.match.params.id, focusId);
  };
}

const selectDashboardDefinition = (state: ReducerState, sjmoCode: string) =>
  state.dashboard.dashboardDefinitions[sjmoCode];

const selectSelectedFocus = (state: ReducerState, sjmoCode: string) =>
  state.dashboard.selected[sjmoCode];

const selectIndexActiveFocus = (state: ReducerState, sjmoCode: string, focusId: string) => {
  const def = state.dashboard.dashboardDefinitions[sjmoCode];
  return def ? def.findIndex(el => el.focusId === focusId) : null;
};

function mapStateToProps(state: ReducerState, ownProps: AdminGalaxyAllProps) {
  // récupération de la définition correspondante
  const sjmoCode = ownProps.match.params.id;

  let panels: PanelState[] = [];
  const currentModuleDefinitions = selectDashboardDefinition(state, sjmoCode);
  const selectedFocus = selectSelectedFocus(state, sjmoCode);
  const index = selectIndexActiveFocus(state, sjmoCode, selectedFocus);
  const galaxyFocuses = selectDashboardDefinition(state, sjmoCode);
  const isDirty = state.adminGalaxy.isDirty;
  const currentGalaxyPropertiesId = state.adminGalaxy.currentGalaxyPropertiesId;
  let galaxyProperties = {} as Pojo;
  let currentSjtaName = "";

  if (currentGalaxyPropertiesId && state.entities[ADMIN_GALAXIE]) {
    const syjGalaxyPropertiesName = "syjGalaxyProperties";
    const syjTablesName = "syjTables";
    galaxyProperties =
      state.entities[ADMIN_GALAXIE][syjGalaxyPropertiesName][currentGalaxyPropertiesId];

    if (state.entities[ADMIN_GALAXIE][syjTablesName][galaxyProperties.syjTablesId]) {
      currentSjtaName =
        state.entities[ADMIN_GALAXIE][syjTablesName][galaxyProperties.syjTablesId].sjtaName;
    }
  }

  panels =
    currentModuleDefinitions && index !== null && currentModuleDefinitions[index]
      ? currentModuleDefinitions[index].panels
      : [];
  return {
    galaxyFocuses,
    selectedFocus,
    panels,
    isDirty,
    galaxyProperties,
    currentSjtaName
  };
}

export default withTranslation()(
  reduxConnect<AdminGalaxyProps, AdminGalaxyPropsFn>(mapStateToProps, {
    initGalaxyInAdministration,
    layoutChange,
    saveAllAdmin,
    addPanelToFocus,
    fetchDashboardPanelAndChangeFocus,
    setDirty,
    closePanel,
    createNewPanel,
    deletePanel,
    onValueChange,
    addEntity,
    onCreateNewGalaxy,
    onCreateOrUpdateGalaxyFocus,
    addMessage,
    deleteSelectedFocus,
    fetchPanels,
    refreshFocusList
  })(AdminGalaxy)
);
