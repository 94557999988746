import { AxiosPromise, CancelToken, CancelTokenSource } from "axios";

import {
  api,
  getUIContext,
  createContextPreRecord,
  getFilterBar,
  apiAdn,
  apiUtils
} from "./common";
import { Pojo, WhenValidateItemResult, PojoLabel } from "types/Galaxy";
import { PagedResource, FilterBar } from "types/Search";
import { chainPromiseAll } from "utils/network.utils";
import { GroupByFunctionParameter, AggregateProperties } from "types/InteractiveReport";

export interface FindOneParams {
  tableName: string;
  id: string;
  includeJoinParent?: boolean;
  includeOels?: string[];
  includeStyle?: boolean;
  labelContext?: string;
  labelDetailsContext?: string;
  filter?: string;
  sjmoCode?: string;
}

export interface FindAllParams {
  sjmoCode?: string;
  contextKey?: string;
  tableName: string;
  filter?: string;
  first?: number;
  size?: number | false;
  includeJoinParent?: boolean;
  includeOels?: string[];
  includeStyle?: boolean;
  includeRowClassName?: string;
  labelContext?: string;
  labelDetailsContext?: string;
  filterBar?: FilterBar;
}

export interface FindViewParams {
  sjmoCode?: string;
  contextKey?: string;
  tableName: string;
  filter?: string;
  first?: number;
  size?: number | false;
  filterBar?: FilterBar;
  groupBy?: string[];
  includes?: string[];
}

function getApi(tableName: string) {
  if (tableName.startsWith("syj") || tableName === "sysDomaine" || tableName === "sysDomaineVal") {
    return apiAdn;
  } else {
    return api;
  }
}

/**
 * Fonction qui récupère la liste des focus d'un mini expert de galaxie
 * @param tableName nom de la table sur laquelle on récupère les data
 * @param id id de la table
 */
export function findOne(
  {
    tableName,
    id,
    includeJoinParent = false,
    includeOels = [],
    includeStyle = false,
    labelContext,
    labelDetailsContext,
    filter = "",
    sjmoCode
  }: FindOneParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<Pojo> {
  const params = getUIContext({
    sjmoCode,
    labelContext: labelContext,
    labelDetailsContext: labelDetailsContext
  });

  if (includeJoinParent) {
    params.append("includeJoinParent", "true");
  }

  if (includeOels.length > 0) {
    params.append("includeOels", includeOels.toString());
  }

  if (includeStyle) {
    params.append("includeStyle", "true");
  }

  return getApi(tableName).get(`/${tableName}/${encodeURIComponent(id)}?${params}&${filter}`, {
    cancelToken: cancelToken && cancelToken.token
  });
}

/**
 * Permet de génerer une query de recherche via URLSearchParams
 * @param configuration paramètre nécessaire pour générer l'URLSearchParams
 */
export function configureUrlParamsForSearch({
  sjmoCode,
  contextKey,
  filter = "",
  first = 0,
  size = 15,
  includeJoinParent = false,
  includeOels = [],
  includeStyle = false,
  includeRowClassName,
  labelContext,
  labelDetailsContext,
  filterBar
}: FindAllParams) {
  const params = getUIContext({
    sjmoCode,
    contextKey,
    labelContext: labelContext,
    labelDetailsContext: labelDetailsContext
  });
  params.append("first", first.toString());
  params.append("size", size.toString());

  if (includeJoinParent) {
    params.append("includeJoinParent", "true");
  }

  if (includeOels.length > 0) {
    params.append("includeOels", includeOels.toString());
  }

  if (includeStyle) {
    params.append("includeStyle", "true");
  }

  if (includeRowClassName) {
    params.append("includeRowClassName", includeRowClassName);
  }

  const filterBarParams = getFilterBar(filterBar);

  const allParams = [params, filterBarParams, filter];

  return allParams
    .map(p => p.toString())
    .filter(p => p.length > 0)
    .join("&");
}

export function findAll<T = Pojo>(
  params: FindAllParams,
  cancel?: CancelTokenSource
): AxiosPromise<PagedResource<T>> {
  const allParamsStr = configureUrlParamsForSearch(params);
  return getApi(params.tableName).get(`/${params.tableName}?${allParamsStr}`, {
    cancelToken: cancel && cancel.token
  });
}

export function find(
  tableName: string,
  filter: string = "",
  first: number = 0,
  size: number = 15,
  includeJoinParent: boolean = false,
  includeOels: string[] = [],
  includeStyle: boolean = false,
  labelContext?: string,
  labelDetailsContext?: string
): AxiosPromise<PagedResource<Pojo>> {
  return findAll({
    tableName,
    filter,
    first,
    size,
    includeJoinParent,
    includeOels,
    includeStyle,
    labelContext,
    labelDetailsContext
  });
}

export function whenValidateItem(
  sjmoCode: string,
  tableName: string,
  column: string,
  entity: Pojo,
  includeOels: string[] = []
): AxiosPromise<WhenValidateItemResult<Pojo>> {
  const params = getUIContext({ sjmoCode, column });

  if (includeOels.length > 0) {
    params.append("includeOels", includeOels.toString());
  }

  return getApi(tableName).post(`/${tableName}/wvi?${params}`, entity);
}
// TODO : mettre en place le crud mini pour gérer les tables

// Method qui gère la sauvegarde d'une liste d'élément
export function createMany(
  tableName: string,
  entities: Pojo[],
  sjmoCode: string,
  includeStyle?: boolean
): AxiosPromise<Pojo[]> {
  const params = getUIContext({ sjmoCode });

  if (includeStyle) {
    params.append("includeStyle", "true");
  }

  return getApi(tableName).post(`/${tableName}?${params}`, entities);
}

/**
 * Permet de demander une suppression d'entités au serveur
 * @param tableName nom de la table
 * @param entities liste des ids des entités à supprimer
 */
export function deleteMany(
  tableName: string,
  entities: (number | string)[],
  sjmoCode: string
): AxiosPromise<void> {
  const params = getUIContext({ sjmoCode });
  return getApi(tableName).delete(`/${tableName}?${params}`, { data: entities });
}

/**
 * Permet de demander une suppression d'entités au serveur
 * @param tableName nom de la table
 * @param filter nom de la colonne sur laquelle la suppression va se baser
 * @param sjmoCode code module
 */
export function deleteByFilter(
  tableName: string,
  filter: string,
  sjmoCode: string
): AxiosPromise<void> {
  const params = getUIContext({ sjmoCode });
  return getApi(tableName).delete(`/${tableName}/by-filter?${params}&${filter}`);
}

/**
 * Fonction qui exécute le prerecord d'une table
 * @param sjmoCode code module
 * @param tableName table Name
 */
export function preRecord({
  sjmoCode,
  tableName,
  context,
  includeOels = [],
  focusId
}: {
  sjmoCode: string;
  tableName: string;
  context?: Record<string, any>;
  includeOels?: string[];
  focusId?: string | null;
}): AxiosPromise<Pojo> {
  const uiContext: URLSearchParams = getUIContext({ sjmoCode, focusId });
  const matrix = createContextPreRecord(context);
  const isMatrix = context && Object.keys(context).length > 0;

  const includeOelsParam = includeOels.length > 0 ? `&includeOels=${includeOels.toString()}` : "";

  return getApi(tableName).get(
    `/${tableName}/preRecord?${uiContext}${isMatrix ? "&" + matrix : ""}${includeOelsParam}`
  );
}

export function duplicate(
  sjmoCode: string,
  tableName: string,
  entity: Pojo,
  context?: Record<string, any>,
  includeOels: string[] = []
): AxiosPromise<Pojo> {
  const uiContext: URLSearchParams = getUIContext({ sjmoCode });
  const matrix = createContextPreRecord(context);
  const isMatrix = context && Object.keys(context).length > 0;
  const includeOelsParam = includeOels.length > 0 ? `&includeOels=${includeOels.toString()}` : "";
  return getApi(tableName).post(
    `/${tableName}/duplicate?${uiContext}${isMatrix ? "&" + matrix : ""}${includeOelsParam}`,
    entity
  );
}

/**
 * Permet de mettre à jour le field d'une entité avec l'id, le field et la valeur souhaité.
 *
 * @export
 * @param {string} sjmoCode code du module
 * @param {string} tableName nom de la table
 * @param {string} id id de l'entité
 * @param {string} field colonne à mettre à jour
 * @param {*} value valeur voulu
 * @returns {AxiosPromise<Pojo>}
 */
export function updateEntity(
  sjmoCode: string,
  tableName: string,
  id: string,
  field: string,
  value: any
): AxiosPromise<Pojo> {
  const uiContext = getUIContext({ sjmoCode });
  return getApi(tableName).put(`/${tableName}/${id}?${uiContext}`, { [field]: value });
}

/**
 * Permet de mettre à jour une liste d'entité avec la liste d'id, le field et la valeur souhaité.
 *
 * @export
 * @param {string} sjmoCode code du module
 * @param {string} table nom de la table
 * @param {string[]} ids liste des ids
 * @param {string} field colonne à mettre à jour
 * @param {*} value valeur voulu
 * @returns {AxiosPromise<Pojo>}
 */
export function updateEntities(
  sjmoCode: string,
  table: string,
  ids: string[],
  field: string,
  value: any
): Promise<Pojo[]> {
  let promises = ids.map(id => () => updateEntity(sjmoCode, table, id, field, value));

  return chainPromiseAll(promises).then(all => all.map(res => res.data));
  // return Promise.all(result).then(all => all.map(res => res.data));
}

/**
 * Fonction générique utilisé pour enregistré le résultat d'une picklist.
 *
 * Elle appelle le service eponyme.
 * Ce service delete en base tout les éléments dans oldIdToDelete.
 * Puis insert les éléments passés dans chosen.
 *
 * ATTENTION : cette méthode n'appel pas le pré-record les entités en entrée doivent être prête à l'enregistrement.
 *
 * @export
 * @param {sjmoCode} sjmoCode
 * @param {Pojo} chosen
 * @param {string} tableNme
 * @param {string[]} oldIdToDelete
 */
export function savePickListResultApi(
  sjmoCode: string,
  tableName: string,
  chosen: Partial<Pojo>[],
  oldIdToDelete: string[]
): AxiosPromise<Pojo[]> {
  const uiContext = getUIContext({ sjmoCode });
  return getApi(tableName).post(`/${tableName}/savePickListResult?${uiContext}`, {
    chosen,
    oldIdToDelete
  });
}

export function findView(params: FindViewParams): AxiosPromise<PagedResource<Pojo>> {
  const allParamsStr = configureUrlParamsForSearch(params);

  let groupByStr = "";
  if (params.groupBy) {
    groupByStr = "&groupBy=" + params.groupBy.join(",");
  }

  let includesStr = "";
  if (params.includes) {
    includesStr = "&includes=" + params.includes.join(",");
  }

  return apiUtils.get(`/view/${params.tableName}?${allParamsStr}${groupByStr}${includesStr}`);
}

export function findViewColumns(params: FindViewParams): AxiosPromise<string[]> {
  const allParamsStr = configureUrlParamsForSearch(params);

  return apiUtils.get(`/view/${params.tableName}/columns?${allParamsStr}`);
}

export interface FindViewComplexParams {
  sjmoCode?: string;
  contextKey?: string;
  tableName: string;
  filter?: string;
  first?: number;
  size?: number | false;
  filterBar?: FilterBar;
  groupBy: {
    columns: string[];
    functionList: GroupByFunctionParameter[];
  };
  aggregates?: AggregateProperties[];
  includes?: string[];
  breakRows?: string[];
}

type BackendAggregateProperties = AggregateProperties & { overColumns: string[] };
export interface FindViewComplexBody {
  includes?: string[];
  groupBy: {
    groupBy: string[];
    functions: GroupByFunctionParameter[];
  };
  aggregates: BackendAggregateProperties[];
}

export function findViewComplex<T = Pojo>(
  params: FindViewComplexParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PagedResource<T>> {
  const allParamsStr = configureUrlParamsForSearch(params);

  const body: FindViewComplexBody = {
    includes: params.includes,
    groupBy: {
      groupBy: params.groupBy.columns || [],
      functions: params.groupBy.functionList || []
    },
    aggregates: (params.aggregates || []).map(aggr => {
      return { ...aggr, overColumns: params.breakRows || [] };
    })
  };

  return apiUtils.post(`/view/${params.tableName}?${allParamsStr}`, body, {
    cancelToken: cancelToken && cancelToken.token
  });
}

export function getEntityLabels(
  sjmoCode: string,
  tableName: string,
  id: string
): AxiosPromise<{ table: string; entity: string }> {
  const rqParams = getUIContext({ sjmoCode });
  rqParams.append("tableName", tableName);
  rqParams.append("id", id);

  return getApi(tableName).get(`/${tableName}/labels?${rqParams}`);
}
