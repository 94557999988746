import TabExtension from "composants/satellite/TabExtension";
import TabCommentaire from "composants/satellite/TabCommentaire";
import TabDocument from "composants/satellite/TabDocument";
import TabConfigurateur from "composants/satellite/TabConfigurateur";
import TabArticulation from "composants/satellite/TabArticulation";
import TabValidation from "composants/satellite/TabValidation";
import TabHistoriqueMail from "composants/satellite/TabHistoriqueMail";

// Action relative au données des données Satellites
export const FETCH_ARTICULATIONS = "FETCH_ARTICULATIONS";
export const FETCH_COMMENTAIRES = "FETCH_COMMENTAIRES";
export const FETCH_CONFIGURATEURS = "FETCH_CONFIGURATEURS";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_EXTENSIONS = "FETCH_EXTENSIONS";
export const FETCH_MAILS = "FETCH_MAILS";
export const FETCH_VALIDATIONS = "FETCH_VALIDATIONS";

// Action relative au count des données Satellites
export const FETCH_ARTICULATIONS_COUNT = "FETCH_ARTICULATIONS_COUNT";
export const FETCH_COMMENTAIRES_COUNT = "FETCH_COMMENTAIRES_COUNT";
export const FETCH_CONFIGURATEURS_COUNT = "FETCH_CONFIGURATEURS_COUNT";
export const FETCH_DOCUMENTS_COUNT = "FETCH_DOCUMENTS_COUNT";
export const FETCH_EXTENSIONS_COUNT = "FETCH_EXTENSIONS_COUNT";
export const FETCH_MAILS_COUNT = "FETCH_MAILS_COUNT";
export const FETCH_VALIDATIONS_COUNT = "FETCH_VALIDATIONS_COUNT";

// Action relative au count des données Satellites
export const FETCH_ARTICULATIONS_COUNT_SUCESS = "FETCH_ARTICULATIONS_COUNT_SUCESS";
export const FETCH_COMMENTAIRES_COUNT_SUCESS = "FETCH_COMMENTAIRES_COUNT_SUCESS";
export const FETCH_CONFIGURATEURS_COUNT_SUCESS = "FETCH_CONFIGURATEURS_COUNT_SUCESS";
export const FETCH_DOCUMENTS_COUNT_SUCESS = "FETCH_DOCUMENTS_COUNT_SUCESS";
export const FETCH_EXTENSIONS_COUNT_SUCESS = "FETCH_EXTENSIONS_COUNT_SUCESS";
export const FETCH_MAILS_COUNT_SUCESS = "FETCH_MAILS_COUNT_SUCESS";
export const FETCH_VALIDATIONS_COUNT_SUCESS = "FETCH_VALIDATIONS_COUNT_SUCESS";

export const FETCH_SATELLITES_ALL_COUNT = "FETCH_SATELLITES_ALL_COUNT";

export const UPDATE_CONTEXT = "UPDATE_CONTEXT";
export const CLEAR_CONTEXT = "CLEAR_CONTEXT";
export const CLEAR_SATELLITES_ALL_COUNT = "CLEAR_SATELLITES_ALL_COUNT";

// Libelles en dur représentant un type de données satellite dans toutes l'application
export const EXTENSIONS = "extensions";
export const COMMENTAIRES = "commentaires";
export const DOCUMENTS = "documents";
export const CONFIGURATEURS = "configurateurs";
export const ARTICULATIONS = "articulations";
export const VALIDATIONS = "validations";
export const MAILS = "mails";

export const DEFAULT_SATELLITES = COMMENTAIRES;

export const SATELLITES = {
  [COMMENTAIRES]: { icon: "comment-dots", compo: TabCommentaire, title: "commun_commentaire" },
  [EXTENSIONS]: { icon: "expand-arrows-alt", compo: TabExtension, title: "commun_extension" },
  [DOCUMENTS]: { icon: "paperclip", compo: TabDocument, title: "commun_document" },
  [CONFIGURATEURS]: { icon: "cog", compo: TabConfigurateur, title: "commun_configurateur" },
  [ARTICULATIONS]: { icon: "link", compo: TabArticulation, title: "commun_articulation" },
  [VALIDATIONS]: {
    icon: "pencil",
    compo: TabValidation,
    title: "commun_validation"
  },
  [MAILS]: { icon: "envelope", compo: TabHistoriqueMail, title: "commun_historique_mail" }
};

export const SATELLITES_KEYS = Object.keys(SATELLITES);
