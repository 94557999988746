import React, { Component, CSSProperties } from "react";
import "./dashboard.css";
import { PanelState } from "types/Dashboard";
import { TypeComplexComponent } from "enum";
import { Fa } from "composants/Icon";
import { copyToClipboard } from "utils/clipboard.utils";

interface PanelLightProps {
  style?: CSSProperties;
  onPanelClose: Function;
  noPaddingContent?: boolean;
  isOverflowScroll?: boolean;
  panelId: string;
  leftHeader?: React.ReactNode;
  mandatory: boolean;
}

class PanelLight extends Component<PanelLightProps> {
  onPanelClose = () => {
    this.props.onPanelClose(this.props.panelId);
  };

  render() {
    return (
      <div
        className="panel-light-wrapper"
        style={{
          ...this.props.style,
          backgroundColor: "transparent",
          boxShadow: "none",
          WebkitBoxShadow: "none"
        }}
      >
        <header className="header-light">
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                backgroundColor: "hsla(213, 47%, 94%, 1)",
                borderRadius: 999999,
                padding: "0 5px"
              }}
            >
              <span className="card-header-draggable">
                <Fa icon={["fal", "grip-lines"]} fixedWidth />
              </span>
              <a
                onClick={() => {
                  copyToClipboard(this.props.panelId.toString());
                }}
              >
                <Fa icon="info" fixedWidth title={this.props.panelId.toString()} />
              </a>
              {this.props.leftHeader}
              {!this.props.mandatory && (
                <a onClick={this.onPanelClose} aria-label="close">
                  <Fa icon="times" fixedWidth />
                </a>
              )}
            </div>
          </div>
        </header>
        <div
          style={{
            height: "calc(100%)",
            padding: this.props.noPaddingContent ? 0 : undefined,
            overflowY: this.props.isOverflowScroll ? "auto" : undefined,
            overflowX: this.props.isOverflowScroll ? "hidden" : undefined
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PanelLight;
