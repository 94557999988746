import {
  ADMIN_CREATOR_GET_TABLE,
  ADMIN_CREATOR_GET_DEFINITION,
  SAVE_CREATOR_PICKLIST_RESULT,
  CREATOR_SAVE_CLEAR
} from "constant/adminCreator";
import { GET_ALL_ROLE, GET_ALL_USER } from "constant/common";
import { GET_CREATOR_ASSOCIATIONS } from "constant/entities";
import { Pojo } from "types/Galaxy";

export function getTable(tableName: string) {
  return {
    type: ADMIN_CREATOR_GET_TABLE,
    payload: {
      tableName
    }
  };
}

export function getFocusDefinition(focusId: number) {
  return {
    type: ADMIN_CREATOR_GET_DEFINITION,
    payload: {
      focusId
    }
  };
}

export function getAssociations(sjmoCode: string, focusId: string) {
  return { type: GET_CREATOR_ASSOCIATIONS, payload: { sjmoCode, focusId } };
}

export function getAllRoles(sjmoCode: string) {
  return { type: GET_ALL_ROLE, payload: { sjmoCode } };
}

export function getAllUsers(sjmoCode: string) {
  return { type: GET_ALL_USER, payload: { sjmoCode } };
}

export function saveCreatorPickListResult(
  sjmoCode: string,
  tableName: string,
  chosen: Pojo,
  oldIdToDelete: string[]
) {
  return {
    type: SAVE_CREATOR_PICKLIST_RESULT,
    payload: { sjmoCode, tableName, chosen, oldIdToDelete }
  };
}

export function clearCreatorSaveStatus() {
  return { type: CREATOR_SAVE_CLEAR, payload: undefined };
}
