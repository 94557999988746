import React, { PureComponent, FC, useState, useContext, useEffect } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import classNames from "classnames";

import { Dropdown, DropdownButton, DropdownMenu, DropDownContextProps } from "./Dropdown";
import { LovParam } from "types/Component";
import { getListLov } from "api/lov";
import { initLovNavigation } from "utils/navigation.utils";
import { GalaxieContext } from "containers/galaxy/Galaxie";
import { Fa } from "composants/Icon";
import { track } from "tracking";

interface DropdownLovProps {
  buttonClassName?: string;
  sjmoCode: string;
  datatableId: string;
  datatableName: string;
  datatableCtrlKey: string;
}

interface DropdownLovState {
  listOfLov: LovParam[];
}

type DropdownLovAllProps = DropdownLovProps & RouteComponentProps<any>;

const DropdownLov: FC<DropdownLovAllProps> = props => {
  const [listOfLov, setListOfLov] = useState<LovParam[]>([]);

  useEffect(() => {
    let isMounted = true;
    getListLov(props.sjmoCode, props.datatableId, props.datatableName)
      .then(res => {
        isMounted && setListOfLov(res.data);
      })
      .catch(() => {
        console.error("error during fetch of list of LOV");
        isMounted && setListOfLov([]);
      });

    return () => {
      isMounted = false;
    };
  }, [props.sjmoCode, props.datatableId, props.datatableName]);

  const { mainEntityTableName, mainEntityId } = useContext(GalaxieContext);

  return (
    <Dropdown autoclose>
      <DropdownButton
        render={(params: DropDownContextProps) => (
          <button
            ref={params.buttonRef}
            className={classNames("button", props.buttonClassName)}
            onClick={params.onOpen}
          >
            <span className="icon has-text-link">
              <Fa icon="caret-down" />
            </span>
          </button>
        )}
      />
      <DropdownMenu
        render={() => {
          const staticNavigationParams = {
            search: props.location.search,
            sjmoCode: props.sjmoCode,
            lovTargetTableName: props.datatableName,
            lovTargetCtrlKey: props.datatableCtrlKey
          };

          return listOfLov.map(lovParam => {
            const urlSearchLink = initLovNavigation({
              ...staticNavigationParams,
              type: "DT",
              column: lovParam.column,
              tableName: lovParam.tableName,
              contextTableName: mainEntityTableName,
              contextId: mainEntityId,
              syjLovId: lovParam.syjLovId
            });

            return (
              <div
                key={lovParam.syjLovId ? lovParam.syjLovId : lovParam.tableName + lovParam.column}
                className="dropdown-item"
              >
                <Link
                  className="button is-text is-fullwidth"
                  onClick={() => {
                    track("lov::open");
                  }}
                  to={{
                    pathname: props.match.url,
                    search: urlSearchLink.toString()
                  }}
                >
                  {lovParam.label}
                </Link>
              </div>
            );
          });
        }}
      />
    </Dropdown>
  );
};

export default withRouter(DropdownLov);
