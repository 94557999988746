import React, { useEffect, FC, useState } from "react";
import ReactDOM from "react-dom";

import { Provider, useSelector } from "react-redux";
import { Router, Route, Switch, Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { I18nextProvider, useTranslation } from "react-i18next";
import NProgress from "nprogress";

import i18n, { initI18n } from "utils/i18n";
import { configureStore, cacheRedux } from "store";
import history from "customHistory";

import * as serviceWorker from "./serviceWorker";
import registerLogger from "registerLogger";

import App from "layout/App";
import OctalContextMenu from "containers/contextMenu/OctalContextMenu";
import { setupCookieLang } from "utils/network.utils";

import "./index.css";

import { initGlobalInteraction, initInteractionByTable } from "actions/interactions";
import { LoadableLoader, AccessDenied, AuthenticationFailed } from "composants/Loader";
import { fetchUserSettings } from "api";
import { initUserSettings } from "actions/userSettings";
import { isApiDataOK } from "api/apiDataOk";
import { setApiDataOK } from "actions/apiOk";

import "./nprogress.css";

import { fetchApplicationState } from "actions/galaxies.action";
import { startupNotification } from "actions/notifications.action";
import { Store, AnyAction } from "redux";
import { ReducerState } from "reducers";
import useIdle from "hooks/useIdle";
import { clearDatatableCache } from "containers/datatable/Datatable";
import { APP_FONT_SIZE } from "composants/userSettings/SelectSize";
import auth, { initAuth, reconnect } from "auth";
import { QueryClient, QueryClientProvider } from "react-query";
import { ProcessusManager } from "features/processus/processusManager";
import { ToastProvider } from "composants/toaster/toaster";

import { setup } from "twind";

import { twindConfig } from "./twind.config";
setup({ ...twindConfig, hash: string => "tw-" + string });

NProgress.configure({ showSpinner: false });

const AdminApp = React.lazy(() => import("admin"));

function selectIsSuperUser(state: ReducerState) {
  return state.userSettings.isSuperUser;
}
const AdminIfSuperUser: FC = () => {
  const isSuperUser = useSelector(selectIsSuperUser);
  const [t] = useTranslation();
  return isSuperUser ? (
    <AdminApp />
  ) : (
    <div style={{ textAlign: "center", marginTop: "1em" }}>
      <div className="title is-3">{t("commun_accessRefuse")}</div>
      <AccessDenied />
      <div className="subtitle is-2">
        <Link to="/" className="underline-link">
          {t("commun_retourAccueil")}
        </Link>
      </div>
    </div>
  );
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10_000,
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  }
});

const IndexRoutes = () => {
  const isIdle = useIdle(30 * 1000);

  useEffect(() => {
    console.log("user is idle ==", isIdle);
  }, [isIdle]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--axin-size", `${localStorage.getItem(APP_FONT_SIZE)}px`);
  }, []);

  return (
    <ProcessusManager>
      <Switch>
        <Route path="/admin" component={AdminIfSuperUser} />
        <Route component={App} />
      </Switch>
      <OctalContextMenu />
    </ProcessusManager>
  );
};

type STATUS_AUTH = "INITIAL" | "LOGGED" | "ERROR";

const AuthLoader: FC<{ store: Store<ReducerState, AnyAction> }> = ({ store, children }) => {
  const [status, setStatus] = useState<STATUS_AUTH>(auth.authenticated ? "LOGGED" : "INITIAL");

  useEffect(() => {
    if (status === "INITIAL") {
      initAuth()
        .then(authenticated => {
          if (!authenticated) {
            setStatus("ERROR");
            return;
          }

          initI18n();

          const applicationStatesPromise = store.dispatch(fetchApplicationState());
          store.dispatch(initGlobalInteraction());
          store.dispatch(initInteractionByTable());
          store.dispatch(startupNotification());

          return Promise.all([
            applicationStatesPromise,
            fetchUserSettings().then(response => {
              try {
                store.dispatch(initUserSettings(response.data));
                setupCookieLang(response.data.lang);
                i18n.changeLanguage(response.data.lang);
              } catch {
                // on arrive parfois ici, yollo catch pour éviter d'avoir la page blanche.
                setStatus("ERROR");
              }
            }),
            isApiDataOK()
              .then(() => {
                store.dispatch(setApiDataOK(true));
              })
              .catch(() => {
                store.dispatch(setApiDataOK(false));
              })
          ]);
        })
        .then(() => {
          setStatus("LOGGED");
        })
        .catch(() => {
          setStatus("ERROR");
        });
    }
  }, [status, store]);

  switch (status) {
    case "INITIAL":
      return null;
    case "ERROR":
      return (
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <div className="title is-3">Authentication failed</div>
          <AuthenticationFailed />
          <div>
            <button className="button is-link" onClick={reconnect}>
              reconnect
            </button>
          </div>
        </div>
      );
    case "LOGGED":
      return <Root store={store} />;
  }
};

const Root: FC<{ store: Store<ReducerState, AnyAction> }> = ({ store }) => {
  return (
    <React.Suspense fallback={<LoadableLoader />}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <HelmetProvider>
              <Helmet defaultTitle="Octal" titleTemplate="Octal : %s" />

              <Router history={history}>
                <ToastProvider>
                  <IndexRoutes />
                </ToastProvider>
              </Router>
            </HelmetProvider>
          </Provider>
        </QueryClientProvider>
      </I18nextProvider>
    </React.Suspense>
  );
};

// Lors de la fermeture de la page
// on clear le cache lié au TAB_ID courant.
// car, à chaque F5, le cache est perdu parce que le tabID change.
window.addEventListener("beforeunload", () => {
  clearDatatableCache();
});

cacheRedux.getAll().then((data: any) => {
  const store = configureStore(data);
  if (!store) {
    return;
  }

  ReactDOM.render(<AuthLoader store={store} />, document.getElementById("root") as HTMLElement);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

registerLogger();
