import React, { SFC, SyntheticEvent, KeyboardEvent, CSSProperties, PureComponent } from "react";
import { GridCellProps } from "react-virtualized";

import classNames from "classnames";
import get from "lodash-es/get";
import memoize from "memoizee";

import { LoadingPojo, Pojo } from "types/Galaxy";
import { ComponentState } from "types/Component";
import { TableDataType, CellMeasurerCacheContext } from "./Table";
import { FilterProps } from "./TableFilter";
import { MeasuredCellParent, CellMeasurer } from "react-virtualized/dist/es/CellMeasurer";

export type ColumnCalculateProperties = (
  entity: TableDataType,
  col: ColumnProps,
  rowIndex: number,
  waitForInitialization: boolean
) => Record<string, any>;

export type CustomRenderProperties = {
  col: ColumnProps;
  colorRow: ColorRow;
  data: Pojo;
  rowIndex: number;
  columnIndex: number;
  className: string;
  style: CSSProperties;
  onRowClick?(rowIndex: number): void;
};

export type CustomRenderHeaderCell = {
  key: string;
  className: string;
  style: React.CSSProperties;
  columnIndex: number;
  parent: MeasuredCellParent;
};

export interface ColumnProps<P = any> {
  className?: string;
  column: ComponentState;
  component: React.ComponentType<P>;
  width: number;
  componentProperties?: ColumnCalculateProperties;
  renderHeaderCell: (props: CustomRenderHeaderCell) => React.ReactNode;
  // pas de children
  children?: (props: CustomRenderProperties) => React.ReactNode;
}

export const TableColumn: SFC<ColumnProps> = () => {
  return null;
};

type ColorRow = "new" | "modified" | "modified-and-not-sync" | "not-sync" | undefined;

export const ColumnsContext = React.createContext<ColumnProps[]>([]);

type ColumnRendererProps = GridCellProps & {
  className?: string;
  data: TableDataType;
  componentSize?: string;
  showDirty: boolean;
  isRowSelected: boolean;
  isFirstRow: boolean;
  isRowNextSelected: boolean;
  onRowClick?: (rowIndex: number) => void;
};

export class ColumnRenderer extends PureComponent<ColumnRendererProps, { error: any }> {
  static contextType = ColumnsContext;

  state: {
    error: null;
  };

  calculateSelectedRowStyle = memoize(
    (isApplyLeft: boolean, isApplyRight: boolean, isApplyTop: boolean, isApplyBottom: boolean) => {
      const borderStyleApplied = "1px solid #3273dc";

      return {
        borderLeft: isApplyLeft ? borderStyleApplied : undefined,
        borderRight: isApplyRight ? borderStyleApplied : undefined,
        borderTop: isApplyTop ? borderStyleApplied : undefined,
        borderBottom: isApplyBottom ? borderStyleApplied : undefined
      } as CSSProperties;
    },
    { primitive: true }
  );

  componentDidCatch(err: any) {
    console.error(err);
    this.setState({ error: err });
  }

  calculateSelectedRow = () => {
    const { isRowSelected, isFirstRow, isRowNextSelected, columnIndex } = this.props;
    const isApplyLeft = (isRowSelected && columnIndex === 0) || false;
    const isApplyRight = (isRowSelected && columnIndex === this.context.length - 1) || false;
    const isApplyTop = (isRowSelected && isFirstRow) || false;
    const isApplyBottom = (isRowSelected && !isRowNextSelected) || false;

    return this.calculateSelectedRowStyle(isApplyLeft, isApplyRight, isApplyTop, isApplyBottom);
  };

  calculateRowState = (): ColorRow => {
    const { data, columnIndex, showDirty } = this.props;

    const isEntityNull = data === null || data === undefined;
    const version = get(data, "version", null);
    const modifie = get(data, "modifie", null);

    let stateRow: ColorRow;

    if (columnIndex === 0) {
      if (!isEntityNull && showDirty) {
        if (version === undefined || version === null) {
          stateRow = "new";
        } else if (modifie) {
          stateRow = "modified";
        }
      }
    }

    return stateRow;
  };

  render() {
    const {
      className,
      data,
      columnIndex,
      style,
      rowIndex,
      onRowClick,
      isScrolling,
      isVisible
    } = this.props;

    const Col = this.context[columnIndex] as ColumnProps;

    const classes = classNames("datatable-cell", className, Col.className, {
      "datatable-odd-row": rowIndex % 2 !== 0,
      "datatable-even-row": rowIndex % 2 === 0,
      "datatable-row-first-cell": columnIndex === 0
    });

    if ((this.state && this.state.error !== null) || data === undefined) {
      return <div className={classes} style={style} />;
    }

    if (data === "LOADING_POJO") {
      return (
        <div className={classes} style={style}>
          <div className="datatable-placeholder-cell" style={{ width: Col.width - 10 }} />
        </div>
      );
    }

    const value = get(data, Col.column.column, "");

    const stateRow: ColorRow = this.calculateRowState();
    const styleSelectedRow = this.calculateSelectedRow();

    if (Col.children !== undefined && typeof Col.children === "function") {
      const childrenElements = Col.children({
        data,
        rowIndex,
        columnIndex,
        col: Col,
        colorRow: stateRow,
        className: classes,
        onRowClick,
        style: { ...styleSelectedRow, ...style }
      });
      return (
        <CellMeasurerCacheContext.Consumer>
          {cache => {
            if (cache != null) {
              return (
                <CellMeasurer
                  cache={cache}
                  columnIndex={columnIndex}
                  rowIndex={rowIndex}
                  parent={this.props.parent}
                >
                  {childrenElements}
                </CellMeasurer>
              );
            } else {
              return childrenElements;
            }
          }}
        </CellMeasurerCacheContext.Consumer>
      );
    }

    const componentProperties =
      Col.componentProperties &&
      Col.componentProperties(data, Col, rowIndex, isScrolling || !isVisible);

    const childrenElements = (
      <div
        className={classes}
        style={{ ...styleSelectedRow, ...style }}
        data-state-row={stateRow}
        onClick={() => onRowClick && onRowClick(rowIndex)}
      >
        <Col.component size={this.props.componentSize} value={value} {...componentProperties} />
      </div>
    );
    return (
      <CellMeasurerCacheContext.Consumer>
        {cache => {
          if (cache != null) {
            return (
              <CellMeasurer
                cache={cache}
                columnIndex={columnIndex}
                rowIndex={rowIndex}
                parent={this.props.parent}
              >
                {childrenElements}
              </CellMeasurer>
            );
          } else {
            return childrenElements;
          }
        }}
      </CellMeasurerCacheContext.Consumer>
    );
  }
}
