import React, { FC, useState, useEffect, useLayoutEffect, useRef } from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import Alert from "./Alert";
import { NotificationIntent } from "types/Notification";

interface TransitionSchema {
  states: {
    exited: {};
    entering: {};
    entered: {};
    exiting: {};
  };
}

type TransitionEvent = { type: "enter" } | { type: "exit" } | { type: "next" };

const transititionMachine = Machine<{}, TransitionSchema, TransitionEvent>(
  {
    id: "transition",
    initial: "exited",
    states: {
      exited: {
        on: {
          enter: "entering"
        }
      },
      entering: {
        on: {
          next: "entered",
          exit: "exiting"
        },
        after: {
          transition: { target: "entered" }
        }
      },
      entered: {
        on: {
          exit: "exiting"
        }
      },
      exiting: {
        on: {
          next: "exited"
        },
        after: {
          transition: { target: "exited" }
        }
      }
    }
  },
  {
    delays: {
      transition: 240
    }
  }
);

// type TransitionConfig = {
//   timeout: number;
//   ref: React.Ref<HTMLElement>;
//   initial: TransitionState;
// }

function useTransition(isShown: boolean) {
  const isMounted = useRef(false);
  const [current, send] = useMachine(transititionMachine);

  useEffect(() => {
    if (isShown && current.matches("exited") && !isMounted.current) {
      send({ type: "enter" });
    }

    if (!isShown && (current.matches("entered") || (current as any).matches("entering"))) {
      send({ type: "exit" });
    }

    isMounted.current = true;
  }, [current, current.value, isShown, send]);

  return current.value;
}

const Toast: FC<{
  title: string;
  intent: NotificationIntent;
  duration: number;
  onRemove(): void;
}> = ({ title, intent, duration = 2, onRemove, children }) => {
  const [isShown, setShown] = useState(true);

  const state = useTransition(isShown);

  useEffect(() => {
    if (!isShown && state === "exited") {
      onRemove();
    }
  }, [isShown, onRemove, state]);

  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (ref.current === null) return;

    const { height } = ref.current.getBoundingClientRect();
    setHeight(height);
  }, [ref, state]);

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!localStorage.getItem("debug::notification")) {
      startTimer();
    }
    return () => {
      clearTimer();
    };
  }, []);

  function startTimer() {
    timer.current = setTimeout(() => {
      close();
    }, duration * 1000);
  }

  function clearTimer() {
    timer.current && clearTimeout(timer.current);
  }

  function onMouseEnter() {
    clearTimer();
  }

  function onMouseLeave() {
    startTimer();
  }

  function close() {
    clearTimer();
    setShown(false);
  }

  if (state === "exited") {
    return null;
  }

  return (
    <div
      data-state={state}
      className="toast"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        height: height,
        zIndex: 50,
        marginBottom: isShown ? 0 : -height
      }}
    >
      <div ref={ref} style={{ padding: 8 }}>
        <Alert intent={intent} title={title} onRemove={() => close()}>
          {children}
        </Alert>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  intent: "DEFAULT"
};

export default Toast;
