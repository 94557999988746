import React from "react";
import { ViewDef } from "@fullcalendar/core";
import { SchedulerDefinition } from "../Scheduler";

export function progressTaskTemplate(
  info: {
    isMirror: boolean;
    isStart: boolean;
    isEnd: boolean;
    event: any;
    el: HTMLElement;
    view: ViewDef;
  },
  definition: SchedulerDefinition
): any {
  return (
    <>
      <span className="fc-title-wrap">
        <span className="fc-title fc-sticky">
          <span
            className={`${info.event.extendedProps.classNameName}-darker`}
            style={{
              position: "absolute",
              width: `${info.event.extendedProps.progress}%`,
              height: "100%"
            }}
          ></span>
          {info.event.extendedProps.icon && (
            <span style={{ fontSize: "1em", position: "absolute", left: "1px", top: "-2px" }}>
              <i className={info.event.extendedProps.icon} />
            </span>
          )}
          <span
            style={{ position: "relative", left: "1em" }}
            dangerouslySetInnerHTML={{ __html: info.event.title }}
          />
        </span>
      </span>
      {definition.editTime && <div className="fc-resizer fc-start-resizer"></div>}
      {definition.editTime && <div className="fc-resizer fc-end-resizer"></div>}
    </>
  );
}
