import {
  DISPLAY_EMAIL,
  SEND_EMAIL,
  CLOSE_EMAIL,
  ADD_DESTINATAIRE,
  ON_SUBJECT_CHANGE,
  ON_BODY_CHANGE,
  CLEAR_EMAIL_REDUCER,
  START_EMAIL_LOADER,
  STOP_EMAIL_LOADER,
  ADD_ADDITIONNAL_ATTACHMENT,
  DELETE_ADDTIONNAL_ATTACHMENT,
  DELETE_ATTACHMENT,
  SET_MAIL_INDEX,
  LOAD_DISPLAY_MAIL,
  LOAD_MAIL_TEMPLATE
} from "constant/email";
import { EmailDestinataireState, EmailState } from "types/Processus";
import { ThunkResult } from "store";
import { getMailTemplate } from "api/email";

/**
 * Action d'affichage d'un email
 */
export function displayEmail(tableName: string, id: string) {
  const mailStruct: EmailState = {
    tableName: tableName,
    entityId: id,
    selectedEmailTo: [],
    selectedEmailCc: [],
    selectedEmailBcc: [],
    additionnalAttachment: {},
    sujet: "",
    corps: "",
    piecesJointes: {}
  };
  return {
    type: DISPLAY_EMAIL,
    payload: [mailStruct]
  };
}

export function loadMailTemplate(
  mailTable: string = "",
  mailTableId: string = "",
  mailTemplate: string = ""
): ThunkResult<void> {
  return dispatch => {
    dispatch(startEmailLoader());
    getMailTemplate(mailTable, mailTableId, mailTemplate)
      .then(res => {
        const struct = res.data;
        dispatch({
          type: LOAD_MAIL_TEMPLATE,
          payload: struct
        });
        dispatch(stopEmailLoader());
      })
      .catch(() => {
        dispatch(stopEmailLoader());
      });
  };
}

/**
 * Précharge et affiche l'interface de mail
 */
export function loadAndDisplayEmail(props: {
  sjmoCode: string;
  tableName: string;
  entityId: string;
  defaultEditionProcessus: string;
}) {
  return {
    type: LOAD_DISPLAY_MAIL,
    payload: props
  };
}

/**
 * Action d'envoi de mail
 */
export function sendEmail(updatedBody: string) {
  return {
    type: SEND_EMAIL,
    payload: updatedBody
  };
}

/**
 * Action de fermeture de la fenetre de mail
 */
export function closeEmail() {
  return {
    type: CLOSE_EMAIL
  };
}

/**
 * Ajouter un destinataire dans le reducer
 * @param typeDest
 * @param dest
 */
export function addDestinataire(typeDest: string, dest: EmailDestinataireState[]) {
  return {
    type: ADD_DESTINATAIRE,
    payload: { typeDest, dest }
  };
}

/**
 * Met à jour le sujet dans le reducer lorsque un utiliateur tape un sujet de mail
 * @param value
 */
export function onSubjectChange(value: string) {
  return {
    type: ON_SUBJECT_CHANGE,
    payload: { value }
  };
}

/**
 * Met à jour le body dans le reducer lorsque un utiliateur tape un coprs de mail
 * @param value
 */
export function onBodyChange(value: string) {
  return {
    type: ON_BODY_CHANGE,
    payload: { value }
  };
}

/**
 * Clear le reducer email une fois la fenetre de mail fermée
 */
export function clearEmailReducer() {
  return {
    type: CLEAR_EMAIL_REDUCER
  };
}

/**
 * Démarre un loader sur le mail lors de son envoi
 * afin d'informer l'utilisateur que l'email est en cours d'envoi
 */
export function startEmailLoader() {
  return {
    type: START_EMAIL_LOADER
  };
}

/**
 * Stop le loader sur l'émail après l'envoi
 */
export function stopEmailLoader() {
  return {
    type: STOP_EMAIL_LOADER
  };
}

/**
 * Upload une pice jointe attachée à un mail
 * ne concerne que les pièces jointes ajoutées maunellement par un utilisateur
 * @param response
 */
export function addAdditionnalAttachment(
  response: Record<string, { name: string; fromGED: boolean }>
) {
  return {
    type: ADD_ADDITIONNAL_ATTACHMENT,
    payload: { response }
  };
}

/**
 * Supprime une pièce jointe additionnel (ajouté par l'utilisateur) sur le serveur afin de ne pas emcombrer le serveur
 * de fichier uploader
 */
export function deleteAdditionalAttachment(key: string, fromGED: boolean) {
  return {
    type: DELETE_ADDTIONNAL_ATTACHMENT,
    payload: { key, fromGED }
  };
}

/**
 * Suppression d'une piece jointe dans le reducer uniquement
 * et non sur le serveur comme pour les pieces jointes additionnelles
 * @param key
 */
export function deleteAttachment(key: string) {
  return {
    type: DELETE_ATTACHMENT,
    payload: { key }
  };
}

export function setMailIndex(index: number) {
  return {
    type: SET_MAIL_INDEX,
    payload: { index }
  };
}
