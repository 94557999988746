import React, { FC, useCallback, CSSProperties } from "react";
import { List } from "react-virtualized";
import { MenuListProps } from "react-select";
import { uuidv4 } from "utils/uuid.utils";

const SelectLazyList: FC<MenuListProps> = props => {
  const { maxHeight } = props;

  const rowRenderer = useCallback(
    (params: { index: number; isScrolling: boolean; key: string; style: CSSProperties }) => {
      return (
        <div key={params.key} style={params.style}>
          {props.children && props.children[params.index]}
        </div>
      );
    },
    [props.children]
  );

  // Width est obligatoire et si il y autowith et width présent
  // width est considéré comme un maxWidth ; donc on met un grand nomre dans width
  // Pour que autowidth prennent toujours le relais

  return (
    <List
      rowRenderer={rowRenderer}
      height={maxHeight}
      rowHeight={35}
      rowCount={props.options.length}
      width={9999}
      autoWidth
    />
  );
};

export default SelectLazyList;
