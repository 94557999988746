import { AxiosPromise } from "axios";

import { api, getUIContext } from "./common";
import { Commentaire } from "composants/satellite/TabCommentaire";
import { Document } from "composants/satellite/TabDocument";
import { Mail } from "composants/satellite/MailList";
import { Configuration } from "composants/satellite/TabConfigurateur";
import { Articulation } from "composants/satellite/TabArticulation";
import { Validation } from "composants/satellite/TabValidation";
import { ExtensionsData, ExtensionValue } from "composants/satellite/TabExtension";
import { SatelliteContextLabel } from "containers/satellites/SatellitesData";
import { Message } from "types/Message";
import { Pojo } from "types/Galaxy";
import { PagedResource, FilterBar } from "types/Search";
import { SatelliteAllCount } from "composants/satellite/SatelliteMenu";
import { EtapeValidation } from "composants/satellite/TabValidation";
import { ExtensionType } from "composants/satellite/TabExtension";

/**
 * Factory qui créer une fonction d'appel à l'api des données satellites
 * pour une url en particulier en fonction du path donnée.
 *
 * @param {string} path
 * @returns
 */
function createFetchSatellitesCount(path: string) {
  return (tableName: string, id: string): AxiosPromise<number> => {
    return api.get(`/satellites/${path}?tableName=${tableName}&id=${id}`);
  };
}

/**
 * Factory qui créer une fonction d'appel à l'api des données satellites
 * pour une url en particulier en fonction du path donnée.
 *
 * @param {string} path
 * @returns
 */
function createFetchSatellitesData<T>(path: string) {
  return (tableName: string, id: string): AxiosPromise<T[]> => {
    return api.get(`/satellites/${path}?tableName=${tableName}&id=${id}`);
  };
}

export const getExtensionCount = createFetchSatellitesCount("extension/count");
export const getCommentaireCount = createFetchSatellitesCount("commentaire/count");
export const getDocumentCount = createFetchSatellitesCount("document/count");
export const getMessageCount = createFetchSatellitesCount("message/count");
export const getConfigurateurCount = createFetchSatellitesCount("configurateur/count");
export const getArticulationCount = createFetchSatellitesCount("articulation/count");
export const getValidationCount = createFetchSatellitesCount("validation/count");
export const getMailCount = createFetchSatellitesCount("mail/count");

export const fetchExtensionData = createFetchSatellitesData<ExtensionsData>("extension/data");
export const fetchCommentaireData = createFetchSatellitesData<Commentaire>("commentaire/data");
export const fetchDocumentData = createFetchSatellitesData<Document>("document/data");
export const fetchMessageData = createFetchSatellitesData<Mail>("message/data");
export const fetchConfigurateurData = createFetchSatellitesData<Configuration>(
  "configurateur/data"
);
export const fetchArticulationData = createFetchSatellitesData<Articulation>("articulation/data");
export const fetchValidationData = createFetchSatellitesData<Validation>("validation/data");
export const fetchMailData = createFetchSatellitesData<Mail>("mail/data");

export function getAllCount(tableName: string, id: string): AxiosPromise<SatelliteAllCount> {
  return api.get(`/satellites/all/count?tableName=${tableName}&id=${id}`);
}

export function fetchMessageThread(tableName: string, rootId: number): AxiosPromise<Mail[]> {
  return api.get(`/satellites/message/conversation?tableName=${tableName}&rootId=${rootId}`);
}

export function fetchContextLabel(
  tableName: string,
  id: string
): AxiosPromise<SatelliteContextLabel> {
  return api.get(`/satellites/contextLabel?tableName=${tableName}&id=${id}`);
}

export function deleteDocument(id: string): AxiosPromise<Message> {
  return api.delete(`document/${id}`);
}

export function fetchDefaultComment(
  sjmoCode: string,
  tableName: string,
  id: string
): AxiosPromise<Commentaire> {
  const params = getUIContext({ sjmoCode });
  params.append("tableName", tableName);
  params.append("id", id);
  return api.get(`/commentaire/default?${params}`);
}

export function fetchOneCommentaireText(id: string): AxiosPromise<string> {
  return api.get(`/commentaire/text/${id}`);
}

export function saveComment(p: {
  sjmoCode: string;
  tableName: string;
  id: string;
  type: string;
  langue: string;
  date: Date | null;
  commentaireId: string | null;
  texte: string;
}): AxiosPromise<Commentaire> {
  const { sjmoCode, tableName, id, type, langue, commentaireId, texte } = p;
  const body = { type, langue, id: commentaireId, texte };
  const params = getUIContext({ sjmoCode });
  params.append("tableName", tableName);
  params.append("id", id);
  return api.post(`/commentaire?${params}`, body);
}

export function deleteCommentaire(sjmoCode: string, id: string) {
  const params = getUIContext({ sjmoCode });
  return api.delete(`/commentaire/${id}?${params}`);
}

export function fecthFilteredComments(
  sjmoCode: string,
  searchQuery: string,
  first: number,
  size: number,
  filter: string,
  filterBar?: FilterBar
): AxiosPromise<PagedResource<Pojo>> {
  const params = getUIContext({ sjmoCode });
  params.append("first", first.toString());
  params.append("size", size.toString());
  params.append("searchQuery", searchQuery);
  params.append("includeJoinParent", "true");

  filterBar?.filterBarId && params.append("filterBarId", filterBar.filterBarId);
  filterBar?.startDate && params.append("filterBarStart", filterBar.startDate);
  filterBar?.endDate && params.append("filterBarEnd", filterBar.endDate);
  filterBar?.filterBarDefaultDtFilter &&
    params.append("filterBarDefaultDtFilter", filterBar.filterBarDefaultDtFilter);

  return api.get(`/commentaire/search?${params}&${filter}`);
}

export function saveExtensions(
  sjmoCode: string,
  tableName: string,
  id: string,
  metaData: { [key: string]: ExtensionValue }
): AxiosPromise<ExtensionValue> {
  const params = getUIContext({ sjmoCode });
  return api.post(`/satellites/extension?${params}&tableName=${tableName}&id=${id}`, metaData);
}

export function fetchDefaultMessage(
  sjmoCode: string,
  tableName: string,
  id: string
): AxiosPromise<Mail> {
  const params = getUIContext({ sjmoCode });

  return api.get(`/satellite-message/default?${params}&tableName=${tableName}&id=${id}`);
}

export function saveMessage(
  sjmoCode: string,
  tableName: string,
  contextId: string,
  data: {
    dest: string;
    src: string;
    date: Date;
    destinataireType: string;
    parentId: string;
    object: string | undefined;
    texte: string;
  }
): AxiosPromise<ExtensionValue> {
  const params = getUIContext({ sjmoCode });
  params.append("tableName", tableName);
  params.append("id", contextId);
  return api.post(`/satellite-message?${params}`, data);
}

export function deleteMessage(sjmoCode: string, id: string) {
  const params = getUIContext({ sjmoCode });
  return api.delete(`/satellite-message/${id}?${params}`);
}

export function createNewExtension(p: {
  sjmoCode: string;
  tableName: string;
  contextId: string;
  fromExisting: boolean;
  data: {
    extensionId?: string;
    exteCode?: string;
    exteLibelle?: string;
    exteTypeDonnee?: ExtensionType;
    daexValeurA?: string;
    daexValeurN?: number;
    daexValeurD?: Date;
  };
}) {
  const { sjmoCode, tableName, contextId, fromExisting, data } = p;
  const params = getUIContext({ sjmoCode });
  params.append("tableName", tableName);
  params.append("id", contextId);
  params.append("fromExisting", `${fromExisting}`);
  return api.post(`/satellite-extension?${params}`, data);
}

export function existList(p: {
  tableName: string;
  ids: string[];
}): AxiosPromise<Record<string, boolean>> {
  const params = getUIContext();
  params.append("tableName", p.tableName);
  params.append("ids", p.ids.join(","));
  return api.get(`/satellites/exists?${params}`);
}

export function validateStep(p: {
  sjmoCode: string;
  step: EtapeValidation;
}): AxiosPromise<EtapeValidation> {
  const { sjmoCode, step } = p;
  const urlParams = getUIContext({ sjmoCode });
  return api.post("validation/accepter?" + urlParams, step);
}

export function modifyStep(p: {
  sjmoCode: string;
  step: EtapeValidation;
}): AxiosPromise<EtapeValidation> {
  const { sjmoCode, step } = p;
  const urlParams = getUIContext({ sjmoCode });
  return api.post("validation/modifier?" + urlParams, step);
}

export function modifyRStep(p: {
  sjmoCode: string;
  step: EtapeValidation;
}): AxiosPromise<EtapeValidation> {
  const { sjmoCode, step } = p;
  const urlParams = getUIContext({ sjmoCode });
  return api.post("validation/modifierr?" + urlParams, step);
}

export function refuseStep(p: {
  sjmoCode: string;
  step: EtapeValidation;
}): AxiosPromise<EtapeValidation> {
  const { sjmoCode, step } = p;
  const urlParams = getUIContext({ sjmoCode });
  return api.post("validation/refuser?" + urlParams, step);
}
