import React, {
  Component,
  SyntheticEvent,
  FC,
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import GroupComponent from "../group/GroupComponent";
import { Button } from "../button";
import { ComponentState, Item } from "types/Component";
import { convertValue, deleteManyEntity } from "utils/entities.utils";
import { fetchExtensionData, saveExtensions, createNewExtension, findAll } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import TabHeader from "./TabHeader";
import AutoCompleteAndLabel from "../autocomplete/AutoCompleteAndLabel";
import { Pojo } from "types/Galaxy";
import { getColumnDefinition } from "api/column";
import { Row, Col } from "composants/Layout";
import SysDomaineAndLabel from "composants/select/SysDomaineAndLabel";
import { Fa } from "composants/Icon";
import { Trans } from "react-i18next";
import Calendar from "composants/calendar/Calendar";
import Input from "composants/input/Input";
import { Switch, Tooltip } from "@axin-org/comet";
import { tw } from "twind";
import Select from "composants/select/Select";
import { GSBuilder, initRsqlCriteria } from "utils/query.utils";

export type ExtensionType = "A" | "N" | "D" | "C" | "S";

export interface ExtensionValue {
  id: number;
  columnName: string;
  dataType: string;
  value: any;
  unite: string;
  style: string;
}

export interface ExtensionsData {
  values: ExtensionValue[];
  composants: ComponentState[];
}

interface TabExtensionState {
  // Definition des composants à afficher
  definition: ComponentState[];
  extensionGSDefinition?: ComponentState;
  extensions: ExtensionValue[];
  isCreatingFromExisting: boolean;
  isDeleting: boolean;
}

const CreateFromExistingBody: FC<{
  extensionGSDefinition?: ComponentState;
  isCreatingFromExisting: boolean;
  sjmoCode: string;
  tableName: string;
  contextId?: string;
  refresh: () => void;
}> = props => {
  const [selectedExtension, setSelectedExtension] = useState<Pojo | null>(null);
  const [valueA, setValueA] = useState<string | undefined>(undefined);
  const [valueN, setValueN] = useState<number | undefined>(undefined);
  const [valueD, setValueD] = useState<Date | undefined>(undefined);
  const [domaineValues, setDomaineValues] = useState<Item[]>([]);

  const typeCompo = useMemo(() => selectedExtension?.exteTypeDonnee as ExtensionType | undefined, [
    selectedExtension?.exteTypeDonnee
  ]);

  const joinTableName = props.extensionGSDefinition
    ? props.extensionGSDefinition.joinTableName
    : undefined;

  const joinListFields = props.extensionGSDefinition
    ? props.extensionGSDefinition.joinListFields
    : undefined;

  const label = props.extensionGSDefinition ? props.extensionGSDefinition.label : "";

  const additionalClause = props.extensionGSDefinition
    ? props.extensionGSDefinition.additionalClause
    : undefined;

  useEffect(() => {
    if (
      selectedExtension === null ||
      selectedExtension.extensionDomaineId === null ||
      selectedExtension.extensionDomaineId === undefined
    ) {
      setDomaineValues([]);
      return;
    }

    const rsql = initRsqlCriteria();
    rsql.filters.and(
      GSBuilder.toFilter(
        GSBuilder.Comparison(
          "extensionDomaineId.id",
          "OPER_EQ",
          selectedExtension.extensionDomaineId
        )
      )
    );

    findAll({
      tableName: "extensionListeDomaine",
      filter: rsql.build(),
      size: false
    })
      .then(res => {
        setDomaineValues(
          res.data.data.map(val => ({ label: val.exldValeurA, value: val.exldValeurA }))
        );
      })
      .catch(() => {
        setDomaineValues([]);
      });
  }, [selectedExtension]);

  function onChange(e: React.SyntheticEvent<any>) {
    const type = e.currentTarget?.dataset?.type as ExtensionType | undefined;
    const newValue = convertValue(e);

    switch (type) {
      case "A":
        setValueA(newValue);
        break;
      case "N":
        setValueN(newValue);
        break;
      case "D":
        setValueD(newValue);
        break;
      case "C":
        setValueA(newValue === true ? "O" : "N");
        break;
      case "S":
        setValueA(newValue);
        break;
    }
  }

  return (
    <Row>
      <Col span={6}>
        <AutoCompleteAndLabel
          id="extensionId"
          value={selectedExtension?.id ?? null}
          onItemChange={(pojo: Pojo | null) => {
            setSelectedExtension(pojo);
            setValueA(undefined);
            setValueD(undefined);
            setValueN(undefined);
          }}
          sjmoCode={props.sjmoCode}
          joinTableName={joinTableName}
          joinListFields={joinListFields}
          label={label}
          additionalClause={additionalClause}
          controlProps={{ expanded: true }}
        />
      </Col>
      <Col span={3} style={{ display: "flex", alignItems: "center" }}>
        {typeCompo === "A" && (
          <Tooltip label={selectedExtension?.exteHint ?? ""}>
            <Input id="extensionValueA" value={valueA} data-type={typeCompo} onChange={onChange} />
          </Tooltip>
        )}
        {typeCompo === "N" && (
          <Tooltip label={selectedExtension?.exteHint ?? ""}>
            <Input
              id="extensionValueN"
              value={valueN}
              type="number"
              data-type={typeCompo}
              onChange={onChange}
            />
          </Tooltip>
        )}
        {typeCompo === "D" && (
          <Tooltip label={selectedExtension?.exteHint ?? ""}>
            <Calendar
              id="extensionValueD"
              value={valueD}
              data-type={typeCompo}
              onChange={onChange}
            />
          </Tooltip>
        )}
        {typeCompo === "C" && (
          <label style={{ display: "flex", alignItems: "center" }}>
            <span>
              <Switch
                checked={valueA === "O" ? true : valueA === "N" ? false : undefined}
                data-type={typeCompo}
                fontSize="large"
                {...({ intent: "primary" } as any)}
                onChange={onChange}
              />
            </span>
            <span className={tw`ml-2`}>
              {valueA === "O" ? t("commun_checkbox_unchecked") : t("commun_checkbox_checked")}
            </span>
          </label>
        )}
        {typeCompo === "S" && (
          <Tooltip label={selectedExtension?.exteHint ?? ""}>
            <Select
              id={`extension-compo-sd-${selectedExtension?.id}`}
              sysDomaineChoices={domaineValues}
              value={valueA}
              data-type={typeCompo}
              onChange={onChange}
            />
          </Tooltip>
        )}
        {typeCompo === undefined && (
          <Tooltip label={selectedExtension?.exteHint ?? ""}>
            <Input id="extensionValueA" value={""} disabled />
          </Tooltip>
        )}
      </Col>
      <Col span={3}>
        <Button
          onClick={() => {
            createNewExtension({
              sjmoCode: props.sjmoCode,
              tableName: props.tableName,
              contextId: props.contextId as string,
              fromExisting: true,
              data: {
                extensionId: selectedExtension?.id,
                daexValeurA: valueA,
                daexValeurN: valueN,
                daexValeurD: valueD
              }
            }).then(props.refresh);
          }}
          disabled={!props.isCreatingFromExisting || selectedExtension === undefined}
        >
          <span className="icon">
            <Fa icon="save" />
          </span>
          <span>
            <Trans i18nKey="commun_creer_new_extension" />
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const DeleteBody: FC<{
  sjmoCode: string;
  extensions: ExtensionValue[];
  isDeleting: boolean;
  refresh: () => void;
  addMessage(message: Message): void;
}> = props => {
  const [selectedExtension, setSelectedExtension] = useState<string | null>(null);

  const sysDomaineChoices: Item[] = useMemo(() => {
    return props.extensions.map(ext => {
      return { label: ext.columnName, value: ext.id };
    });
  }, [props.extensions]);

  return (
    <Row>
      <Col span={9}>
        <SysDomaineAndLabel
          id="extensionId"
          value={selectedExtension}
          onChange={e => setSelectedExtension(convertValue(e))}
          sysDomaineChoices={sysDomaineChoices}
          label={t("commun_extensions")}
        />
      </Col>
      <Col span={3}>
        <Button
          onClick={() => {
            deleteManyEntity(
              "dataExtension",
              [selectedExtension as string],
              props.sjmoCode,
              () => props.refresh(),
              error => props.addMessage
            );
          }}
          disabled={!props.isDeleting || selectedExtension === undefined}
        >
          <span className="icon">
            <Fa icon="save" />
          </span>
          <span>
            <Trans i18nKey="commun_supprimer_extension" />
          </span>
        </Button>
      </Col>
    </Row>
  );
};

class TabExtension extends Component<TabSatelliteProps, TabExtensionState> {
  state: TabExtensionState = {
    extensions: [],
    isCreatingFromExisting: false,
    isDeleting: false,
    definition: []
  };

  componentDidMount() {
    if (this.props.contextId) {
      this.refresh();
    }
    getColumnDefinition(this.props.sjmoCode, "dataExtension", ["extensionId"]).then(response => {
      this.setState({
        extensionGSDefinition: response.data[0] ? response.data[0] : undefined
      });
    });
  }

  componentDidUpdate(prevProps: TabSatelliteProps) {
    if (prevProps.contextId !== this.props.contextId && this.props.contextId) {
      this.refresh();
    }
  }

  refresh = () => {
    fetchExtensionData(this.props.tableName, this.props.contextId as string)
      .then(response => {
        let metaData = {};
        response.data[0].values.forEach((v: ExtensionValue) => {
          metaData[v.columnName] = v;
        });

        this.setState({
          definition: response.data[0].composants,
          extensions: response.data[0].values
        });
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    this.props.countAction(this.props.tableName, this.props.contextId);
    this.setState({
      isCreatingFromExisting: false,
      isDeleting: false
    });
  };

  render() {
    let extensionValues: any = {};
    let _style = {};
    this.state.extensions.forEach((v: ExtensionValue) => {
      extensionValues[v.columnName] = v.value;
      if (v.style) {
        _style[v.columnName] = v.style;
      }
    });
    extensionValues._style = _style;

    const newBody = this.state.isCreatingFromExisting ? (
      <CreateFromExistingBody
        extensionGSDefinition={this.state.extensionGSDefinition}
        isCreatingFromExisting={this.state.isCreatingFromExisting}
        sjmoCode={this.props.sjmoCode}
        tableName={this.props.tableName}
        contextId={this.props.contextId}
        refresh={this.refresh}
      />
    ) : null;
    const deleteBody = this.state.isDeleting ? (
      <DeleteBody
        sjmoCode={this.props.sjmoCode}
        extensions={this.state.extensions}
        refresh={this.refresh}
        isDeleting={this.state.isDeleting}
        addMessage={this.props.addMessage}
      />
    ) : null;

    return (
      <>
        <TabHeader
          i18nKey="commun_extensions_liees"
          tableName={this.props.tableName}
          contextId={this.props.contextId}
          count={this.props.count}
          sjmoCode={this.props.sjmoCode}
        >
          <Button
            className="button is-success is-rounded mr-6"
            onClick={() => this.save()}
            disabled={this.props.contextId === undefined}
            title={t("commun_valider")}
          >
            <span className="icon">
              <Fa icon="save" className="mr-7" />
            </span>
            <span>
              <Trans i18nKey="commun_valider" />
            </span>
          </Button>

          <Button
            className="button is-link is-rounded mr-6"
            onClick={() =>
              this.setState({ isCreatingFromExisting: !this.state.isCreatingFromExisting })
            }
            disabled={this.props.contextId === undefined}
          >
            <span className="icon">
              <Fa icon="plus" />
            </span>
            <span>
              <Trans i18nKey="commun_ajouter" />
            </span>
          </Button>
          <Button
            className="button is-link is-rounded mr-6"
            onClick={() => this.setState({ isDeleting: !this.state.isDeleting })}
            disabled={this.props.contextId === undefined}
          >
            <span className="icon">
              <Fa icon="trash" />
            </span>
            <span>
              <Trans i18nKey="commun_supprimer" />
            </span>
          </Button>
        </TabHeader>
        {newBody}
        {deleteBody}
        <>
          <GroupComponent
            group={{
              groupLabel: "",
              groupSize: 12,
              compos: this.state.definition,
              position: 0,
              datatableTableName: "",
              datatableCtrlKey: ""
            }}
            groupSize={12}
            entity={extensionValues}
            wviState={{}}
            onChange={this.onChange}
            onValueChange={this.onValueChange}
            onBlur={this.onBlur}
            contextMenu={() => {
              /* rien */
            }}
            sjmoCode={this.props.sjmoCode}
          />
        </>
      </>
    );
  }

  onChange = (e: SyntheticEvent<any>) => {
    const field: string = e.currentTarget.name;
    const value = convertValue(e);

    let extensions = [...this.state.extensions];
    extensions = extensions.map(ext => {
      let newValue = value;
      if (ext.dataType === "C") {
        newValue = value === true ? "O" : "N";
      }

      if (ext.columnName === field) {
        return { ...ext, value: newValue };
      } else {
        return ext;
      }
    });

    this.setState({
      extensions
    });
  };

  onValueChange = (field: string | undefined, value: any) => {
    let extensions = [...this.state.extensions];
    extensions = extensions.map(ext => {
      let newValue = value;
      if (ext.dataType === "C") {
        newValue = value === true ? "O" : "N";
      }

      if (ext.columnName === field) {
        return { ...ext, value: newValue };
      } else {
        return ext;
      }
    });

    this.setState({
      extensions
    });
  };

  onBlur = (e: SyntheticEvent<any>) => {
    // pas de WVI sur les extensions
  };

  save = () => {
    let metaData = {};
    this.state.extensions.forEach((v: ExtensionValue) => {
      metaData[v.columnName] = v;
    });

    saveExtensions(
      this.props.sjmoCode,
      this.props.tableName,
      this.props.contextId as string,
      metaData
    )
      .then(response => {
        this.setState({
          definition: response.data[0].composants,
          extensions: response.data[0].values
        });

        this.props.countAction(this.props.tableName, this.props.contextId as string);
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };
}

export default TabExtension;
