import React, { SFC } from "react";
import classNames from "classnames";

import "./SmallButton.css";
import { Fa } from "composants/Icon";

export const SmallButton: SFC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...rest
}) => {
  return (
    <button className={classNames("small-button", className)} {...rest}>
      <Fa icon="angle-down" aria-label="open" />
    </button>
  );
};
