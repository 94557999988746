import React, { FC, useState, useMemo } from "react";

import classNames from "classnames";

import { AffectationType } from "../../enum";
import { getSizeClasses } from "composants/common";
import { FocusState } from "types/Focus";
import { t } from "utils/i18n";

import { Menu } from "composants/DropDown/Menu";
import { Fa } from "composants/Icon";
import DeleteButton from "composants/button/DeleteButton";
import { track } from "tracking";

interface FocusMenuProps {
  // la liste des faocus indépendemment du context du composant
  focuses: FocusState[];
  // le focus courant
  currentFocus: string;
  width?: string;
  className?: string;
  // Indique si on affiche les options pour la personnalisation (save et raz perso)
  personnalisable: boolean;
  // La taille du menu (small, medium, large) sont les seules valeurs attendues
  size?: string;
  disabled?: boolean;
  isAdmin?: boolean;
  //functions
  onFocusChange?(focusId: string): void;
  onSaveFocusPerso?(): void;
  onRazFocusPerso?(): void;
  onRazFocus?(focusId: string): void;
}

type FocusMenuAllProps = FocusMenuProps;

function getLabelFocus(type: string) {
  let label: string | null;
  switch (type) {
    case AffectationType.GLOBAL:
      label = t("commun_global");
      break;

    case AffectationType.ROLE:
      label = t("commun_role");
      break;

    case AffectationType.MODULE:
      label = t("commun_module");
      break;

    case AffectationType.USER:
      label = t("commun_utilisateur");
      break;

    case AffectationType.NON_ASSIGNE:
      label = t("commun_non_assigne");
      break;

    default:
      label = null;
  }

  return label;
}

/**
 * Composant de gestion du menu des focus
 * Ce composant permet de gérer la liste de focus de :
 * - galaxies
 * - mini-expert
 * - creator
 * - expert
 *
 * de façon générique
 */
const FocusMenu: FC<FocusMenuAllProps> = props => {
  function saveFocusPerso() {
    track("focus::save::perso");
    if (props.onSaveFocusPerso !== undefined) {
      props.onSaveFocusPerso();
    }
  }

  function razFocusPerso() {
    track("focus::raz::perso");
    if (props.onRazFocusPerso !== undefined) {
      props.onRazFocusPerso();
    }
  }

  const { focuses, currentFocus, personnalisable } = props;
  let label: string = "";
  let isPerso = "";
  if (!focuses || !currentFocus) {
    return null;
  }

  const element = focuses.find(({ focusId }) => focusId === currentFocus);
  if (element) {
    label = element.focusLabel;
    isPerso = element.perso ? "dropdown-item" : "dropdown-item disabled-link";
  }

  const buttonClasses = classNames("button", getSizeClasses(props.size || ""));

  return (
    <Menu autoclose>
      <Menu.Button
        className={buttonClasses}
        aria-haspopup="true"
        aria-controls="dropdown-menu4"
        disabled={props.disabled}
      >
        <span className="icon">
          <Fa icon="eye" fixedWidth />
        </span>
        <span>{label}</span>
        <span className="icon">
          <Fa icon="angle-down" />
        </span>
      </Menu.Button>
      <Menu.Overlay>
        <FocusMenuItem
          focuses={props.focuses}
          onFocusChange={props.onFocusChange}
          type={AffectationType.GLOBAL}
          selectedFocus={props.currentFocus}
        />
        <FocusMenuItem
          focuses={props.focuses}
          onFocusChange={props.onFocusChange}
          type={AffectationType.MODULE}
          selectedFocus={props.currentFocus}
        />
        <FocusMenuItem
          focuses={props.focuses}
          onFocusChange={props.onFocusChange}
          type={AffectationType.ROLE}
          selectedFocus={props.currentFocus}
        />
        <FocusMenuItem
          focuses={props.focuses}
          onFocusChange={props.onFocusChange}
          type={AffectationType.USER}
          selectedFocus={props.currentFocus}
        />
        <FocusMenuItem
          focuses={props.focuses}
          onFocusChange={props.onFocusChange}
          type={AffectationType.NON_ASSIGNE}
          selectedFocus={props.currentFocus}
        />
        {personnalisable && (
          <>
            <Menu.Separator />
            <Menu.Item as="a" className="dropdown-item" onClick={saveFocusPerso} autoClose>
              {t("commun_sauvegarder")}
            </Menu.Item>
            <Menu.Item as="a" className={isPerso} onClick={razFocusPerso}>
              {t("commun_supprimer_focus_perso")}
            </Menu.Item>
          </>
        )}
      </Menu.Overlay>
    </Menu>
  );
};

FocusMenu.defaultProps = {
  width: "100%",
  disabled: false
};

const FocusMenuItem: FC<{
  focuses: FocusState[];
  type: string;
  onFocusChange?(focusId: string): void;
  selectedFocus?: string;
}> = ({ selectedFocus, focuses, type, onFocusChange }) => {
  const group = useMemo<FocusState[]>(() => {
    const group = focuses.filter(focus => focus.focusNature === type);
    group.sort((a, b) => a.position - b.position);
    return group;
  }, [focuses, type]);

  if (group.length <= 0) {
    return null;
  }

  let label = getLabelFocus(type);

  // si l'affectation n'existe pas, on ne render rien
  if (label == null) {
    return null;
  }

  return (
    <>
      <Menu.Item as="strong">{label}</Menu.Item>
      {group.map(focus => (
        <Menu.Item
          as="a"
          className={focus.focusId === selectedFocus ? "is-active" : undefined}
          aria-selected={focus.focusId === selectedFocus}
          key={focus.focusId}
          onClick={() => {
            onFocusChange && onFocusChange(focus.focusId);
            track("focus::changed", { label: focus.focusLabel, id: focus.focusId });
          }}
          autoClose
        >
          {focus.focusLabel}
        </Menu.Item>
      ))}
    </>
  );
};

export default FocusMenu;
