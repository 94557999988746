import React from "react";
import { ViewDef } from "@fullcalendar/core";
import { SchedulerDefinition } from "../Scheduler";

export function simpleTaskTemplate(
  info: {
    isMirror: boolean;
    isStart: boolean;
    isEnd: boolean;
    event: any;
    el: HTMLElement;
    view: ViewDef;
  },
  definition: SchedulerDefinition
): any {
  return (
    <span className="fc-title-wrap">
      <span className="fc-title fc-sticky">
        {info.event.extendedProps.icon && (
          <span style={{ fontSize: "1em", position: "absolute", left: "1px", top: "-2px" }}>
            <i className={info.event.extendedProps.icon} />
          </span>
        )}
        <span
          style={{
            position: "relative",
            left: info.event.extendedProps.icon ? "1em" : undefined,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            display: "block"
          }}
          dangerouslySetInnerHTML={{ __html: info.event.title }}
        />
      </span>
    </span>
  );
}
