import React, { SFC, FC } from "react";
import classNames from "classnames";

import { DatatableFocusState } from "types/Focus";
import FocusMenu from "../focus/FocusMenu";
import { Button } from "../button";
import ProcessusMenu from "../processus/ProcessusMenu";
import { GridPaddingType } from "./table/datatableBehavior";
import { Field, Control } from "../form";
import { Dropdown, DropdownButton, DropdownMenu } from "../DropDown/Dropdown";
import DropdownLov from "../DropDown/DropdownLov";
import { ButtonWithLoader } from "composants/button/ButtonWithLoader";
import { Fa } from "composants/Icon";
import { Menu } from "composants/DropDown/Menu";
import { Trans } from "react-i18next";

export interface ToolbarButtonOverride {
  add: boolean;
  save: boolean;
  delete: boolean;
  refresh: boolean;
  processus: boolean;
  satellite: boolean;
  focus: boolean;
  exportExcel: boolean;
  exportPdf: boolean;
}

interface ToolbarProps {
  sjmoCode: string;
  tableName: string;
  tableCtrlKey: string;
}

const defaultFn = () => {
  /*nothing*/
};

const ToolbarContext = React.createContext<ToolbarProps>({} as any);

type ToolbarAddProps = { isRounded?: boolean; selectedFocus?: string; onAdd?(): void };
const ToolbarAdd: SFC<ToolbarAddProps> = ({
  isRounded = false,
  selectedFocus,
  onAdd = defaultFn
}) => {
  return (
    <ToolbarContext.Consumer>
      {({ sjmoCode, tableName, tableCtrlKey }) => {
        const classRounded = isRounded ? "is-rounded" : undefined;

        return (
          <div className="level-item">
            <Field addons>
              <Control>
                <button
                  onClick={onAdd}
                  className={classNames("button is-link is-inverted", classRounded)}
                  title="ajouter une ligne"
                >
                  <Fa icon="plus" />
                </button>
              </Control>
              {selectedFocus ? (
                <Control>
                  <DropdownLov
                    buttonClassName={classNames("is-link is-inverted", classRounded)}
                    sjmoCode={sjmoCode}
                    datatableId={selectedFocus}
                    datatableName={tableName}
                    datatableCtrlKey={tableCtrlKey}
                  />
                </Control>
              ) : null}
            </Field>
          </div>
        );
      }}
    </ToolbarContext.Consumer>
  );
};

type ToolbarSaveProps = {
  title?: string;
  isRounded?: boolean;
  highlightSave?: boolean;
  onSave?(): Promise<any>;
  disabled?: boolean;
};
const ToolbarSave: SFC<ToolbarSaveProps> = ({
  isRounded = false,
  highlightSave = false,
  onSave,
  title,
  disabled,
  children
}) => {
  const classRounded = isRounded ? "is-rounded" : undefined;

  return (
    <Control>
      <ButtonWithLoader
        className={classNames(
          "is-inverted",
          { "is-link": !highlightSave, "is-danger": highlightSave },
          classRounded
        )}
        title={title}
        onPress={onSave}
        disabled={disabled}
      >
        {children}
      </ButtonWithLoader>
    </Control>
  );
};

type ToolbarRefreshProps = { isRounded?: boolean; onRefresh(): void };
const ToolbarRefresh: FC<ToolbarRefreshProps> = ({ isRounded = false, onRefresh }) => {
  const classRounded = isRounded ? "is-rounded" : undefined;

  function onClick() {
    onRefresh();
  }

  return (
    <Control>
      <Button
        className={classNames("is-link is-inverted", classRounded)}
        title="Rafraîchir"
        onClick={onRefresh}
      >
        <span className="icon">
          <Fa icon="sync-alt" />
        </span>
      </Button>
    </Control>
  );
};

type ToolbarDeleteProps = {
  highlightSave?: boolean;
  isRounded?: boolean;
  disabled?: boolean;
  onDelete?(): Promise<any>;
};

const ToolbarDelete: SFC<ToolbarDeleteProps> = ({
  isRounded = false,
  highlightSave = false,
  disabled = false,
  onDelete
}) => {
  const classRounded = isRounded ? "is-rounded" : undefined;

  return (
    <Control>
      <ButtonWithLoader
        className={classNames(
          "is-inverted",
          { "is-link": !highlightSave, "is-danger": highlightSave },
          classRounded
        )}
        title="Supprimer"
        icon="trash"
        onPress={onDelete}
        disabled={disabled}
      />
    </Control>
  );
};

type ToolbarProcessMenuProps = {
  isRounded?: boolean;
  disabled?: boolean;
};

const ToolbarProcessMenu: SFC<ToolbarProcessMenuProps> = ({ isRounded = false, disabled }) => {
  const classRounded = isRounded ? "is-rounded" : undefined;

  return (
    <Control>
      <ProcessusMenu
        buttonClassName={classNames("is-link is-inverted", classRounded)}
        icon="cogs"
        disabled={disabled}
      />
    </Control>
  );
};

type ToolbarSatelliteProps = {
  isRounded?: boolean;
  renderSatelliteMenu?(
    isActive: boolean,
    onClose: () => void,
    id?: string
  ): React.ReactElement | null;
};
const ToolbarSatellite: SFC<ToolbarSatelliteProps> = ({
  isRounded = false,
  renderSatelliteMenu
}) => {
  return renderSatelliteMenu ? (
    <Menu autoclose>
      <Menu.Button className={classNames("button is-link is-inverted", isRounded && "is-rounded")}>
        <span className="icon">
          <Fa icon={["fas", "satellite"]} />
        </span>
      </Menu.Button>
      <Menu.Overlay>
        <Menu.WithContext>
          {({ isActive, setIsActive }) => {
            return renderSatelliteMenu(isActive, () => setIsActive(false));
          }}
        </Menu.WithContext>
      </Menu.Overlay>
    </Menu>
  ) : null;
};

// right side
type ToolbarFocusMenuProps = {
  focus?: DatatableFocusState[];
  selectedFocus?: string;
  onFocusChange?(selected: string): void;
  onSaveFocusPerso?(): void;
  onRazFocusPerso?(): void;
};
const ToolbarFocusMenu: SFC<ToolbarFocusMenuProps> = ({
  selectedFocus,
  focus = [],
  onFocusChange,
  onSaveFocusPerso,
  onRazFocusPerso
}) => {
  return focus.length > 0 && selectedFocus !== null ? (
    <div className="level-item">
      <FocusMenu
        focuses={focus}
        currentFocus={selectedFocus || ""}
        onFocusChange={onFocusChange}
        onSaveFocusPerso={onSaveFocusPerso}
        onRazFocusPerso={onRazFocusPerso}
        personnalisable
      />
    </div>
  ) : null;
};

type ToolbarExportExcelProps = {
  onExportExcel?(): void;
};
const ToolbarExportExcel: SFC<ToolbarExportExcelProps> = ({ onExportExcel }) => {
  return (
    <ToolbarContext.Consumer>
      {props => {
        return (
          <div className="level-item">
            <a onClick={onExportExcel}>
              <span className="icon">
                <Fa icon="file-excel" />
              </span>
            </a>
          </div>
        );
      }}
    </ToolbarContext.Consumer>
  );
};

type ToolbarExportPdfProps = { onExportPdf?(): void };
const ToolbarExportPdf: SFC<ToolbarExportPdfProps> = ({ onExportPdf }) => {
  return (
    <ToolbarContext.Consumer>
      {props => {
        return (
          <div className="level-item">
            <a onClick={onExportPdf}>
              <span className="icon">
                <Fa icon="file-pdf" />
              </span>
            </a>
          </div>
        );
      }}
    </ToolbarContext.Consumer>
  );
};

type ToolbarActionProps = {
  addButton?: boolean;
  deleteButton?: boolean;
  saveButton?: boolean;
  refreshButton?: boolean;
  processusButton?: boolean;
  satelliteButton?: boolean;
} & ToolbarAddProps &
  ToolbarDeleteProps &
  ToolbarSaveProps &
  ToolbarRefreshProps &
  ToolbarProcessMenuProps &
  ToolbarSatelliteProps;

const ToolbarAction: SFC<ToolbarActionProps> = ({
  addButton,
  deleteButton,
  saveButton,
  refreshButton,
  processusButton,
  satelliteButton,
  // childrenProps
  selectedFocus,
  highlightSave,
  onAdd,
  onSave,
  onRefresh,
  onDelete,
  renderSatelliteMenu
}) => {
  let nbActive = 0;

  addButton && nbActive++;
  deleteButton && nbActive++;
  saveButton && nbActive++;
  refreshButton && nbActive++;
  processusButton && nbActive++;
  satelliteButton && nbActive++;

  let isOnlyOneButtonAction = nbActive === 1;

  return (
    <>
      {addButton && <ToolbarAdd selectedFocus={selectedFocus} onAdd={onAdd} />}
      <div className="level-item">
        <Field addons={!isOnlyOneButtonAction}>
          {saveButton && (
            <ToolbarSave
              highlightSave={highlightSave}
              onSave={onSave}
              isRounded={isOnlyOneButtonAction}
            />
          )}
          {refreshButton && (
            <ToolbarRefresh onRefresh={onRefresh} isRounded={isOnlyOneButtonAction} />
          )}
          {deleteButton && <ToolbarDelete onDelete={onDelete} isRounded={isOnlyOneButtonAction} />}
          {processusButton && <ToolbarProcessMenu isRounded={isOnlyOneButtonAction} />}
          {satelliteButton && (
            <ToolbarSatellite
              renderSatelliteMenu={renderSatelliteMenu}
              isRounded={isOnlyOneButtonAction}
            />
          )}
        </Field>
      </div>
    </>
  );
};

type ToolbarFilterActiveProps = { isFilterVisible: boolean; onClearFilter(): void };
const ToolbarFilterActive: SFC<ToolbarFilterActiveProps> = ({
  isFilterVisible = false,
  onClearFilter
}) => {
  const filterItemClasses = classNames("filter-item", {
    "is-active": isFilterVisible
  });

  return (
    <div className="level-item">
      <a className={filterItemClasses} onClick={onClearFilter}>
        <span className="icon has-text-grey-dark">
          <span className="fa-layers fa-fw">
            <Fa icon={["fas", "filter"]} />
            <Fa
              className="has-text-danger"
              icon={["fas", "times-circle"]}
              size="sm"
              transform="shrink-2 down-3 right-9"
            />
          </span>
        </span>
      </a>
    </div>
  );
};

type ToolbarGridPaddingProps = {
  gridPadding: GridPaddingType;
  onGridSizeChange(size: GridPaddingType): void;
};
const ToolbarGridPadding: SFC<ToolbarGridPaddingProps> = ({ gridPadding, onGridSizeChange }) => {
  return (
    <div className="level-item">
      <a onClick={() => onGridSizeChange("small")}>
        <span
          className={classNames("icon", {
            "has-text-grey": gridPadding !== "small"
          })}
          title="small"
        >
          <Fa icon="th" aria-label="size grid small" />
        </span>
      </a>
      <a onClick={() => onGridSizeChange("large")}>
        <span
          className={classNames("icon", {
            "has-text-grey": gridPadding !== "large"
          })}
          title="large"
        >
          <Fa icon="th-large" aria-label="size grid large" />
        </span>
      </a>
    </div>
  );
};

type ToolbarSearchProps = { onSearch(): void };
const ToolbarSearch: SFC<ToolbarSearchProps> = ({ onSearch, children }) => {
  return (
    <div className="level-item">
      <Menu autoclose>
        <Menu.Button>
          <Fa icon="search" />
        </Menu.Button>
        <Menu.Overlay>
          {children}
          <Menu.Item as="button" className="button is-link is-inverted" onClick={onSearch}>
            <Trans i18nKey="commun_recherche_avancee" />
          </Menu.Item>
        </Menu.Overlay>
      </Menu>
    </div>
  );
};

const ToolbarLeft: SFC = ({ children }) => {
  return <div className="level-left">{children}</div>;
};

const ToolbarRight: SFC = ({ children }) => {
  return <div className="level-right">{children}</div>;
};

type ToolbarItemProps = React.HTMLAttributes<HTMLDivElement>;
const ToolbarItem: SFC<ToolbarItemProps> = ({ className, ...props }) => {
  return <div className={classNames("level-item", className)} {...props} />;
};

interface ToolbarStaticFunction {
  Add: React.ComponentType<ToolbarAddProps>;
  Save: React.ComponentType<ToolbarSaveProps>;
  Refresh: React.ComponentType<ToolbarRefreshProps>;
  Delete: React.ComponentType<ToolbarDeleteProps>;
  ProcessMenu: React.ComponentType<ToolbarProcessMenuProps>;
  Satellite: React.ComponentType<ToolbarSatelliteProps>;
  FocusMenu: React.ComponentType<ToolbarFocusMenuProps>;
  ExportExcel: React.ComponentType<ToolbarExportExcelProps>;
  ExportPdf: React.ComponentType<ToolbarExportPdfProps>;
  Action: React.ComponentType<ToolbarActionProps>;
  FilterActive: React.ComponentType<ToolbarFilterActiveProps>;
  GridPadding: React.ComponentType<ToolbarGridPaddingProps>;
  Search: React.ComponentType<ToolbarSearchProps>;
  Left: React.ComponentType;
  Right: React.ComponentType;
  Item: React.ComponentType<ToolbarItemProps>;
}

const Toolbar: SFC<ToolbarProps & { width?: number } & React.HTMLAttributes<HTMLDivElement>> &
  ToolbarStaticFunction = ({ width, sjmoCode, tableName, tableCtrlKey, className, ...props }) => {
  const providerProps = React.useMemo(() => ({ sjmoCode, tableName, tableCtrlKey }), [
    sjmoCode,
    tableName,
    tableCtrlKey
  ]);

  return (
    <ToolbarContext.Provider value={providerProps}>
      <div className="datatable-toolbar" style={{ width }}>
        <div
          className={classNames("level", className)}
          style={{ width: "100%", padding: "0 1em 0 1em" }}
          {...props}
        >
          {props.children}
        </div>
      </div>
    </ToolbarContext.Provider>
  );
};

Toolbar.Add = ToolbarAdd;
Toolbar.Save = ToolbarSave;
Toolbar.Refresh = ToolbarRefresh;
Toolbar.Delete = ToolbarDelete;
Toolbar.ProcessMenu = ToolbarProcessMenu;
Toolbar.Satellite = ToolbarSatellite;
Toolbar.FocusMenu = ToolbarFocusMenu;
Toolbar.ExportExcel = ToolbarExportExcel;
Toolbar.ExportPdf = ToolbarExportPdf;
Toolbar.Action = ToolbarAction;
Toolbar.FilterActive = ToolbarFilterActive;
Toolbar.GridPadding = ToolbarGridPadding;
Toolbar.Search = ToolbarSearch;
Toolbar.Left = ToolbarLeft;
Toolbar.Right = ToolbarRight;
Toolbar.Item = ToolbarItem;

export default Toolbar;
