import React, { Component, SFC } from "react";
import { Trans } from "react-i18next";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Spring } from "react-spring/renderprops";
import get from "lodash-es/get";

import { Pojo } from "types/Galaxy";

import { findOne } from "api";

import { Row, Col } from "composants/Layout";
import { Control } from "composants/form";
import { Field } from "composants/form/Form";
import { convertValue } from "utils/entities.utils";

import Calendar from "composants/calendar/Calendar";
import { executeKanbanLaunch, executeProcessLaunch } from "api/kanban";
import { formatDate } from "utils/i18n";
import { CardWrapper } from "composants/kanban/Kanban";
import { RefLink } from "./common";
import customHistory from "customHistory";
import { Fa } from "composants/Icon";

interface CardBlProps {
  entity: Pojo;
  baseUrl: string;
  galaxyUrl: string;
  laneId: string;
  title: string;
  subtitle: string;
  baseUrlLinkRef?: string;
  linkRef?: string | string[];
  linkRefLabel?: string;
  createdAt: string;
  editAt?: string;
  validatedAt?: string;
  createdAtLabel?: string;
  editAtLabel?: string;
  validatedAtLabel?: string;
  subtitle3: string;
  titleTooltip: string;
  subtitleTooltip: string;
  subtitle2Tooltip: string;
  subtitle3Tooltip: string;
  createdAtTooltip: string;
  validatedAtTooltip: string;
  kanbanId: string;
  modalTemplate?: string;
  onSelectCard(entity: Pojo, laneId: string): void;
}
export const KanbanCardGcoBl: SFC<CardBlProps> = ({
  entity,
  baseUrl,
  galaxyUrl,
  laneId,
  title,
  subtitle,
  createdAt,
  editAt,
  validatedAt,
  createdAtLabel,
  editAtLabel,
  validatedAtLabel,
  baseUrlLinkRef,
  linkRef,
  linkRefLabel,
  onSelectCard,
  subtitle3,
  titleTooltip,
  subtitleTooltip,
  subtitle3Tooltip,
  validatedAtTooltip,
  kanbanId,
  modalTemplate
}) => {
  return (
    <CardWrapper
      id={entity.id}
      entity={entity}
      laneId={laneId}
      className="box box-link"
      role="button"
      onSelectCard={e => {
        if (e.ctrlKey) {
          onSelectCard && onSelectCard(entity, laneId);
        }
      }}
      onOpen={() => {
        if (modalTemplate) {
          customHistory.push(
            `${baseUrl}/${entity.id}?kanbanId=${kanbanId}&template=${modalTemplate}&entityId=${
              entity.id
            }`
          );
        }
      }}
    >
      <div className="columns is-narrow">
        <div className="column">
          <div title={titleTooltip}>
            <Link
              to={galaxyUrl ? `${galaxyUrl}/${entity.id}` : "#"}
              className="underline-link is-capitalized is-size-4 has-text-weight-semibold"
            >
              {title}
            </Link>
          </div>
        </div>
        <div className="column has-text-right">
          <div
            className="is-capitalized is-size-4 has-text-weight-semibold"
            title={subtitleTooltip}
          >
            {subtitle}
          </div>
          <div className="subtitle is-7" title={subtitle3Tooltip}>
            {subtitle3 && <span title={subtitle3Tooltip}>{subtitle3}</span>}
          </div>
        </div>
      </div>
      {baseUrlLinkRef && linkRef ? (
        <RefLink baseUrlLinkRef={baseUrlLinkRef} linkRef={linkRef} linkRefLabel={linkRefLabel} />
      ) : (
        <br />
      )}
      <div className="has-text-grey">
        <div>
          <span className="icon">
            <Fa icon="calendar" fixedWidth />
          </span>
          <Trans i18nKey={createdAtLabel}>créée le </Trans>
          <strong className="ml-8">{formatDate(createdAt)}</strong>
        </div>
        {editAt && (
          <div>
            <span className="icon">
              <Fa icon="calendar-plus" fixedWidth />
            </span>
            <Trans i18nKey={editAtLabel}>edité le </Trans>
            <strong className="ml-8">{formatDate(editAt)}</strong>
          </div>
        )}
        {validatedAt && (
          <div title={validatedAtTooltip}>
            <span className="icon">
              <Fa icon="calendar-check" fixedWidth />
            </span>
            <Trans i18nKey={validatedAtLabel}>validé le</Trans>
            <strong className="ml-8">{formatDate(validatedAt)}</strong>
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

function initModalInfo(blcl: Pojo) {
  let drvFluxCialBl: Record<string, boolean> = {
    drvFbl: false,
    drvFcde: false,
    drvFcli: false,
    drvFfm: false
  };
  switch (blcl.clientId.clntModeFacturation) {
    case "FBL":
      drvFluxCialBl.drvFbl = true;
      break;
    case "FCDE":
      drvFluxCialBl.drvFcde = true;
      break;
    case "FCLI":
      drvFluxCialBl.drvFcli = true;
      break;
    case "FFM":
      drvFluxCialBl.drvFfm = true;
      break;
    default:
  }
  return drvFluxCialBl;
}

interface KanbanModalBlState {
  parent: Pojo | null;
  configurationOpen: boolean;
  loadingValidation: boolean;

  createdFacl: Pojo | null;
  drvFbl: boolean;
  drvFcde: boolean;
  drvFcli: boolean;
  drvFfm: boolean;
  drvFfmDt: string;
}

export class KanbanModalGcoBl extends Component<
  { sjmoCode: string; id: string; kanbanDefinitionId: string; processId: string },
  KanbanModalBlState
> {
  state: KanbanModalBlState = {
    parent: null,
    createdFacl: null,
    configurationOpen: false,
    loadingValidation: false,
    drvFbl: false,
    drvFcde: false,
    drvFcli: false,
    drvFfm: false,
    drvFfmDt: format(new Date())
  };

  componentDidMount() {
    findOne({ tableName: "blClient", id: this.props.id, includeJoinParent: true }).then(res =>
      this.setState({ parent: res.data, ...initModalInfo(res.data) })
    );
  }

  validateConfiguration = () => {
    const { drvFbl, drvFcde, drvFcli, drvFfm, drvFfmDt } = this.state;

    this.setState({ loadingValidation: true }, () => {
      if (this.state.parent === null) {
        return;
      }
      if (this.props.kanbanDefinitionId) {
        executeKanbanLaunch(this.props.sjmoCode, this.props.kanbanDefinitionId, {
          blClientId: this.state.parent.id,
          drvFbl,
          drvFcde,
          drvFcli,
          drvFfm,
          drvFfmDt
        })
          .then(res =>
            this.setState({
              createdFacl: res.data && res.data.length > 0 ? res.data[0] : null
            })
          )
          .catch(() => console.error("error during validation of kanban"))
          .then(() => this.setState({ loadingValidation: false }));
      } else {
        executeProcessLaunch(this.props.sjmoCode, this.props.processId, {
          blClientId: this.state.parent.id,
          drvFbl,
          drvFcde,
          drvFcli,
          drvFfm,
          drvFfmDt
        })
          .then(res =>
            this.setState({
              createdFacl: res.data && res.data.length > 0 ? res.data[0] : null
            })
          )
          .catch(() => console.error("error during validation of kanban"))
          .then(() => this.setState({ loadingValidation: false }));
      }
    });
  };

  render() {
    const { parent: entity } = this.state;

    if (entity === null) {
      return null;
    }

    return (
      <>
        <div style={{ padding: "0 0 4em 0" }}>
          <Row>
            <Col span={4}>
              <div
                className="is-size-1 has-text-black-ter has-text-weight-light"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div>
                  <Link
                    to={`/page/OGCO008/${entity.id}`}
                    title="naviguer vers le bl"
                    className="underline-link"
                  >
                    {entity.id}
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
            >
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                <strong>
                  <Link
                    to={`/page/OGCO001/${entity.clientId.id}`}
                    title="naviguer vers le client"
                    className="underline-link"
                  >
                    {get(entity, "clientId.clntNom")}
                  </Link>
                </strong>
                {" ("}
                {get(entity, "clientId.id")}
                {")"}
              </div>
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                {get(entity, "societeId.sociLibelle")}
              </div>
              <div>
                <button
                  onClick={() => this.setState({ configurationOpen: true })}
                  className="button is-link"
                >
                  Passer en facture &rarr;
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="is-5">
          <Col className="is-narrow">
            <div className="box">
              <h3 className="subtitle is-4 underline">
                <Trans>Autres</Trans>
              </h3>
              <div className="is-size-6 has-text-black-ter has-text-weight-light">
                par <strong>{get(entity, "personnelId.persNom")}</strong> le{" "}
                <strong>{format(entity.blclDtSaisie, "DD/MM/YYYY")}</strong>
              </div>

              <div className="is-size-6 has-text-black-ter has-text-weight-light">
                {entity.blclReferenceClient ? (
                  <>
                    référence <strong>{entity.blclReferenceClient}</strong>
                  </>
                ) : (
                  "aucune référence"
                )}
              </div>

              <div className="is-size-6 has-text-black-ter has-text-weight-light">
                service : <strong>{entity.serviceId.servLibelle}</strong>
              </div>
            </div>
          </Col>
          <Col className="is-narrow px-5">
            <div className="box">
              <h3 className="subtitle is-4 underline">
                <Trans>Adresse</Trans>
              </h3>
              <div className="has-text-weight-bold is-size-4">{entity.clientAdresseId.cladNom}</div>
              <div className="has-text-weigh-light is-size-5">
                {entity.clientAdresseId.cladAdresse1}
              </div>
              <div className="has-text-weigh-light is-size-6">
                {entity.clientAdresseId.cladCp} {entity.clientAdresseId.cladLocalite}
              </div>
            </div>
          </Col>
          <Col className="is-narrow">
            <div className="box">
              <h3 className="subtitle is-4 underline">
                <Trans>Date</Trans>
              </h3>
              <div>
                <span className="icon has-text-weight-bold">
                  <Fa icon="calendar" />
                </span>
                <Trans>créée le </Trans>
                <strong className="ml-8">{formatDate(entity.blclDtSaisie)}</strong>
              </div>
              <div>
                <span className="icon has-text-weight-bold">
                  <Fa icon="calendar-plus" />
                </span>
                <Trans>éditée le </Trans>
                <strong className="ml-8">{formatDate(entity.blclDtEdition)}</strong>
              </div>
              <div>
                <span className="icon has-text-weight-bold">
                  <Fa icon="calendar-check" />
                </span>
                <Trans>envoyée le </Trans>
                <strong className="ml-8">{formatDate(entity.blclDtEnvoi)}</strong>
              </div>
            </div>
          </Col>
        </Row>

        <div>
          {this.state.configurationOpen ? (
            <Spring from={{ right: -400, opacity: 0 }} to={{ right: 0, opacity: 1 }}>
              {styles => {
                return (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: 610,
                        opacity: (styles as any).opacity,
                        top: 5
                      }}
                    >
                      <button
                        className="delete"
                        onClick={() => this.setState({ configurationOpen: false })}
                        aria-label="close"
                      />
                    </div>
                    <div className="octal-overlay" style={{ ...styles, width: 600 }}>
                      <h3 className="pt-5 px-5 subtitle is-3">Configuration</h3>
                      <div
                        className="px-5 py-7 my-7 -ml-px"
                        style={{ borderLeft: "2px solid hsl(217, 71%, 53%)" }}
                      >
                        <Field label="Options">
                          <Control>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.drvFbl}
                                onChange={e => this.setState({ drvFbl: convertValue(e) })}
                              />{" "}
                              <Trans key="static_avec_commentaires">Facture/BL</Trans>
                            </label>
                          </Control>
                        </Field>
                        <Field>
                          <Control>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.drvFcde}
                                onChange={e => this.setState({ drvFcde: convertValue(e) })}
                              />{" "}
                              <Trans key="static_avec_commentaires">Facture/Cde</Trans>
                            </label>
                          </Control>
                        </Field>
                        <Field>
                          <Control>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.drvFcli}
                                onChange={e => this.setState({ drvFcli: convertValue(e) })}
                              />{" "}
                              <Trans key="static_avec_commentaires">Facture/Client</Trans>
                            </label>
                          </Control>
                        </Field>
                        <Field>
                          <Control>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.drvFfm}
                                onChange={e => this.setState({ drvFfm: convertValue(e) })}
                              />{" "}
                              <Trans key="static_avec_commentaires">Fin de mois</Trans>
                            </label>
                          </Control>
                        </Field>
                        <Field className="mb-6" label="Date de facturation">
                          <Calendar
                            id="drvFfmDt"
                            value={this.state.drvFfmDt}
                            onChange={e => this.setState({ drvFfmDt: convertValue(e) })}
                          />
                        </Field>
                      </div>

                      <div className="pb-5 px-5">
                        <button
                          className={classNames("button is-link is-large is-fullwidth", {
                            "is-loading": this.state.loadingValidation
                          })}
                          onClick={this.validateConfiguration}
                        >
                          <Trans i18nKey="commun_valider">Valider</Trans>
                        </button>
                        {this.state.createdFacl && (
                          <Link
                            to={`/page/OGCO027/${this.state.createdFacl.id}`}
                            className="button is-link is-outlined is-large is-fullwidth mt-7"
                          >
                            <Trans i18nKey="commun_naviguer_facl">Ouvrir la galaxie</Trans> &rarr;
                          </Link>
                        )}
                      </div>
                    </div>
                  </>
                );
              }}
            </Spring>
          ) : null}
        </div>
      </>
    );
  }
}
