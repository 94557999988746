import React, {
  Component,
  FC,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from "react";

import { getInformation, getInformationTemplateServer } from "api/information";
import {
  GalaxieListenerContextProps,
  GalaxieListenerComponent,
  withGalaxieRegister
} from "containers/galaxy/GalaxieContextListener";
import { LoadableLoader } from "composants/Loader";

import { Fa } from "composants/Icon";
import useInteractions from "hooks/useInteractions";
import Axios, { CancelTokenSource } from "axios";

const GenericMarkdownDisplay = React.lazy(() =>
  import("composants/genericDisplay/GenericMarkdownDisplay")
);

interface InformationProps {
  sjmoCode: string;
  sjpaId: string;
  currentMainEntityId: string | null; // id de l'entité courante de la galaxie dans laquelle se trouve le composant Information
}

type InformationFetchContextProps = {
  value: string;
  fetchData(value?: string): void;
  setSelected(value: string): void;
};

const InformationFetchContext = React.createContext<InformationFetchContextProps>({
  value: "",
  fetchData: () => {
    /* empty */
  },
  setSelected: () => {
    /* empty */
  }
});

function fetchInformation(
  sjmoCode: string,
  target: string,
  entityId: string | null,
  selected: string | null,
  cancel?: CancelTokenSource
) {
  if (entityId) {
    return getInformation(
      {
        sjmoCode,
        target: target,
        entityId: entityId,
        valueAdditionnelle: selected || undefined
      },
      cancel
    )
      .then(res => res.data)
      .catch(e => {
        if (!Axios.isCancel(e)) {
          console.error(
            "error during fetch of information for ",
            sjmoCode,
            "on the panel ",
            target
          );
          console.error(e);
        }
        return "";
      });
  } else {
    return Promise.resolve("");
  }
}

export const InformationProvider: FC<InformationProps> = ({
  sjmoCode,
  sjpaId,
  currentMainEntityId,
  children
}) => {
  const isMountedRef = useRef(true);
  const [value, setData] = useState("");
  const [selected, setSelected] = useState<string | null>(null);

  const interaction = useInteractions({ sjmoCode, ctrlKey: sjpaId + "" });
  const contextualId = interaction.id || currentMainEntityId;

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const fetchData = useCallback(() => {
    fetchInformation(sjmoCode, sjpaId, contextualId, selected).then(data => setData(data));
  }, [contextualId, selected, sjmoCode, sjpaId]);

  // TODO : on va devoir passer par une state machine
  //        les effets que l'on va lancer pour gérer le nouveau système ne sont plus aussi
  //        simple que changement => refetch
  //
  //        - les composants de type formulaire vont pouvoir effectuer des changements dans astContext
  //        sans pour autant avoir un refetch
  //
  //        - les composants d'actions vont pouvoir forcer les refresh mais pendant un refresh, on va vouloir bloquer les composants actions
  //
  //        à la fin d'un composant action, on va peut-être devoir refetch les autres panels de la page (un composant de type action peut vouloir dire traitement)
  //        pour éviter d'avoir trop de refresh, est-ce qu'il nous faut pas un paramètre à ajouter spécifique ?
  //        ex :
  //        <Button action="change-devise" refreshPageWidget>Changer la devise</Button>
  //
  //        l'avantage étant que, lorsque l'on va effectuer des actions dynamiques autres des traitements, on aura un système qui nous permet d'éviter d'avoir trop
  //        d'appels réseaux
  //
  //        autre chose qui pourrait être intéressante, react-query nous permettrait de simplifier le système de listener que l'on a :
  //        si valeurs des panels sont enregistrés via `[module, panel, templateName]`, pour les cas de reset, on pourrait simplement faire : cache.invalidateQueries([module]);
  //        et react-query s'occupe du reste.
  //
  //        => en sachant que react-query est déjà une bibliothèque étudié pour remplacer le système custom des autompletes.
  //        le système fonctionne correctement, il ne manque plus que l'implémentation
  //        le système actuellement en place est d'ailleurs calculé sur le modèle de react-query.
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();
    let isValid = true;

    fetchInformation(sjmoCode, sjpaId, contextualId, selected, cancelToken).then(
      data => isValid && setData(data)
    );

    return () => {
      cancelToken.cancel();
      isValid = false;
    };
  }, [contextualId, selected, sjmoCode, sjpaId]);

  const context = useMemo<InformationFetchContextProps>(() => {
    return { value, fetchData, setSelected };
  }, [fetchData, value]);

  return (
    <InformationFetchContext.Provider value={context}>{children}</InformationFetchContext.Provider>
  );
};

export const InformationRefresh: FC = () => {
  const context = useContext(InformationFetchContext);

  return (
    <span role="button" className="has-text-link" onClick={() => context.fetchData()}>
      <span className="icon">
        <Fa icon="sync-alt" transform="down-2" />
      </span>
    </span>
  );
};

class Information extends Component<GalaxieListenerContextProps>
  implements GalaxieListenerComponent {
  static contextType = InformationFetchContext;

  private unregister: () => void;

  componentDidMount() {
    this.unregister = this.props.register(this);
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  /**
   * Implémente le refresh en car il est listener de la Galaxie
   */
  refresh = () => {
    this.fetchData();
  };

  fetchData = () => {
    if (this.context) {
      (this.context as InformationFetchContextProps).fetchData();
    }
  };

  onChange = (value: string) => {
    const context = this.context as InformationFetchContextProps;
    context.setSelected(value);
  };

  render() {
    const context = this.context as InformationFetchContextProps;

    if (context.value) {
      return (
        <React.Suspense fallback={<LoadableLoader delay={2000} />}>
          <GenericMarkdownDisplay value={context.value} onChange={this.onChange} />
        </React.Suspense>
      );
    } else {
      return null;
    }
  }
}

export default withGalaxieRegister(Information);
