import React, { SFC, useState, useCallback } from "react";
import { Trans } from "react-i18next";
import { getEntityLabels } from "api";
import { AxiosError } from "axios";
import { t } from "utils/i18n";
import { useEffect } from "react";

interface TabHeaderProps {
  tableName: string;
  contextId?: string;
  count: number;
  i18nKey: string;
  sjmoCode: string;
}

const TabHeader: SFC<TabHeaderProps> = props => {
  const [labels, setLabels] = useState<null | { table: String; entity: string }>(null);

  const refresh = useCallback(() => {
    if (!props.contextId) {
      return;
    }
    getEntityLabels(props.sjmoCode, props.tableName, props.contextId as string)
      .then(response => {
        setLabels(response.data);
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }
        console.log(t(er.response.data.message));
      });
  }, [props.sjmoCode, props.tableName, props.contextId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-5" style={{ maxWidth: "60vw" }}>
            <Trans i18nKey={props.i18nKey}>
              {{ count: props.count }} satellites liés à l'élément :{{ tableName: labels?.table }} (
              {{ oldPks: [labels?.entity] }})
            </Trans>
          </p>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item">{props.children}</div>
      </div>
    </nav>
  );
};

export default TabHeader;
