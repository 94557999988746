import produce from "immer";
import Action from "reducers/Action";

import { INIT_USER_SETTINGS } from "constant/userSettings";
import { UserSettingProps } from "types/UserSettings";

export interface UserSettingsState {
  id?: string;
  prenom?: string;
  nom?: string;
  civilite?: string;
  lang: string;
  hash: string;
  role: string;
  isSuperUser: boolean;
  societeEnCours: string;
  societeDisponible: string[];
}

const initialState: UserSettingsState = {
  lang: "fr",
  isSuperUser: false,
  hash: "",
  role: "",
  societeEnCours: "",
  societeDisponible: []
};

export default function userSettingsReducer(
  state: UserSettingsState = initialState,
  action: Action<UserSettingProps>
) {
  switch (action.type) {
    case INIT_USER_SETTINGS:
      return produce(state, draft => {
        draft.id = action.payload.id;
        draft.prenom = action.payload.prenom;
        draft.nom = action.payload.nom;
        draft.civilite = action.payload.civilite;
        draft.lang = action.payload.lang;
        draft.hash = action.payload.hash;
        draft.role = action.payload.role;
        draft.isSuperUser = action.payload.superUser;
        draft.societeEnCours = action.payload.societeEnCours;
        draft.societeDisponible = action.payload.societeDisponible;
      });
    default:
      return state;
  }
}
