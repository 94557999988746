import React, { Component, SFC } from "react";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";
import memoize from "memoizee";
import produce from "immer";
import { connect } from "react-redux";
import { RSQLCriteria, RSQLFilterExpression, Operators } from "rsql-criteria-typescript";
import { AxiosResponse } from "axios";
import { Trans } from "react-i18next";

import camelCase from "lodash-es/camelCase";
import get from "lodash-es/get";
import set from "lodash-es/set";
import debounce from "lodash-es/debounce";

import { Kanban, KanbanLane } from "composants/kanban/Kanban";
import { Pojo } from "types/Galaxy";
import { KanbanLane as KanbanLaneType } from "types/Component";
import { findAll } from "api";
import Modal from "composants/Modal/Modal";

import { CardTemplate, ModalTemplate } from "composants/kanban/template";

import "./KanbanPage.css";
import { KanbanDefinition } from "types/Component";
import {
  getKanbanDefinition,
  executeKanbanMove,
  findDataKanban,
  executeKanbanValidationLane,
  executeKanbanValidationGlobal
} from "api/kanban";

import { RSQLFilterExpressionString } from "utils/query.utils";
import { listToMap, findIndexMap, mapToList, convertToMap } from "utils/entities.utils";
import { setActive } from "actions/galaxy.action";
import { clearContext } from "actions/satellites";

import { PagedResource } from "types/Search";
import { Button } from "composants/button";

import { FilterBar } from "types/Search";
import { FilterBarDefinition } from "types/Component";
import { launchProcessFromGalaxyHeader, addNewEntity } from "actions/processus";
import { Fa } from "composants/Icon";

const getComponentProps = memoize((json: object, entity: Pojo) => {
  const props = {};

  for (let key of Object.keys(json)) {
    props[key] = entity[json[key]] === undefined ? json[key] : entity[json[key]];
  }

  return props;
});

type KanbanLaneComponentProps = {
  kanbanId: string;
  lane: KanbanLaneType;
  baseUrl: string;
  filter?: string;
  entities: Record<string, Pojo>;
  firstPage: number;
  modalTemplate?: string;
  onSelectCard(entity: Pojo, laneId: string): void;
};

class KanbanLaneComponent extends Component<KanbanLaneComponentProps> {
  render() {
    const { lane, baseUrl } = this.props;

    const SelectedComponent = CardTemplate[lane.template];

    if (!SelectedComponent) {
      return "aucun template trouvé";
    }

    return (
      <KanbanLane key={lane.code} id={lane.code}>
        {Object.keys(this.props.entities).map(key => {
          const entity = this.props.entities[key];
          const props = getComponentProps(lane.jsonMapping, entity);
          const entityUniqueKey = lane.view ? `${entity.tableName}-${entity.id}` : entity.id;
          const reactUniqueKey = `${lane.code}-${entityUniqueKey}`;
          return (
            <SelectedComponent
              key={reactUniqueKey}
              laneId={lane.code}
              entity={entity}
              baseUrl={baseUrl}
              onSelectCard={this.props.onSelectCard}
              kanbanId={this.props.kanbanId}
              modalTemplate={this.props.modalTemplate}
              {...props}
            />
          );
        })}
      </KanbanLane>
    );
  }
}

interface KanbanPageReduxFn {
  setActive(sjmoCode: string): void;
  lanchProcess(
    sjmoCode: string,
    sjipId: string,
    contextTableName: string,
    contextIds: string[],
    navigationUrl?: string,
    forAll?: boolean,
    callback?: () => void
  ): void;
  clearAllSatellites(sjmoCode: string): void;
  setupEntityAdvancedProcess(entity: Partial<Pojo>): void;
}
type KanbanPageAllProps = { sjmoCode: string } & RouteComponentProps<{}> & KanbanPageReduxFn;

interface KanbanPageState {
  definition: KanbanDefinition | null;
  filter: Record<string, string>;
  entities: Record<string, Record<string, Pojo>>;
  totalRecords: Record<string, number>;
  lanePage: Record<string, number>;
  filterBarDefinitions: Record<string, FilterBarDefinition[]>;
  filterBars: Record<string, FilterBar>;
  selectAllByLanes: Record<string, Pojo[]>;
}

type RangeDragAndDrop = { oldIndex: number; newIndex: number };

const isSelectedAll = memoize(
  (totalRecords: number, ids: Pojo[]) => {
    return totalRecords === ids.length;
  },
  { primitive: true }
);

function isSelectedAllByLanes(
  selectAllByLanes: Record<string, Pojo[]>,
  totalRecords: Record<string, number>
): Record<string, boolean> {
  const result = {};
  const keys = Object.keys(selectAllByLanes);
  for (let key of keys) {
    result[key] = isSelectedAll(totalRecords[key], selectAllByLanes[key]);
  }

  return result;
}

type SearchSetupResult =
  | false
  | { first: number; size: number; filter: string; kanbanId: string; lane: KanbanLaneType };

class KanbanPage extends Component<KanbanPageAllProps, KanbanPageState> {
  static PAGE_SIZE_DEFAULT = 15;

  state: KanbanPageState = {
    definition: null,
    filter: {},
    entities: {},
    totalRecords: {},
    lanePage: {},
    filterBarDefinitions: {},
    filterBars: {},
    selectAllByLanes: {}
  };

  private pendingUpdate: RangeDragAndDrop | null = null;
  private requestedFrame: any = null;

  setupSearch = (laneId: string, start: number, size: number): SearchSetupResult => {
    if (this.state.definition === null) {
      return false;
    }

    const kanbanId = this.state.definition.kanban;
    const lane = this.state.definition.lanes.find(l => l.code === laneId);

    if (!lane) {
      return false;
    }

    const criteria = new RSQLCriteria("q", "order");
    criteria.filters.and(new RSQLFilterExpressionString(lane.filter));

    // on travaille avec l'index auquel on doit commencer avec l'API.
    const first = start * size;

    const filter = this.state.filter[lane.code];
    const filterBar = this.state.filterBars[laneId];

    const combineFilters = [
      criteria.build(),
      lane.sort && "order=" + encodeURIComponent(lane.sort),
      this.props.sjmoCode && "sjmoCode=" + this.props.sjmoCode,
      "contextKey=" + laneId,
      this.state.definition.token && "token=" + this.state.definition.token,
      filter && "searchTerm=" + encodeURIComponent(filter),
      filterBar && filterBar.filterBarId && "filterBarId=" + filterBar.filterBarId,
      filterBar &&
        filterBar.startDate &&
        "filterBarStart=" + encodeURIComponent(filterBar.startDate),
      filterBar && filterBar.endDate && "filterBarEnd=" + encodeURIComponent(filterBar.endDate),
      filterBar &&
        filterBar.filterBarDefaultDtFilter &&
        "filterBarDefaultDtFilter=" + encodeURIComponent(filterBar.filterBarDefaultDtFilter)
    ];

    return {
      kanbanId,
      lane,
      first,
      size,
      filter: combineFilters.filter(e => e).join("&")
    };
  };

  findIds = (laneId: string): Promise<void> => {
    const searchParams = this.setupSearch(laneId, 0, 0);
    if (searchParams === false) {
      return Promise.resolve();
    }

    const { kanbanId, lane, first, filter } = searchParams;

    return new Promise(resolve => {
      const responseSearch = (res: AxiosResponse<PagedResource<Pojo>>) => {
        const newState = produce(this.state, draft => {
          draft.selectAllByLanes[laneId] = res.data.data;
        });
        this.setState(newState, () => resolve());
      };

      if (lane.view) {
        findDataKanban({
          kanbanId,
          tableName: lane.tableName,
          filter,
          first,
          size: false
        })
          .then(responseSearch)
          .catch(() => console.error("error during fetch with findDataKanban"));
      } else {
        findAll({
          tableName: lane.tableName,
          filter,
          first,
          size: false
        })
          .then(responseSearch)
          .catch(() => console.error("error during fetch with findAll"));
      }
    });
  };

  search = (
    laneId: string,
    start = 0,
    reset = false,
    size: number = KanbanPage.PAGE_SIZE_DEFAULT
  ) => {
    const searchParams = this.setupSearch(laneId, start, size);
    if (searchParams === false) {
      return;
    }

    const { kanbanId, lane, first, filter } = searchParams;

    const responseSearch = (res: AxiosResponse<PagedResource<Pojo>>) => {
      if (reset) {
        const newState = produce(this.state, draft => {
          draft.entities[laneId] = listToMap(res.data.data, first);
          draft.totalRecords[laneId] = res.data.meta.totalRecords;
        });
        this.setState(newState);
      } else {
        const newState = produce(this.state, draft => {
          draft.entities[laneId] = {
            ...draft.entities[laneId],
            ...listToMap(res.data.data, first)
          };
          draft.totalRecords[laneId] = res.data.meta.totalRecords;
        });
        this.setState(newState);
      }
    };

    if (lane.view) {
      findDataKanban({
        kanbanId,
        tableName: lane.tableName,
        filter,
        first,
        size
      })
        .then(responseSearch)
        .catch(() => console.error("error during fetch with findDataKanban"));
    } else {
      findAll({
        tableName: lane.tableName,
        filter,
        first,
        size
      })
        .then(responseSearch)
        .catch(() => console.error("error during fetch with findAll"));
    }
  };

  getInitialDataByLane = <T extends any>(
    lanes: KanbanLaneType[],
    initial: (lane: KanbanLaneType) => T
  ) => {
    let laneData: Record<string, T> = {};
    lanes.forEach(lane => (laneData[lane.code] = initial(lane)));
    return laneData;
  };

  callApiForLanes = (first?: number, reset?: boolean, size?: number) => {
    if (this.state.definition) {
      this.state.definition.lanes.forEach(lane => this.search(lane.code, first, reset, size));
    }
  };

  componentDidMount() {
    this.props.clearAllSatellites(this.props.sjmoCode);

    getKanbanDefinition(this.props.sjmoCode)
      .then(res => {
        this.setState(
          {
            definition: res.data,
            entities: this.getInitialDataByLane(res.data.lanes, () => ({})),
            totalRecords: this.getInitialDataByLane(res.data.lanes, () => 0),
            lanePage: this.getInitialDataByLane(res.data.lanes, () => 0),
            filterBarDefinitions: convertToMap(
              res.data.lanes,
              el => el.code,
              el => el.filters
            ),
            filterBars: this.getInitialDataByLane<FilterBar>(res.data.lanes, lane => {
              const privilegie = lane.filters.find(filter => filter.privilegie === true);
              return {
                filterBarId: privilegie ? privilegie.filterBarId : null,
                startDate: null,
                endDate: null,
                filterBarDefaultDtFilter: lane.defaultDtFilter
              };
            }),
            selectAllByLanes: this.getInitialDataByLane<Pojo[]>(res.data.lanes, () => [])
          },
          () => this.callApiForLanes()
        );
      })
      .catch(() => console.error("error fetching kanban definition"));

    this.props.setActive(this.props.sjmoCode);
  }

  scheduleUpdate = (toUpdate: RangeDragAndDrop) => {
    this.pendingUpdate = toUpdate;

    if (!this.requestedFrame) {
      this.requestedFrame = requestAnimationFrame(this.drawFrame);
    }
  };

  drawFrame = () => {
    // if (this.pendingUpdate) {
    //   this.setState({
    //     devisClient: arrayMove(
    //       this.state.devisClient,
    //       this.pendingUpdate.oldIndex,
    //       this.pendingUpdate.newIndex
    //     )
    //   });
    //   this.pendingUpdate = null;
    //   this.requestedFrame = null;
    // }
  };

  moveCard = (dragged: string, hoverred: string) => {
    // const draggedIndex = this.state.devisClient.findIndex(dvcl => dvcl.id === dragged);
    // const hoverredIndex = this.state.devisClient.findIndex(dvcl => dvcl.id === hoverred);
    // if (draggedIndex !== -1 && hoverredIndex !== -1) {
    //   this.scheduleUpdate({
    //     oldIndex: draggedIndex,
    //     newIndex: hoverredIndex
    //   });
    // }
  };

  changeLaneSelected = (tableName: string, oldLaneId: string, newLaneId: string) => {
    const { definition } = this.state;
    if (definition === null) {
      return;
    }

    const ids = this.state.selectAllByLanes[oldLaneId];

    const currentLane = definition.lanes.find(l => l.code === newLaneId);

    const newState = produce(this.state, draft => {
      draft.selectAllByLanes[oldLaneId] = [];
      draft.selectAllByLanes[newLaneId] = [];

      const listOldEntities = mapToList(draft.entities[oldLaneId]);

      let lastIndex = Object.keys(draft.entities[newLaneId]).length;

      for (let i = 0; i < listOldEntities.length; i++) {
        let oldEntity = listOldEntities[i];
        for (let selectedEntity of ids) {
          if (oldEntity.id === selectedEntity.id) {
            delete draft.entities[oldLaneId][i];
            draft.entities[newLaneId][lastIndex++] = selectedEntity;
          }
        }
      }
    });

    this.setState(newState);

    if (currentLane && currentLane.advancedProcessMove) {
      this.props.setupEntityAdvancedProcess({ TOKEN: definition.token });
      this.props.lanchProcess(
        this.props.sjmoCode,
        currentLane.processIdMove,
        camelCase(tableName),
        ids.map(p => p.id)
      );
    } else {
      executeKanbanMove(this.props.sjmoCode, definition.kanban, newLaneId, ids)
        .then(this.updateAllLaneAfterApiCall)
        .catch(this.updateAllLaneAfterApiCall);
    }
  };

  changeLane = (entity: Pojo, laneId: string) => {
    const { definition } = this.state;
    if (definition === null) {
      return;
    }

    const oldIndex = definition.lanes
      .map(lane => {
        const pojoIndex = findIndexMap(
          this.state.entities[lane.code],
          pojo => pojo.id === entity.id
        );
        return { laneCode: lane.code, pojoIndex };
      })
      .reduce((acc, curr) => {
        return acc != null ? acc : curr.pojoIndex !== -1 ? curr : null;
      }, null);

    if (oldIndex !== null && this.state.selectAllByLanes[oldIndex.laneCode].length > 0) {
      this.changeLaneSelected(entity.tableName, oldIndex.laneCode, laneId);
      return;
    }

    if (oldIndex !== null && oldIndex.pojoIndex !== -1) {
      const newState = produce(this.state, draft => {
        const entitiesOldIndexList = mapToList(draft.entities[oldIndex.laneCode]);
        entitiesOldIndexList.splice(oldIndex.pojoIndex, 1);
        draft.entities[oldIndex.laneCode] = listToMap(entitiesOldIndexList);

        const entitiesNewIndexList = mapToList(draft.entities[laneId]);
        entitiesNewIndexList.splice(0, 0, entity);
        draft.entities[laneId] = listToMap(entitiesNewIndexList);
      });

      this.setState(newState);
    }

    const currentLane = definition.lanes.find(l => l.code === laneId);

    if (currentLane && currentLane.advancedProcessMove) {
      const ids = [entity.id];

      this.props.setupEntityAdvancedProcess({ TOKEN: definition.token, KANBAN_LANE_CODE: laneId });
      this.props.lanchProcess(
        this.props.sjmoCode,
        currentLane.processIdMove,
        camelCase(entity.tableName),
        ids
      );
    } else {
      executeKanbanMove(this.props.sjmoCode, definition.kanban, laneId, entity)
        .then(this.updateAllLaneAfterApiCall)
        .catch(this.updateAllLaneAfterApiCall);
    }
  };

  validateLane = (laneId: string) => {
    const { definition } = this.state;
    if (definition === null) {
      return;
    }

    const currentLane = definition.lanes.find(l => l.code === laneId);

    if (currentLane && currentLane.advancedProcessLane) {
      const currentTableName = get(
        this.state.entities,
        [currentLane.code, 0, "tableName"],
        currentLane.tableName
      );

      this.findIds(laneId).then(() => {
        this.props.setupEntityAdvancedProcess({
          TOKEN: definition.token,
          KANBAN_LANE_CODE: laneId
        });
        this.props.lanchProcess(
          this.props.sjmoCode,
          currentLane.processIdLane,
          camelCase(currentTableName),
          this.state.selectAllByLanes[laneId].map(it => it.id)
        );
      });
    } else {
      executeKanbanValidationLane(this.props.sjmoCode, definition.kanban, laneId)
        .then(this.updateAllLaneAfterApiCall)
        .catch(this.updateAllLaneAfterApiCall);
    }
  };

  validateGlobal = () => {
    const { definition } = this.state;
    if (definition == null) {
      return;
    }
    // s'il n'y a pas de validation globale, on ne permet pas de lancer la fonction
    if (!definition.canValidateGlobally) {
      return;
    }

    executeKanbanValidationGlobal(this.props.sjmoCode, definition.kanban)
      .then(this.updateAllLaneAfterApiCall)
      .catch(this.updateAllLaneAfterApiCall);
  };

  updateAllLaneAfterApiCall = () => {
    if (this.state.definition === null) {
      return;
    }

    if (this.state.definition) {
      this.state.definition.lanes.forEach(lane =>
        this.search(
          lane.code,
          0,
          true,
          Math.max(
            this.state.lanePage[lane.code] * KanbanPage.PAGE_SIZE_DEFAULT,
            KanbanPage.PAGE_SIZE_DEFAULT
          )
        )
      );
    }
  };

  updateFilter = (lane: string, value: string) => {
    this.setState({ filter: { ...this.state.filter, [lane]: value } }, () => {
      this.search(lane, 0, true);
    });
  };

  loadMoreRow = (laneId: string) => {
    this.setState(
      state => ({
        lanePage: { ...state.lanePage, [laneId]: (state.lanePage[laneId] || 0) + 1 }
      }),
      () => {
        this.search(laneId, this.state.lanePage[laneId]);
      }
    );
  };

  debounceReloadAfterFilterBarChange = debounce((laneId: string) => {
    this.search(
      laneId,
      0,
      true,
      Math.max(
        this.state.lanePage[laneId] * KanbanPage.PAGE_SIZE_DEFAULT,
        KanbanPage.PAGE_SIZE_DEFAULT
      )
    );
  }, 300);

  filterBarChange = (type: keyof FilterBar) => (laneId: string, val: number | string | Date) => {
    this.setState(
      produce(
        this.state,
        draft => {
          set(draft.filterBars, [laneId, type], val);
        },
        () => {
          this.debounceReloadAfterFilterBarChange(laneId);
        }
      )
    );
  };

  onSelectAllChange = (laneId: string) => {
    const { definition } = this.state;
    if (definition === null) {
      return;
    }

    if (isSelectedAll(this.state.totalRecords[laneId], this.state.selectAllByLanes[laneId])) {
      const newState = produce(this.state, draft => {
        draft.selectAllByLanes[laneId] = [];
      });

      this.setState(newState);
    } else {
      this.findIds(laneId);
    }
  };

  onSelectCard = (entity: Pojo, laneId: string) => {
    const newState = produce(this.state, draft => {
      const index = draft.selectAllByLanes[laneId].findIndex(el => el.id === entity.id);
      if (index !== -1) {
        draft.selectAllByLanes[laneId].splice(index, 1);
      } else {
        draft.selectAllByLanes[laneId].push({ ...entity });
      }
    });
    this.setState(newState);
  };

  render() {
    const { definition } = this.state;
    if (definition === null) {
      return null;
    }

    let detailTemplates: any = {};
    definition.details.forEach(detail => {
      detailTemplates[detail.kanbaLaneCode] = detail.template;
    });

    return (
      <div>
        <Button
          className="is-rounded"
          onClick={this.validateGlobal}
          disabled={
            this.state.definition == null || this.state.definition.canValidateGlobally === false
          }
        >
          <span className="icon">
            <Fa icon="check" />
          </span>
          <Trans i18nKey="commun_valider_global">Validation globale</Trans>
        </Button>
        <div style={{ width: "100%", height: "100%" }}>
          <Kanban
            height={650}
            width={400}
            lanes={definition.lanes.map(l => ({
              title: l.label,
              id: l.code,
              disabledValidation:
                l.processIdLane === null
                  ? true
                  : this.state.entities[l.code]
                  ? Object.keys(this.state.entities[l.code]).length === 0
                  : true
            }))}
            canMove={definition.canMove}
            canReorder={definition.canReorder}
            moveCard={this.moveCard}
            changeLane={this.changeLane}
            onValidateLane={this.validateLane}
            onFilter={this.updateFilter}
            loadMoreRow={this.loadMoreRow}
            filterBarFilters={this.state.filterBarDefinitions}
            filterBar={this.state.filterBars}
            selectAll={isSelectedAllByLanes(this.state.selectAllByLanes, this.state.totalRecords)}
            selectedCards={this.state.selectAllByLanes}
            onSelectAllChange={this.onSelectAllChange}
            filterBarSelectedFilterChange={this.filterBarChange("filterBarId")}
            filterBarStartDateChange={this.filterBarChange("startDate")}
            filterBarEndDateChange={this.filterBarChange("endDate")}
          >
            {definition.lanes.map(lane => (
              <KanbanLaneComponent
                key={lane.code}
                lane={lane}
                baseUrl={this.props.match.url}
                filter={this.state.filter[lane.code]}
                entities={this.state.entities[lane.code]}
                firstPage={this.state.lanePage[lane.code]}
                onSelectCard={this.onSelectCard}
                kanbanId={definition.kanban}
                modalTemplate={detailTemplates[lane.code]}
              />
            ))}
          </Kanban>
        </div>
      </div>
    );
  }
}

export default connect<{}, KanbanPageReduxFn>(undefined, {
  setActive,
  lanchProcess: launchProcessFromGalaxyHeader,
  setupEntityAdvancedProcess: addNewEntity,
  clearAllSatellites: clearContext
})(KanbanPage);
