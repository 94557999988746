import React, { Component } from "react";
import "./dashboard.css";
import { PanelState } from "types/Dashboard";

import { Fa } from "composants/Icon";
import toaster from "composants/toaster/toaster";
import { t } from "utils/i18n";
import { uuidv4 } from "utils/uuid.utils";
import { copyToClipboard } from "utils/clipboard.utils";

interface Props {
  id?: any;
  title: string;
  style?: object;
  onPanelClose: Function;
  noPaddingContent?: boolean;
  isOverflowScroll?: boolean;
  panel: PanelState;
  leftHeader?: React.ReactNode;
  onOpenCreator?(creatorTableName: string): void;
}

class Panel extends Component<Props> {
  openCreator = () => {
    const creatorTableName = this.props.panel.creatorTableName;
    if (creatorTableName && this.props.onOpenCreator) {
      this.props.onOpenCreator(creatorTableName);
    }
  };

  onPanelClose = () => {
    this.props.onPanelClose(this.props.panel.panelId);
  };

  render() {
    return (
      <div
        className="card"
        style={{
          ...this.props.style,
          ...{
            borderRadius: 5,
            boxShadow: "0 2px 4px 0 rgba(0,0,0,0.10)"
          }
        }}
      >
        <header className="card-header card-header-draggable" style={{ height: "2em" }}>
          <div className="card-header-title">
            {this.props.title}
            {this.props.leftHeader}
          </div>
          <div className="card-header-icon">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              onClick={() => {
                copyToClipboard(this.props.panel.panelId.toString());
              }}
            >
              <Fa icon="info" title={this.props.panel.panelId.toString()} fixedWidth />
            </a>
            {this.props.panel.creatorTableName !== null && this.props.onOpenCreator ? (
              <a onClick={this.openCreator} aria-label="open creator">
                <Fa icon="plus-circle" fixedWidth />
              </a>
            ) : null}
            {!this.props.panel.mandatory && (
              <a onClick={this.onPanelClose} aria-label="close">
                <Fa icon="times" fixedWidth transform="down-2" />
              </a>
            )}
          </div>
        </header>
        <div
          id={this.props.id}
          className="card-content"
          style={{
            height: "calc(100% - 2em)",
            padding: this.props.noPaddingContent ? 0 : undefined,
            overflowY: this.props.isOverflowScroll ? "auto" : undefined,
            overflowX: this.props.isOverflowScroll ? "auto" : undefined
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Panel;
