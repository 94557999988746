import React, { Component, FC, useState, useMemo, useEffect, useContext } from "react";
import { withTranslation, WithTranslation, useTranslation } from "react-i18next";
import { Fa } from "composants/Icon";

interface ModeDebugContextProps {
  modeDebug: boolean;
  toggleModeDebug(): void;
}

const ModeDebugContext = React.createContext<ModeDebugContextProps>({
  modeDebug: false,
  toggleModeDebug: () => {
    console.log("toggle mode debug");
  }
});

export const ModeDebugProvider: FC = props => {
  // la valeur initiale est calculé en fonction du session storage, la valeur par défaut est false.
  const [modeDebug, setModeDebug] = useState(() => sessionStorage.getItem("modeDebug") === "true");

  const value = useMemo(() => {
    return {
      modeDebug,
      toggleModeDebug: () => {
        setModeDebug(old => {
          const newValue = !old;
          sessionStorage.setItem("modeDebug", newValue.toString());
          return newValue;
        });
      }
    };
  }, [modeDebug]);

  return <ModeDebugContext.Provider value={value}>{props.children}</ModeDebugContext.Provider>;
};

export function useModeDebug() {
  const { modeDebug } = useContext(ModeDebugContext);
  return modeDebug;
}

export const ModeDebug: FC = () => {
  const { modeDebug, toggleModeDebug } = useContext(ModeDebugContext);
  const { t } = useTranslation();

  return (
    <a className={`has-text-${modeDebug ? "success" : "danger"}`} onClick={toggleModeDebug}>
      <span className="fa-stack mr-7" style={{ fontSize: 10, verticalAlign: "top" }}>
        <Fa icon={["fal", "circle"]} className="fa-stack-2x " />
        <Fa icon="bug" className="fa-stack-1x" />
      </span>
      <span>{t("commun_mode_debug")}</span>
    </a>
  );
};

export const ModeDebugIcon: FC = () => {
  const { modeDebug } = useContext(ModeDebugContext);

  return modeDebug ? (
    <span className="fa-stack ">
      <Fa icon={["fas", "circle"]} className="fa-stack-2x has-text-grey-light" />
      <Fa icon="bug" className="fa-stack-1x has-text-black" />
    </span>
  ) : null;
};
