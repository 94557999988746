import { call, put, takeLatest, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { t } from "utils/i18n";
import history from "customHistory";
import Action from "reducers/Action";

/***************** TYPES *********************/
import { EmailState } from "types/Processus";
import { Message } from "types/Message";
/*************** CONSTANT ******************/
import {
  DISPLAY_EMAIL,
  SEND_EMAIL,
  CLOSE_EMAIL,
  START_EMAIL_LOADER,
  STOP_EMAIL_LOADER,
  DELETE_ADDTIONNAL_ATTACHMENT,
  DELETE_ADDTIONNAL_ATTACHMENT_SUCCESS,
  REMOVE_SENDED_EMAIL,
  LOAD_DISPLAY_MAIL,
  SET_EMAIL_STRUCT
} from "constant/email";

/***************** API *********************/
import { sendMail, deleteAdditionnalAttachment, getDefinitionFromScenario } from "api/email";
/**************** SELECTORS ****************/
import { selectEmailStruct, selectTotalEmail } from "selectors/email.selectors";
/**************** ACTIONS ******************/
import { addMessage } from "actions/messages";
import { fetchMailCount } from "actions/satellites";

/**
 * affiche un email
 * @param action action redux
 */
function* onDisplayEmail(action: Action<EmailState[]>) {
  yield put({
    type: SET_EMAIL_STRUCT,
    payload: {
      mailStruct: action.payload
    }
  });

  const params = new URLSearchParams();
  params.append("mail", "true");
  return history.push({ search: params.toString() });
}

/**
 * Charge et affiche l'interface de mail
 * @param action action redux
 */
function* onLoadDisplayEmail(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    entityId: string;
    defaultEditionProcessus: string;
  }>
) {
  try {
    const definitionResponse = yield call(getDefinitionFromScenario, action.payload);

    if (definitionResponse.data) {
      yield put({
        type: SET_EMAIL_STRUCT,
        payload: { mailStruct: definitionResponse.data }
      });
    }

    const params = new URLSearchParams();
    params.append("mail", "true");
    return history.push({ search: params.toString() });
  } catch {
    console.error("erreur lors de la récupération de la définition des mails ");
  }
}

/**
 * Envoi un email
 * @param action action redux
 */
function* onSendEmail(action: Action<string>) {
  try {
    const selectProperties = {};
    const emailStruct: EmailState = yield select(selectEmailStruct, selectProperties);
    let mailStruct = { ...(emailStruct as any), corps: action.payload };
    yield put({
      type: START_EMAIL_LOADER
    });

    if (emailStruct) {
      const response: AxiosResponse<string> = yield call(sendMail, mailStruct);
    }
    const message: Message = {
      code: "",
      message: t("commun_msg_envoye"),
      type: "SUCCESS",
      target: "GLOBAL"
    };
    const totalMail = yield select(selectTotalEmail, {});

    yield put(addMessage(message));
    yield put({
      type: STOP_EMAIL_LOADER
    });
    yield put({
      type: REMOVE_SENDED_EMAIL
    });
    // si il ne reste plus de mails à envoyer

    if (totalMail - 1 <= 0) {
      yield call(onCloseEmail, {} as any);
    }

    if (mailStruct.tableName && mailStruct.entityId) {
      // update des data satellites pour le mail historique
      yield put(fetchMailCount(mailStruct.tableName, mailStruct.entityId));
    }
  } catch {
    yield put({
      type: STOP_EMAIL_LOADER
    });
    console.error("erreur lors de l'envoi de mail ");
  }
}

/**
 * ferme la fenetre de mail
 * @param action action redux
 */
function* onCloseEmail(action: Action<{}>) {
  // clear du reducer

  const params = new URLSearchParams();
  params.delete("mail");
  return history.push({ search: params.toString() });
}

/**
 * ferme la fenetre de mail
 * @param action action redux
 */
function* onDeleteAdditionnalAttachment(action: Action<{ key: string; fromGED: boolean }>) {
  try {
    const { key, fromGED } = action.payload;

    let response: AxiosResponse<string> | { data: false } = fromGED
      ? { data: false }
      : yield call(deleteAdditionnalAttachment, key);

    if (response.data || fromGED) {
      yield put({
        type: DELETE_ADDTIONNAL_ATTACHMENT_SUCCESS,
        payload: { fileKey: key }
      });
    }
  } catch {
    console.error(
      "erreur lors de la suppression d'une piece jointe saga.onDeleteAdditionnalAttachment"
    );
  }
}

// export d'une liste qui est utilisée dans l'index des saga
export default [
  takeLatest(DISPLAY_EMAIL, onDisplayEmail),
  takeLatest(LOAD_DISPLAY_MAIL, onLoadDisplayEmail),
  takeLatest(SEND_EMAIL, onSendEmail),
  takeLatest(CLOSE_EMAIL, onCloseEmail),
  takeLatest(DELETE_ADDTIONNAL_ATTACHMENT, onDeleteAdditionnalAttachment)
];
