import React, { Component } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import { Button } from "../button";
import Scrollbars from "react-custom-scrollbars";
import { t } from "utils/i18n";

import "./satelliteTab.css";
import { fetchConfigurateurData } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import ProcessusMenu from "../processus/ProcessusMenu";
import TabHeader from "./TabHeader";
import { Row, Col } from "../Layout";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { Fa } from "composants/Icon";

interface ConfStatus {
  position: number;
  code: string;
  title: string;
  icon: string;
  color: string;
}

export interface Configuration {
  id: number;
  titre: string;
  status: string;
  dateGeneration: string;
  articleCode: string;
  articleDesignation: string;
  articleId: string; // utiliser pour la navigation
  clientCode: string;
  clientDesignation: string;
  userName: string;
  userLastName: string;
}

interface TabConfigurateurState {
  configurations: Configuration[];
}

class TabConfigurateur extends Component<TabSatelliteProps, TabConfigurateurState> {
  state: TabConfigurateurState = {
    configurations: []
  };

  componentDidMount() {
    if (this.props.contextId) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps: TabSatelliteProps) {
    if (prevProps.contextId !== this.props.contextId && this.props.contextId) {
      this.refresh();
    }
  }

  refresh() {
    fetchConfigurateurData(this.props.tableName, this.props.contextId as string)
      .then(response => {
        this.setState({ configurations: response.data });
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    this.props.countAction(this.props.tableName, this.props.contextId);
  }

  render() {
    return (
      <>
        <TabHeader
          i18nKey="commun_configurateurs_liees"
          count={this.props.count}
          tableName={this.props.tableName}
          contextId={this.props.contextId}
          sjmoCode={this.props.sjmoCode}
        />
        <Scrollbars autoHide style={{ height: this.props.height }}>
          <Row style={{ width: "100%" }}>
            <Col span={6}>{this.buildListConfiguration(true)}</Col>
            <Col span={6}>{this.buildListConfiguration(false)}</Col>
          </Row>
        </Scrollbars>
      </>
    );
  }

  bildOneConfig = (config: Configuration) => {
    let articleGenere;
    if (config.articleCode && config.articleCode !== null) {
      articleGenere = (
        <>
          <div>{`${t("commun_article_genere")} : `}</div>
          <ProcessusProvider
            sjmoCode={this.props.sjmoCode}
            tableName="article"
            selected={config.articleId}
          >
            <ProcessusMenu isAnchor isRight={true} color="link">
              {`${config.articleDesignation} (${config.articleCode})`}
            </ProcessusMenu>
          </ProcessusProvider>
        </>
      );
    } else {
      articleGenere = (
        <div style={{ marginBottom: "1.85rem" }}>{t("commun_aucun_article_genere")}</div>
      );
    }

    return (
      <div className="box donnees-satellite-container" key={config.id}>
        <article className="media">
          <div className="media-content">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <span className="subtitle is-5">{config.titre}</span>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <span className={`tag ${config.status === "GEN" ? "is-success" : "is-warning"}`}>
                    {config.status}
                  </span>
                </div>
                <div className="level-item">
                  <Button className="is-size-4">
                    <span className="icon">
                      <Fa icon="sign-out" />
                    </span>
                  </Button>
                </div>
              </div>
            </nav>

            <div>
              <span>
                {`${t("commun_par")} : 
              ${config.userName !== null ? config.userName : ""} 
              ${config.userLastName !== null ? config.userLastName : ""}
             ${config.dateGeneration ? ", " + t("commun_le") + " : " + config.dateGeneration : ""}
             ${
               config.clientDesignation !== null
                 ? ", " +
                   t("commun_pour") +
                   " : " +
                   config.clientDesignation +
                   "(" +
                   config.clientCode +
                   ")"
                 : ""
             }`}
              </span>
            </div>

            <h2 className="is-size-5">{articleGenere}</h2>
          </div>
        </article>
      </div>
    );
  };

  buildListConfiguration = (isLeft: boolean) => {
    return this.state.configurations
      .filter(configuration => {
        const even = isLeft ? 0 : 1;
        return this.state.configurations.indexOf(configuration) % 2 === even;
      })
      .map(config => {
        return this.bildOneConfig(config);
      });
  };
}

export default TabConfigurateur;
