import React, { Component } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import Scrollbars from "react-custom-scrollbars";
import { fetchArticulationData } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import ProcessusMenu from "../processus/ProcessusMenu";
import TabHeader from "./TabHeader";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";

export interface Articulation {
  id: number;
  table: string;
  tableLibelle: string;
  entityId: string;
  title: string;
  values: string[];
  commentaire: string;
}

interface TabArticulaionState {
  articulations: Articulation[];
}

class TabArticulation extends Component<TabSatelliteProps, TabArticulaionState> {
  state: TabArticulaionState = { articulations: [] };

  componentDidMount() {
    if (this.props.contextId) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps: TabSatelliteProps) {
    if (prevProps.contextId !== this.props.contextId && this.props.contextId) {
      this.refresh();
    }
  }

  refresh() {
    fetchArticulationData(this.props.tableName, this.props.contextId as string)
      .then(response => {
        this.setState({ articulations: response.data });
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
    this.props.countAction(this.props.tableName, this.props.contextId);
  }

  buildOne(articulation: Articulation) {
    return (
      <div className="box donnees-satellite-container" key={articulation.id}>
        <article className="media">
          <div className="media-content">
            <div>
              <ProcessusProvider
                sjmoCode={this.props.sjmoCode}
                tableName={articulation.table}
                selected={articulation.entityId}
              >
                <ProcessusMenu isAnchor isRight={false} color="link">
                  {articulation.tableLibelle}, ({articulation.values})
                </ProcessusMenu>
              </ProcessusProvider>
            </div>
            <div>{articulation.title}</div>
            <div>{articulation.commentaire}</div>
          </div>
        </article>
      </div>
    );
  }

  render() {
    const list = this.state.articulations.map(articulation => {
      return this.buildOne(articulation);
    });

    return (
      <>
        <TabHeader
          i18nKey="commun_articulations_liees"
          count={this.props.count}
          tableName={this.props.tableName}
          contextId={this.props.contextId}
          sjmoCode={this.props.sjmoCode}
        />
        <Scrollbars autoHide style={{ height: this.props.height }}>
          {list}
        </Scrollbars>
      </>
    );
  }
}

export default TabArticulation;
