import React, { SFC } from "react";
import { ModalTemplate } from "./template";
import { RouteComponentProps } from "react-router-dom";

export const ValidateFlux: SFC<{
  sjmoCode: string;
  template: string;
  kanbanId?: string;
  processId?: string;
  entityId: string;
} & RouteComponentProps<{}>> = props => {
  const SelectedComponent = ModalTemplate[props.template];
  return (
    <SelectedComponent
      sjmoCode={props.sjmoCode}
      kanbanDefinitionId={props.kanbanId}
      processId={props.processId}
      id={props.entityId}
    />
  );
};
