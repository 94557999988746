import { AxiosPromise } from "axios";

import { api, apiAdn, getUIContext } from "./common";
import { FocusState } from "types/Focus";
import { ComponentGroupState } from "types/ComponentGroup";
import { Pojo } from "types/Galaxy";

// Fonction qui récupère la liste des focus d'un mini expert de galaxie
export function getCreatorFocus(
  tableName: string,
  sjmoCode: string,
  focusId: string | null
): AxiosPromise<FocusState[]> {
  const urlParams = getUIContext({ sjmoCode });
  urlParams.append("tableName", tableName);
  focusId && urlParams.append("preferId", focusId);
  return apiAdn.get(`/creator/focus?${urlParams}`);
}

// Fonction qui récupère la liste des focus d'un mini expert de galaxie
export function getAllCreatorFocus(
  tableName: string,
  sjmoCode: string
): AxiosPromise<FocusState[]> {
  const urlParams = getUIContext({ sjmoCode });
  urlParams.append("tableName", tableName);
  return apiAdn.get(`/creator/allFocus?${urlParams}`);
}

// Fonction qui récupère les groupes de composants d'un focus de mini-expert
export function getCreatorGroups(
  sjmoCode: string,
  id: string
): AxiosPromise<ComponentGroupState[]> {
  const urlParams = getUIContext({ sjmoCode });
  return apiAdn.get(`/creator/${id}/groups?${urlParams}`);
}

// Fonction qui initialise les données d'un créator à partir d'une entité contextuelle
// Inclus également le preRecord
export function initCreatorFromContext(
  sjetId: string,
  sjmoCode: string,
  contextTable: string,
  contextId: string,
  contextualValues?: { [key: string]: any } | null
): AxiosPromise<Pojo> {
  const urlParams = getUIContext({ sjmoCode });
  if (contextualValues && contextualValues != null) {
    const keys = Object.keys(contextualValues);
    for (let key of keys) {
      urlParams.append(key, contextualValues[key]);
    }
  }

  return api.get(
    `/creator/${sjetId}/initValue?${urlParams}&contextTable=${contextTable}&contextId=${contextId}`
  );
}
/**
 * Fonction qui renvoit les informations nécéssaires à une navigation après création.
 *
 * Envoi : sjmoCode, selectedFocus
 * Retour :
 *  - Si une navigation existe, le code et la table correspondent au paramétrage
 *  - Sinon, le sjmoCode provenant de l'url et sa table correspondante
 *
 * @export
 * @param {string} sjmoCode
 * @param {number} sjefId
 * @returns {AxiosPromise<{
 *   navigationSjmoCode: string;
 *   navigationTableName: string;
 * }>}
 */
export function getNavigationInformations(
  sjmoCode: string,
  sjefId: number
): AxiosPromise<{
  navigationSjmoCode: string;
  navigationTableName: string;
}> {
  const urlParams = getUIContext({ sjmoCode });
  return apiAdn.get(`/creator/${sjefId}/navigation?${urlParams}`);
}
