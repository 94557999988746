import {
  put,
  takeEvery,
  delay,
  call,
  race,
  take,
  fork,
  all,
  putResolve,
  select
} from "redux-saga/effects";
import { HandleApiMessageAction } from "actions/messages";
import { HANDLE_API_MESSAGE } from "constant/common";
// import { MessageType } from "types/Message";

import setupStomp, { getSocket } from "socket";
import { Client } from "webstomp-client";
import { eventChannel, END, EventChannel } from "redux-saga";

import { ActionTypeData } from "reducers/Action";
import { Notification, NotificationGroup } from "types/Notification";
import { pushNotification, fetchListNotificationSuccess } from "actions/notifications.action";

import toaster from "composants/toaster/toaster";
import {
  NOTIFICATION_PRIORITY_VALUE,
  NOTIFICATION_OVERLAY_LIMIT
} from "reducers/modules/NotificationReducer";
import { uuidv4 } from "utils/uuid.utils";
import { format } from "date-fns";
import { getUnreadNotification, getReadNotification } from "api/notification";
import { STARTUP_NOTIFICATION } from "constant/notification.constant";
import { AxiosResponse } from "axios";
import { selectIsNotificationPresent } from "selectors/notification.selector";

// const autoCloseType: MessageType[] = ["SUCCESS", "INFO"];

type AllSocketAction = ActionTypeData<"SOCKET_MESSAGE", Notification>;

const DELAY_30_SEC = 30 * 1000;
const BATCH_SIZE = 3;

function* watchHandeApiMessage(action: HandleApiMessageAction) {
  const message = action.payload;

  yield window.requestIdleCallback(() => {
    if (message.message) {
      toaster.notify({
        id: message.code ?? uuidv4(),
        group: NotificationGroup.DEFAULT,
        title: message.message,
        priority: message.type === "DANGER" ? "HIGH" : "NORMAL",
        intent: message.type,
        createdAt: format(Date.now())
      });

      if (message.stackTrace) {
        console.groupCollapsed(message.message || message.code);
        console.table(message.stackTrace);
        console.groupEnd();
      }
    }
  });
}

function setupSocket(stompClient: Client, socket: WebSocket) {
  return eventChannel(emit => {
    stompClient.subscribe("/user/queue/messages", message => {
      emit({ type: "SOCKET_MESSAGE", payload: JSON.parse(message.body) });
    });

    stompClient.subscribe("/topic/messages", message => {
      emit({ type: "SOCKET_MESSAGE", payload: JSON.parse(message.body) });
    });

    // si on perd la socket, on ferme la connexion.
    socket.onerror = () => {
      emit(END);
    };

    socket.onclose = () => {
      emit(END);
    };

    return () => {
      stompClient.disconnect();
      socket.close();
    };
  });
}

function* getChannel() {
  let socket = getSocket();
  if (!socket) {
    return null;
  }
  let stompClient: Client | undefined = yield call(setupStomp, socket);

  if (!stompClient) {
    return null;
  }

  return setupSocket(stompClient, socket);
}

function* watchMessage() {
  // on ne démarre la gestion des sockets que à partir du moment où on a déjà récupéré les données initiales
  yield take(STARTUP_NOTIFICATION);

  // à vérifier sur la façon dont celà réagit à la connexion à notre socket
  let socketChannel: EventChannel<{}> | null = null;
  while (true) {
    try {
      const [unread, readed]: AxiosResponse<Notification[]>[] = yield all([
        call(getUnreadNotification),
        call(getReadNotification, 0, 50)
      ]);

      yield putResolve(fetchListNotificationSuccess([...unread.data, ...readed.data]));

      socketChannel = yield call(getChannel);

      while (true) {
        if (!socketChannel) {
          // on a pas de connexion, on lance alors une recherche manuelle des dernières notifications
          // on retente aussi une connexion pour la prochaine tentative
          // puis on attend 30sec si on a toujours pas de connexion.
          const unread: AxiosResponse<Notification[]> = yield call(getUnreadNotification);

          for (let notif of unread.data) {
            const isPresent = yield select(selectIsNotificationPresent, notif.id);
            yield call(handleNotification, notif, !isPresent);
          }

          yield delay(DELAY_30_SEC);
          socketChannel = yield call(getChannel);
          // si on a pas de connexion, pas besoin de gérer les messages du channel
          if (!socketChannel) continue;
        }

        try {
          yield call(handleChannelSocket, socketChannel);
        } finally {
          // on passe dans le finally si la socket est fermé pour
          // - une errreur
          // - une perte de connexion
          // dans le ce cas là, on passe alors par un appel GET
          // avec un delai toutes les 30 secondes
          socketChannel = null;
        }
      }
    } catch (e) {
      console.error("cannot initialize the notifications feature");
      console.error(e);

      if (socketChannel) {
        socketChannel.close();
      }
      // délai de 5 secondes avant de réinitialiser la socket
      yield delay(5 * 1000);
    }
  }
}

function* handleChannelSocket(socketChannel: EventChannel<{}>) {
  while (true) {
    // on attend un message de la socket pendant 30 sec.
    // si aucune notification n'arrive, on vérifie que ce n'est pas une erreur en lancant
    // un appel au service rest pour récupérer les notifications non-lus.
    // *race* s'arrête dès que l'une des deux actions est terminée
    // delay retourne `true` s'il arrive à la fin du délai
    const [message, delayed]: [any, boolean] = yield race([
      take(socketChannel),
      delay(DELAY_30_SEC)
    ]);

    if (delayed) {
      const unread: AxiosResponse<Notification[]> = yield call(getUnreadNotification);

      const size = unread.data.length;

      let batch = BATCH_SIZE;
      for (let notif of unread.data) {
        const isPresent = yield select(selectIsNotificationPresent, notif.id);
        yield call(
          handleNotification,
          notif,
          isPresent ? false : notif.validatedAt == null || notif.validatedAt === undefined
        );
        // on évite de spammer l'écran de notification
        if (size > BATCH_SIZE && batch === 0) {
          batch = BATCH_SIZE;
          yield delay(2000);
        }
        batch--;
      }
    } else {
      yield call(handleNotification, message.payload);
    }
  }
}

function* handleNotification(message: Notification, withOverlay: boolean = true) {
  const value = NOTIFICATION_PRIORITY_VALUE[message.priority];
  if (withOverlay && value >= NOTIFICATION_OVERLAY_LIMIT && message.validatedBy == null) {
    toaster.notify(message);
  }

  yield put(pushNotification(message));
}

export default [takeEvery(HANDLE_API_MESSAGE, watchHandeApiMessage), fork(watchMessage)];
