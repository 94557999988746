import React, { Component, FC, useContext, useEffect } from "react";
import { Omit } from "types/utils";

/**
 * Cette interface doit être implémenté par
 * les composants qui doivent être forcé en refresh
 *
 */
export interface GalaxieListenerComponent {
  refresh(): void;
}

export interface GalaxieListenerContextProps {
  register(instance: GalaxieListenerComponent): () => void;
}
export type GalaxieListenerCallbackContextProps = (instance: () => void) => () => void;

function noop() {
  /* nothing to do */
  return () => {
    /* empty too */
  };
}

export const GalaxieListenerContext = React.createContext<GalaxieListenerContextProps>({
  register: noop
});

export const GalaxieDatatableContext = React.createContext<GalaxieListenerContextProps>({
  register: noop
});

export const GalaxieListenerCallbackContext = React.createContext<
  GalaxieListenerCallbackContextProps
>(noop);

export function useGalaxieCallbackListener(callback: () => void) {
  const register = useContext(GalaxieListenerCallbackContext);
  useEffect(() => {
    const unregister = register(callback);
    return () => {
      unregister();
    };
  }, [callback, register]);
}

export function withGalaxieRegister<P extends Object>(
  WrappedComponent: React.ComponentType<P & GalaxieListenerContextProps>
) {
  if (WrappedComponent === undefined || WrappedComponent === null) {
    throw new Error("cannot use withGalaxieRegister with an undefined or null component");
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  const displayName = `withGalaxieRegister(${wrappedComponentName})`;

  const GalaxieRegisterComponent: FC<JSX.LibraryManagedAttributes<
    P,
    Omit<P & GalaxieListenerContextProps, "register">
  >> = props => {
    const { register } = useContext(GalaxieListenerContext);
    return <WrappedComponent {...(props as any)} register={register} />;
  };
  GalaxieRegisterComponent.displayName = displayName;

  return GalaxieRegisterComponent;
}

export function withGalaxieDatatableRegister<P extends Object>(
  WrappedComponent: React.ComponentType<P & GalaxieListenerContextProps>
) {
  if (WrappedComponent === undefined || WrappedComponent === null) {
    throw new Error("cannot use withGalaxieDatatableRegister with an undefined or null component");
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  const displayName = `withGalaxieDatatableRegister(${wrappedComponentName})`;

  const GalaxieDatatableRegisterComponent: FC<P> = props => {
    const { register } = useContext(GalaxieDatatableContext);

    return <WrappedComponent {...props} register={register} />;
  };

  GalaxieDatatableRegisterComponent.displayName = displayName;

  return GalaxieDatatableRegisterComponent;
}
