import { createBrowserHistory } from "history";
import { PUBLIC_URL } from "customGlobal";

/**
 * permet de gérer les URL et paramètre de l'application
 * PUBLIC_URL permet de définir l'url à laquelle est déployé l'application
 *
 * Ainsi, si PUBLIC_URL vaut "/suivi-production".
 *
 * Un Link de react-router-dom vers "/" renverra vers "/suivi-production/".
 * PUBLIC_URL permet également à create-react-app de packager correctement
 * l'application pour le déploiement (récupération CSS, JS).
 */
export default createBrowserHistory({ basename: PUBLIC_URL() });
