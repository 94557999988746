import React, { Component } from "react";
import { AxiosError } from "axios";
import Scrollbars from "react-custom-scrollbars";
import classNames from "classnames";

import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import { Button } from "../button";
import { fetchCommentaireData, fetchDefaultComment, saveComment, deleteCommentaire } from "api";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import { Pojo } from "types/Galaxy";
import TabHeader from "./TabHeader";
import { Dropdown, DropdownButton, DropdownMenu } from "../DropDown/Dropdown";
import EditeurCommentaire from "./EditeurCommentaire";
import DupplicationCommentaire from "./DupplicationCommentaire";

import "./satelliteTab.css";
import "flag-icon-css/css/flag-icon.css";
import { Fa } from "composants/Icon";

export interface Langue extends Pojo {
  langCode: string;
  langLibelle: string;
}

export interface CommentaireType extends Pojo {
  cotyCode: string;
  cotyLibelle: string;
}

export interface Commentaire extends Pojo {
  langue: string;
  langueLibelle: string;
  type: string;
  typeLibelle: string;
  texte: string;
  dtSaisie: Date;
}

interface TabCommentaireState {
  commentaires: Commentaire[];
  isEditing: boolean;
  isDuplicating: boolean;
  currentText: string;
  forceUpdate: boolean;
  currentType?: string;
  currentLang?: string;
  defaultComment?: Commentaire;
  selectedComment?: Commentaire;
}

class TabCommentaire extends Component<TabSatelliteProps, TabCommentaireState> {
  state: TabCommentaireState = {
    commentaires: [],
    isEditing: false,
    isDuplicating: false,
    currentText: "",
    currentLang: undefined,
    currentType: undefined,
    defaultComment: undefined,
    selectedComment: undefined,
    forceUpdate: false
  };

  private isSaving = false;

  componentDidMount() {
    if (this.props.contextId) {
      this.refresh();
      this.fetchDefaultValues();
    }
  }

  componentDidUpdate(prevProps: TabSatelliteProps) {
    if (prevProps.contextId !== this.props.contextId && this.props.contextId) {
      this.refresh();
      this.fetchDefaultValues();
    }
  }

  refresh() {
    this.props.countAction(this.props.tableName, this.props.contextId);

    // Récupération des commentaires en tant que tel
    fetchCommentaireData(this.props.tableName, this.props.contextId as string)
      .then(response => {
        this.setState({ commentaires: response.data });
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    if (this.state.isEditing) {
      this.editing();
    }
    if (this.state.isDuplicating) {
      this.duplicating();
    }
  }

  fetchDefaultValues = () => {
    // Initialisation d'un pré-record qui une fois chargé ne change plus, il servira pour toutes les créations
    fetchDefaultComment(this.props.sjmoCode, this.props.tableName, this.props.contextId as string)
      .then(response => {
        this.setState({ defaultComment: response.data });
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };

  /**
   * Gère l'entré et la sortie du mode édition
   *
   * @memberof TabCommentaire
   */
  editing = (selected?: Commentaire) => {
    if (this.state.isEditing) {
      // Sortie du mode édition
      this.setState({
        isEditing: false,
        selectedComment: undefined,
        currentLang: undefined,
        currentType: undefined,
        currentText: ""
      });
    } else if (selected) {
      // Entré du mode édition avec un document sélectionné
      this.setState({
        isEditing: true,
        selectedComment: selected,
        currentLang: selected.langue,
        currentType: selected.type,
        currentText: selected.texte
      });
    } else {
      if (this.state.defaultComment) {
        // Entré du mode édition avec un pré-record
        this.setState({
          isEditing: true,
          currentLang: this.state.defaultComment.langue,
          currentType: this.state.defaultComment.type,
          currentText:
            this.state.defaultComment.texte && this.state.defaultComment.texte != null
              ? this.state.defaultComment.texte
              : ""
        });
      }
    }
  };

  /**
   * Gère l'entré et la sortie du mode dupplication
   *
   * @memberof TabCommentaire
   */
  duplicating = (selected?: Commentaire) => {
    if (this.state.isDuplicating) {
      // Sortie du mode duplication
      this.setState({
        isDuplicating: false,
        selectedComment: undefined,
        currentLang: undefined,
        currentType: undefined,
        currentText: ""
      });
    } else if (selected) {
      // Entré du mode dupplication avec un document sélectionné
      this.setState({
        isDuplicating: true,
        selectedComment: selected,
        currentLang: selected.langue,
        currentType: selected.type,
        currentText: selected.texte
      });
    } else if (this.state.defaultComment) {
      // Entré du mode dupplication avec un pré-record
      this.setState({
        isDuplicating: true,
        currentLang: this.state.defaultComment.langue,
        currentType: this.state.defaultComment.type,
        currentText:
          this.state.defaultComment.texte && this.state.defaultComment.texte != null
            ? this.state.defaultComment.texte
            : ""
      });
    }
  };

  // Concatenation du texte actuel avec le texte source selectionné séparé par un saut de la ligne
  transfert = (sourceText: string) => {
    // Copie du state, pour ne pas le modifier en sneaky sans le vouloir.
    // Ajout d'un saut de ligne pour séparer (sauf premier) pour séparer les textes ajoutés
    let currentText = `${this.state.currentText}`;

    // Gestion des cas où sourceText à été enregistré en HTML ou pas
    let updatedSourceText = `<div>${sourceText}</div>`;

    currentText += updatedSourceText;

    this.setState({
      currentText: currentText,
      forceUpdate: true
    });
  };

  save = () => {
    if (this.isSaving) return;

    this.isSaving = true;

    const texte = this.state.currentText;
    saveComment({
      sjmoCode: this.props.sjmoCode,
      tableName: this.props.tableName,
      id: this.props.contextId as string,
      langue: this.state.currentLang as string,
      type: this.state.currentType as string,
      date: this.state.defaultComment ? this.state.defaultComment.dtSaisie : null,
      commentaireId: this.state.selectedComment ? this.state.selectedComment.id : null,
      texte: texte
    })
      .then(() => {
        this.isSaving = false;
        if (this.state.isEditing) {
          this.editing();
        } else if (this.state.isDuplicating) {
          this.duplicating();
        }
        this.refresh();
      })
      .catch(e => {
        this.isSaving = false;
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };

  delete = (id: string) => {
    return deleteCommentaire(this.props.sjmoCode, id)
      .then(() => {
        this.refresh();
      })
      .catch(e => {
        const er = e as AxiosError;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };

  render() {
    let body = <div />;

    if (this.state.isEditing) {
      body = (
        <EditeurCommentaire
          sjmoCode={this.props.sjmoCode}
          currentLang={this.state.currentLang as string}
          currentType={this.state.currentType as string}
          currentText={this.state.currentText}
          selectedComment={this.state.selectedComment}
          editing={this.editing}
          save={this.save}
          onChangeLang={(currentLang?: string) => {
            this.setState({ currentLang });
          }}
          onChangeType={(currentType?: string) => {
            this.setState({ currentType });
          }}
          onChangeText={(currentText: string) => {
            this.setState({ currentText, forceUpdate: false });
          }}
          style={{ height: "35vh", overflow: "auto" }}
        />
      );
    } else if (this.state.isDuplicating) {
      body = (
        <DupplicationCommentaire
          sjmoCode={this.props.sjmoCode}
          tableName={this.props.tableName}
          contextId={this.props.contextId as string}
          currentLang={this.state.currentLang as string}
          currentType={this.state.currentType as string}
          currentText={this.state.currentText}
          selectedComment={this.state.selectedComment}
          save={this.save}
          onChangeLang={(currentLang: string) => {
            this.setState({ currentLang });
          }}
          onChangeType={(currentType: string) => {
            this.setState({ currentType });
          }}
          onChangeText={(currentText: any) => {
            this.setState({ currentText, forceUpdate: false });
          }}
          duplicating={this.duplicating}
          transfert={this.transfert}
          forceUpdate={this.state.forceUpdate}
        />
      );
    } else {
      body = this.buildCommentList();
    }

    return (
      <>
        <TabHeader
          i18nKey="commun_commentaires_liees"
          count={this.props.count}
          tableName={this.props.tableName}
          contextId={this.props.contextId}
          sjmoCode={this.props.sjmoCode}
        >
          <Button
            className={
              this.state.isDuplicating
                ? "no-underline is-size-5 has-text-grey-light cursor-force-default"
                : "no-underline is-size-5"
            }
            onClick={() => {
              if (!this.state.isDuplicating) {
                this.editing();
              }
            }}
          >
            <span className="icon">
              <Fa icon="plus" />
            </span>
          </Button>
          <Button
            className={
              this.state.isEditing
                ? "no-underline is-size-5 has-text-grey-light cursor-force-default"
                : "no-underline is-size-5"
            }
            onClick={() => {
              if (!this.state.isEditing) {
                this.duplicating();
              }
            }}
          >
            <span className="icon">
              <Fa icon="copy" />
            </span>
          </Button>
        </TabHeader>

        <Scrollbars autoHide style={{ height: this.props.height }}>
          {body}
        </Scrollbars>
      </>
    );
  }

  buildCommentList = () => {
    const listComm = this.state.commentaires.map((comm: Commentaire) => {
      // Mapping particulier dans le cas des commentaires en anglais
      // Il existe de nombreux pays qui parlent anglais il n'y a donc pas de drapeau pour la valeur "en"
      // On lui envoi donc "gb" à la place.
      let lang = comm.langueLibelle;
      if ("en" === comm.langueLibelle) {
        lang = "gb";
      }

      return (
        <article
          className={classNames(
            "message donnees-satellite-container",
            comm.type.includes("ALERT") ? "is-danger" : "is-info"
          )}
          key={comm.id}
        >
          <div className="message-body has-text-black">
            <div className="level commentaire-header">
              <p className="has-margin-bot">
                <span className={`flag-icon flag-icon-${lang}`} /> {comm.typeLibelle}
              </p>
              <span>
                <Button className="no-underline is-size-5" onClick={() => this.editing(comm)}>
                  <span className="icon">
                    <Fa icon="pencil" />
                  </span>
                </Button>
                <Dropdown autoclose className="is-right">
                  <DropdownButton
                    render={param => (
                      <Button
                        ref={param.buttonRef}
                        className="no-underline is-size-5"
                        onClick={param.onOpen}
                      >
                        <span className="icon">
                          <Fa icon="trash" />
                        </span>
                      </Button>
                    )}
                  />
                  <DropdownMenu
                    isRight
                    render={param => (
                      <div className="dropdown-item">
                        <p>{t("commun_sur_de_suprimer")}</p>
                        <button
                          className="button is-danger is-fullwidth is-small"
                          onClick={() => {
                            this.delete(comm.id).then(param.onClose);
                          }}
                        >
                          {t("commun_oui")}
                        </button>
                      </div>
                    )}
                  />
                </Dropdown>
                <Button className="no-underline is-size-5" onClick={() => this.duplicating(comm)}>
                  <span className="icon">
                    <Fa icon="file-import" />
                  </span>
                </Button>
              </span>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: comm.texte }} />
          </div>
        </article>
      );
    });

    return <>{listComm}</>;
  };
}

export default TabCommentaire;
