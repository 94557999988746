import React, { Component, SFC } from "react";
import Input, { InputAllProps, InputNumber, InputMask, InputMaskAllProps } from "./Input";
import { Field } from "../form";
import { ComposantAndLabelProps } from "../common";
import { withLabel } from "composants/withLabel";

export const InputTextAndLabel = withLabel<InputAllProps>()(Input);

export const InputNumberAndLabel = withLabel<InputAllProps>()(InputNumber);

export const InputMaskAndLabel = withLabel<InputMaskAllProps>()(InputMask);

export default InputTextAndLabel;
