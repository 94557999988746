import { ReducerState } from "reducers/index";
import { createSelector } from "reselect";

const selectUnreadNotificationById = (root: ReducerState, id: string) => {
  return root.notifications.notifications.filter(
    notif => (notif.validatedAt === null || notif.validatedAt === undefined) && notif.id === id
  );
};

export const selectIsNotificationPresent = createSelector(
  selectUnreadNotificationById,
  unreadById => unreadById.length === 1
);
