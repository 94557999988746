export const DISPLAY_EMAIL = "DISPLAY_EMAIL";
export const LOAD_DISPLAY_MAIL = "LOAD_DISPLAY_MAIL";
export const DISPLAY_EMAIL_SUCCESS = "DISPLAY_EMAIL_SUCCESS";
export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SET_EMAIL_STRUCT = "SET_EMAIL_STRUCT";
export const CLOSE_EMAIL = "CLOSE_EMAIL";
export const ADD_DESTINATAIRE = "ADD_DESTINATAIRE";
export const ON_SUBJECT_CHANGE = "ON_SUBJECT_CHANGE";
export const ON_BODY_CHANGE = "ON_BODY_CHANGE";
export const CLEAR_EMAIL_REDUCER = "CLEAR_EMAIL_REDUCER";
export const START_EMAIL_LOADER = "START_EMAIL_LOADER";
export const STOP_EMAIL_LOADER = "STOP_EMAIL_LOADER";
export const ADD_ADDITIONNAL_ATTACHMENT = "ADD_ADDITIONNAL_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const DELETE_ADDTIONNAL_ATTACHMENT = "DELETE_ADDTIONNAL_ATTACHMENT";
export const DELETE_ADDTIONNAL_ATTACHMENT_SUCCESS = "DELETE_ADDTIONNAL_ATTACHMENT_SUCCESS";
export const SET_MAIL_INDEX = "SET_MAIL_INDEX";
export const REMOVE_SENDED_EMAIL = "REMOVE_SENDED_EMAIL";

export const LOAD_MAIL_TEMPLATE = "LOAD_MAIL_TEMPLATE";
