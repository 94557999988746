import React, { useLayoutEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ToastManager from "./ToastManager";
import { Notification, NotificationGroup, NotificationIntent } from "types/Notification";
import { uuidv4 } from "utils/uuid.utils";

class Toaster {
  private notifyHandler?: (notification: Notification) => void;
  private closeAllHandler: () => void;

  __bindNotify = (handler: any) => {
    this.notifyHandler = handler;
  };

  __bindCloseAll = (handler: any) => {
    this.closeAllHandler = handler;
  };

  public success = (title: string) => {
    this.__notifySimple(title, "SUCCESS");
  };

  public error = (title: string) => {
    this.__notifySimple(title, "DANGER");
  };

  public info = (title: string) => {
    this.__notifySimple(title, "INFO");
  };

  private __notifySimple = (title: string, intent: NotificationIntent) => {
    this.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      intent: intent,
      priority: "NORMAL",
      title: title,
      createdAt: new Date().toISOString()
    });
  };

  public notify = (notification: Notification) => {
    if (this.notifyHandler) {
      this.notifyHandler(notification);
    } else {
      console.error(
        "you need to setup <ToasterProvider> at the top of the tree before using the toast"
      );
    }
  };

  public closeAll = () => {
    if (this.closeAllHandler) {
      this.closeAllHandler();
    } else {
      console.error(
        "you need to setup <ToasterProvider> at the top of the tree before using the toast"
      );
    }
  };
}

const toaster = new Toaster();
if (process.env.NODE_ENV === "development") {
  window["toaster"] = toaster;
}

export function ToastProvider({ children }: React.PropsWithChildren<{}>) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  function getContainerRef() {
    if (containerRef.current == null) {
      containerRef.current = document.createElement("div");
      containerRef.current.setAttribute("data-octal-toaster-container", "");
      document.body.append(containerRef.current);
    }
    return containerRef.current;
  }

  useLayoutEffect(() => {
    let container = getContainerRef();

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  const container = getContainerRef();

  const portalNode = ReactDOM.createPortal(
    <ToastManager bindNotify={toaster.__bindNotify} bindCloseAll={toaster.__bindCloseAll} />,
    container
  );

  return (
    <>
      {children}
      {portalNode}
    </>
  );
}

export default toaster;
