// Composant commun au mini-expert, au creator et a l'expert.
// Ce composant contient
// Un bouton de savegarde
// Un Bouton de refresh
// une liste de focus
import React, { Component, CSSProperties } from "react";
import { Row, Col } from "composants/Layout";
import FocusMenu from "composants/focus/FocusMenu";
import { FocusState } from "types/Focus";

interface FocusBarProps {
  focuses: FocusState[];
  selectedFocus: string;
  // indique si il y a les options de sauvegarde et de raz de perso dans le menu des focus
  personnalisable: boolean;
  containerStyle?: CSSProperties;
  disabled?: boolean;
}

interface FocusBarPropsFn {
  onFocusChange(focusId: string): void;
}

type FocusBarAllProps = FocusBarProps & FocusBarPropsFn;

class FocusBar extends Component<FocusBarAllProps> {
  public static defaultProps: Partial<FocusBarAllProps> = {
    disabled: false
  };

  render() {
    const { focuses, selectedFocus } = this.props;
    return (
      <Row style={this.props.containerStyle}>
        <Col>
          <div>{this.props.children}</div>
        </Col>
        <Col className="is-narrow">
          <div className="is-pulled-right">
            <FocusMenu
              focuses={focuses}
              currentFocus={selectedFocus}
              onFocusChange={this.props.onFocusChange}
              personnalisable={false}
              disabled={this.props.disabled}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default FocusBar;
