import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

import { Route, RouteComponentProps, matchPath, Switch } from "react-router-dom";

import Modal from "composants/Modal/Modal";
import ContextInfo from "composants/rightClick/ContextInfo";

import Galaxie from "containers/galaxy/Galaxie";
import Creator from "containers/creator/Creator";
import Lov from "composants/lov/Lov";

import { getLovParamsFromURL, removeLovParamsFromURL } from "utils/navigation.utils";
import KanbanPage from "pages/KanbanPage";
import SatellitesData from "containers/satellites/SatellitesData";
import Mail from "composants/email/Mail";
import { t } from "utils/i18n";
import MiniExpert from "containers/miniexpert/MiniExpert";
import { Trans } from "react-i18next";
import { SuperUserLink } from "composants/userSettings/SuperUser";
import { AppGalaxyInfo, Pojo } from "types/Galaxy";
import { SchedulerPage } from "pages/SchedulerPage";
import { ValidateFlux } from "composants/kanban/ValidateFlux";
import PageNotFound from "pages/PageNotFound";
import ParamAvanceDialog from "composants/processus/ParamAvanceDialog";
import { ProcessusDefinitionNature } from "types/Processus";
import { memoize } from "lodash-es";
import { ON_CLOSE_CREATOR_CALLBACK } from "constant/creator";
import { ON_CLOSE_MINIEXPERT_CALLBACK } from "constant/miniexpert";

const renderGalaxy = (sjmoCode: string) => (props: RouteComponentProps<any>) => {
  return <Galaxie sjmoCode={sjmoCode} {...props} />;
};

const renderHome = (props: RouteComponentProps<any>) => (
  <>
    <Helmet titleTemplate="%s">
      <title>{t("commun_accueil")}</title>
    </Helmet>
    <Galaxie sjmoCode="HOME" {...props} />
  </>
);

const renderMiniExpert = (props: RouteComponentProps<any>) => {
  // on récupère le paramètre tableName
  const URLParams = new URLSearchParams(props.location.search);
  const tableName = URLParams.get("miniExpertTableName");
  const entityId = URLParams.get("miniExpertEntityId") as string;
  const sjmoCode = URLParams.get("miniExpertSjmoCode");

  // on affiche le creator que si l'on a le paramètre tableName de présent
  if (tableName) {
    // si on a une origine, on navigue vers l'origine à la fermeture
    // sinon, on retourne en arrière avec `history.goBack()`

    return (
      <Modal
        show
        onClose={() => {
          try {
            // on execute le callback
            window[ON_CLOSE_MINIEXPERT_CALLBACK] && (window as any)[ON_CLOSE_MINIEXPERT_CALLBACK]();
          } catch (e) {
            console.log(e);
          } finally {
            // on vide le callback pour éviter de polluer l'object global
            // avec des fonctions. Cela permet de faire passer le garbage collector
            // pour la fonction callback.
            (window as any)[ON_CLOSE_MINIEXPERT_CALLBACK] = undefined;
          }
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete("miniExpertTableName");
          urlParams.delete("miniExpertEntityId");
          urlParams.delete("miniExpertSjmoCode");
          props.history.push({
            search: urlParams.toString(),
          });
        }}
        title="Modification"
        hideFooter={true}
      >
        {/* <div> */}
        <MiniExpert
          sjmoCode={sjmoCode ? sjmoCode : ""}
          tableName={tableName}
          currentMainEntityId={entityId}
        />
        {/* </div> */}
      </Modal>
    );
  } else {
    return null;
  }
};

const creatorContextualValues = memoize((search) => {
  const urlParams = new URLSearchParams(search);
  let contextualValues: { [key: string]: any } | null = {};
  const managedProperties = [
    "creatorTableName",
    "creatorContextTable",
    "creatorContextId",
    "creatorSjmoCode",
    "navigateOnClose",
    "focusId",
    // on exclut les query params des autres modales également
    // mini-expert
    "miniExpertTableName",
    "miniExpertEntityId",
    "miniExpertSjmoCode",
    // information clic droit
    "contextInfo",
    "contextColumnName",
    "origineTableName",
    "origineTableId",
    "origineId",
  ];

  urlParams.forEach((value, key) => {
    if (managedProperties.indexOf(key) < 0 && contextualValues != null) {
      contextualValues[key] = decodeURIComponent(value);
    }
  });
  if (Object.entries(contextualValues).length === 0) {
    contextualValues = null;
  }
  return contextualValues;
});

export const renderCreator = (props: RouteComponentProps<any>) => {
  // on récupère le paramètre tableName
  const urlParams = new URLSearchParams(props.location.search);
  const tableName = urlParams.get("creatorTableName");
  const contextTable = urlParams.get("creatorContextTable") as string;
  const contextIdTemp = urlParams.get("creatorContextId") as string;
  const sjmoCode = urlParams.get("creatorSjmoCode");
  const navigateOnClose = urlParams.get("navigateOnClose")
    ? urlParams.get("navigateOnClose") === "EXIT"
      ? "EXIT"
      : urlParams.get("navigateOnClose") === "false"
      ? false
      : true
    : true;
  const focusId = urlParams.get("focusId");

  const match = matchPath<{ code: string; id: string }>(window.location.pathname, {
    path: "/page/:code/:id?",
  });

  const contextualValues = creatorContextualValues(props.location.search);
  const galaxyMainEntityId = match ? match.params.id : undefined;

  const contextId =
    contextIdTemp !== undefined && contextIdTemp !== null ? contextIdTemp : undefined;

  const title = (
    <>
      <Trans key={"commun_creation"}>Création</Trans>
      <SuperUserLink url={`/admin/creator/${tableName}`} className="ml-6" />
    </>
  );

  function onClose() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("creatorTableName");
    urlParams.delete("creatorContextTable");
    urlParams.delete("creatorContextId");
    urlParams.delete("creatorSjmoCode");
    urlParams.delete("navigateOnClose");

    try {
      // on execute le callback
      window[ON_CLOSE_CREATOR_CALLBACK] && (window as any)[ON_CLOSE_CREATOR_CALLBACK]();
    } catch (e) {
      console.log(e);
    } finally {
      // on vide le callback pour éviter de polluer l'object global
      // avec des fonctions. Cela permet de faire passer le garbage collector
      // pour la fonction callback.
      (window as any)[ON_CLOSE_CREATOR_CALLBACK] = undefined;
    }

    props.history.push({
      search: urlParams.toString(),
    });
  }

  // on affiche le creator que si l'on a le paramètre tableName de présent
  if (tableName) {
    // si on a une origine, on navigue vers l'origine à la fermeture
    // sinon, on retourne en arrière avec `history.goBack()`
    return (
      <Creator
        {...props}
        sjmoCode={sjmoCode ? sjmoCode : ""}
        galaxyMainEntityId={galaxyMainEntityId}
        mainTableName={tableName}
        contextTableName={contextTable}
        contextId={contextId}
        navigateOnClose={navigateOnClose}
        onClose={onClose}
        contextualValues={contextualValues}
        focusId={focusId}
        title={title}
      />
    );
  } else {
    return null;
  }
};

export const renderFluxModal = (props: RouteComponentProps<any>) => {
  /**
   * exemple d'url :
   * ?kanbanId=8DF7029F012178F0E053560110ACA9DB&template=MODAL_GCO_DEVIS&entityId=D19-00146
   * ?processId=8DF47970D9E34D2FE053560110AC4705&template=MODAL_GCO_DEVIS&entityId=D19-00146
   */
  const URLParams = new URLSearchParams(props.location.search);
  const kanbanId = URLParams.get("kanbanId") as string;
  const processId = URLParams.get("processId") as string;
  const template = URLParams.get("template") as string;
  const entityId = URLParams.get("entityId") as string;

  const match = matchPath<{ code: string; id: string }>(window.location.pathname, {
    path: "/page/:code/:id?",
  });

  if ((kanbanId || processId) && template && entityId && match) {
    const title = (
      <>
        <Trans key={"commun_validation"}>Validation</Trans>
      </>
    );

    const onClose = () => {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("kanbanId");
      urlParams.delete("template");
      urlParams.delete("processId");
      urlParams.delete("entityId");
      props.history.push({
        search: urlParams.toString(),
      });
    };

    return (
      <Modal show onClose={onClose} title={title} hideFooter={true} height="90vh" width="90vw">
        <ValidateFlux
          {...props}
          kanbanId={kanbanId}
          processId={processId}
          template={template}
          sjmoCode={match.params.code}
          entityId={entityId}
        />
      </Modal>
    );
  } else {
    return null;
  }
};

const renderMail = (props: RouteComponentProps<any>) => {
  const params = new URLSearchParams(props.location.search);

  if (params.get("mail") === "true") {
    return <Mail {...props} />;
  } else {
    return null;
  }
};

const renderContextInfo = (props: RouteComponentProps<any>) => {
  const params = new URLSearchParams(props.location.search);

  function deleteSearchContextInfo() {
    params.delete("contextInfo");
    params.delete("contextColumnName");
    params.delete("origineTableName");
    params.delete("origineTableId");
    params.delete("origineId");

    props.history.push({ search: params.toString() });
  }

  if (params.get("contextInfo") === "true") {
    const contextColumnName = params.get("contextColumnName");
    const contextTableName = params.get("origineTableName");
    const origineTableId = params.get("origineTableId");
    const contextTableId = params.get("origineId");
    return (
      <Modal
        show
        onClose={deleteSearchContextInfo}
        onValidate={deleteSearchContextInfo}
        title="Informations"
        minWidth="30vw"
        minHeight="30vh"
        hideFooter={true}
      >
        <ContextInfo
          contextColumnName={contextColumnName}
          contextTableName={contextTableName}
          origineTableId={origineTableId}
          contextTableId={contextTableId}
        />
      </Modal>
    );
  } else {
    return null;
  }
};

export const renderProcessusAvance = (
  props: RouteComponentProps<any, any, { refresh: string; selected: Pojo[] | undefined }>
) => {
  const urlParams = new URLSearchParams(props.location.search);

  if (urlParams.get("process")) {
    const search = urlParams;
    const compositeId = search.get("process") as string;
    const navigationUrl = search.get("navigationUrl");
    const type = search.get("type") as ProcessusDefinitionNature;
    const sjmoCode = search.get("sjmoCode") as string;
    const delay = search.get("delay") as string;
    const label = search.get("processLabel") as string;
    const editionType = search.get("editionType") as "rapide" | "apercu" | undefined;
    const forcedForAll = (search.get("forAll") as string) === "true";
    const refresh = props.location.state.refresh;
    const selected = props.location.state.selected;

    const callback = window[refresh] as () => void;

    return (
      <div>
        <ParamAvanceDialog
          {...props}
          compositeId={compositeId}
          navigationUrl={navigationUrl}
          type={type}
          sjmoCode={sjmoCode}
          delay={delay}
          label={label}
          editionType={editionType}
          forcedForAll={forcedForAll}
          callback={() => {
            if (callback && typeof callback === "function") {
              callback();
              delete window[refresh];
            }
          }}
          selected={selected}
        />
      </div>
    );
  } else {
    return null;
  }
};

const renderSatellite = (props: RouteComponentProps<any>) => {
  const urlParams = new URLSearchParams(props.location.search);
  if (urlParams.get("currentTab")) {
    const contextId = (urlParams.get("satelliteContextId") as string) || undefined;
    const ctrlKey = (urlParams.get("ctrlKey") as string) || undefined;
    const query = (urlParams.get("query") as string) || undefined;
    const columns = (urlParams.get("columns") as string) || undefined;

    return (
      <SatellitesData
        currentTab={urlParams.get("currentTab") as string}
        tableName={urlParams.get("satelliteTableName") as string}
        initContextId={contextId}
        sjmoCode={urlParams.get("satelliteSjmoCode") as string}
        ctrlKey={ctrlKey}
        query={query}
        columns={columns ? columns.split(",") : undefined}
      />
    );
  }
  return null;
};

const renderLov = (props: RouteComponentProps<any>) => {
  // code module + target + selectionUnique permet de savoir d'ou l'on vient :
  // -> lors de la validation, si jamais le path code + target et sélection unique n'est pas bon,
  // on ignore l'ajout.
  const lovParams = getLovParamsFromURL(props.location.search);

  if (lovParams) {
    return (
      <Lov
        // chemin utilisé pour la validation
        sjmoCode={lovParams.sjmoCode}
        targetTableName={lovParams.lovTargetTableName}
        targetCtrlKey={lovParams.lovTargetCtrlKey}
        targetId={lovParams.lovTargetId}
        onClose={() => {
          const urlParams = removeLovParamsFromURL(window.location.search);
          props.history.push({ search: urlParams.toString() });
        }}
        // permet de savoir si on est sur une GS ou DT
        lovType={lovParams.type}
        // information pour charger la LOV
        column={lovParams.column}
        tableName={lovParams.tableName}
        contextTableName={lovParams.contextTableName}
        contextId={lovParams.contextId}
        syjLovId={lovParams.syjLovId}
      />
    );
  }

  return null;
};

const renderKanbanPage = (sjmoCode: string, label: string) => (props: RouteComponentProps<any>) =>
  (
    <>
      <Helmet titleTemplate="%s">
        <title>{label || "Kanban"}</title>
      </Helmet>
      <KanbanPage sjmoCode={sjmoCode} {...props} />
    </>
  );

export const AppRoute: FC<{ galaxies: Record<string, AppGalaxyInfo> }> = ({ galaxies }) => {
  const keys = React.useMemo(() => Object.keys(galaxies), [galaxies]);

  return (
    <>
      <Switch>
        {keys.map((key) => {
          const currentPage = galaxies[key];
          switch (currentPage.type) {
            case "GALAXY":
              return (
                <Route
                  key={currentPage.code}
                  path={currentPage.url}
                  render={renderGalaxy(currentPage.code)}
                />
              );

            case "HOME":
              return (
                <Route key={currentPage.code} path={currentPage.url} exact render={renderHome} />
              );

            case "KANBAN":
              return (
                <Route
                  key={currentPage.code}
                  path={currentPage.url}
                  render={renderKanbanPage(currentPage.code, currentPage.label)}
                />
              );

            case "UNIVERS":
              return (
                <Route
                  key={currentPage.code}
                  path={currentPage.url}
                  render={renderGalaxy(currentPage.code)}
                />
              );

            case "TIMELINE":
              return (
                <Route key={currentPage.code} path={currentPage.url}>
                  <Helmet titleTemplate="%s">
                    <title>{currentPage.label}</title>
                  </Helmet>
                  <SchedulerPage sjmoCode={currentPage.code} type={currentPage.type} />
                </Route>
              );
            case "CALENDAR":
              return (
                <Route key={currentPage.code} path={currentPage.url}>
                  <Helmet titleTemplate="%s">
                    <title>{currentPage.label}</title>
                  </Helmet>
                  <SchedulerPage sjmoCode={currentPage.code} type={currentPage.type} />
                </Route>
              );
            default:
              return null;
          }
        })}

        <Route path="/process" render={renderProcessusAvance} />
        <Route component={PageNotFound} />
      </Switch>
      <Route render={renderCreator} />
      <Route render={renderMiniExpert} />
      <Route render={renderContextInfo} />
      <Route render={renderProcessusAvance} />
      <Route render={renderSatellite} />
      <Route render={renderLov} />
      <Route render={renderMail} />
      <Route render={renderFluxModal} />
    </>
  );
};
