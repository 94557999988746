import React, { HTMLAttributes } from "react";

import {
  GridCellRangeProps,
  Grid,
  List,
  Table,
  GridCellRenderer,
  SizeAndPositionData,
  Map,
  VisibleCellRange
} from "react-virtualized";
import { CSSProperties } from "react";
import { MeasuredCellParent } from "react-virtualized/dist/es/CellMeasurer";
import Row from "../Row";
import { renderRowExpandable, renderBreakRow, renderBreakSubtotal } from "./cellRenderer";

export const MAX_SIZE_EXPAND = 200;
export const MAX_SIZE_BREAK = 30;
export const MAX_SIZE_BREAK_BOTTOM = 30;

/**
 * Default implementation of cellRangeRenderer used by Grid.
 * This renderer supports cell-caching while the user is scrolling.
 */
export default function defaultCellRangeRenderer(
  {
    cellCache,
    cellRenderer,
    columnSizeAndPositionManager,
    columnStartIndex,
    columnStopIndex,
    deferredMeasurementCache,
    horizontalOffsetAdjustment,
    isScrolling,
    parent, // Grid (or List or Table)
    rowSizeAndPositionManager,
    rowStartIndex,
    rowStopIndex,
    styleCache,
    verticalOffsetAdjustment,
    visibleColumnIndices,
    visibleRowIndices,
    scrollLeft
  }: GridCellRangeProps,
  isBreakRow: (rowIndex: number) => boolean,
  breakRowLabel: (rowIndex: number) => string,
  isSubtotal: (rowIndex: number) => boolean,
  breakRowSubtotal: (rowIndex: number) => string,
  isRowExpandable?: (rowIndex: number) => boolean,
  rowExpandableRenderer?: GridCellRenderer
) {
  const renderedCells = [];

  // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
  // User cannot scroll beyond these size limitations.
  // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
  // We should never cache styles for compressed offsets though as this can lead to bugs.
  // See issue #576 for more.
  const areOffsetsAdjusted =
    columnSizeAndPositionManager.areOffsetsAdjusted() ||
    rowSizeAndPositionManager.areOffsetsAdjusted();

  const canCacheStyle = !isScrolling && !areOffsetsAdjusted;

  for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
    let rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

    let isCurrentRowRenderBreakLabel = isBreakRow(rowIndex);
    let isCurrentRowRenderBreakSubtotal = isSubtotal && isSubtotal(rowIndex);

    let columnsRendered: any[] = [];
    for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
      let columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
      let isVisible =
        columnIndex >= visibleColumnIndices.start &&
        columnIndex <= visibleColumnIndices.stop &&
        rowIndex >= visibleRowIndices.start &&
        rowIndex <= visibleRowIndices.stop;
      let key = `${rowIndex}-${columnIndex}`;
      let style: React.CSSProperties;

      // Cache style objects so shallow-compare doesn't re-render unnecessarily.
      if (canCacheStyle && styleCache[key]) {
        style = styleCache[key];
      } else {
        // In deferred mode, cells will be initially rendered before we know their size.
        // Don't interfere with CellMeasurer's measurements by setting an invalid size.
        if (deferredMeasurementCache && !deferredMeasurementCache.has(rowIndex, columnIndex)) {
          // Position not-yet-measured cells at top/left 0,0,
          // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
          // Positioning them further to the right/bottom influences their measured size.
          style = {
            height: "auto",
            left: 0,
            position: "absolute",
            top: 0,
            width: "auto"
          };
        } else {
          let height = rowDatum.size;

          if (isRowExpandable && isRowExpandable(rowIndex)) {
            height -= MAX_SIZE_EXPAND;
          }

          if (isCurrentRowRenderBreakLabel) {
            height -= MAX_SIZE_BREAK;
          }

          if (isCurrentRowRenderBreakSubtotal) {
            height -= MAX_SIZE_BREAK_BOTTOM;
          }

          let top = rowDatum.offset + verticalOffsetAdjustment;
          if (isCurrentRowRenderBreakLabel) {
            top += MAX_SIZE_BREAK;
          }

          style = {
            height: height,
            left: columnDatum.offset + horizontalOffsetAdjustment,
            position: "absolute",
            top,
            width: columnDatum.size
          };

          styleCache[key] = style;
        }
      }

      let cellRendererParams = {
        columnIndex,
        isScrolling,
        isVisible,
        key,
        parent,
        rowIndex,
        style
      };

      let renderedCell;

      // Avoid re-creating cells while scrolling.
      // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
      // If a scroll is in progress- cache and reuse cells.
      // This cache will be thrown away once scrolling completes.
      // However if we are scaling scroll positions and sizes, we should also avoid caching.
      // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
      // For more info refer to issue #395
      if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
        if (!cellCache[key]) {
          cellCache[key] = cellRenderer(cellRendererParams);
        }

        renderedCell = cellCache[key];

        // If the user is no longer scrolling, don't cache cells.
        // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
      } else {
        renderedCell = cellRenderer(cellRendererParams);
      }

      if (renderedCell == null || renderedCell === false) {
        continue;
      }

      if (process.env.NODE_ENV !== "production") {
        warnAboutMissingStyle(parent, renderedCell);
      }

      columnsRendered.push(renderedCell);
      // renderedCells.push(renderedCell);
    }

    let renderedExpand = null;
    // on vérifie qu'un row expand est disponible & ouvert
    if (isRowExpandable && rowExpandableRenderer && isRowExpandable(rowIndex)) {
      renderedExpand = renderRowExpandable({
        parent,
        rowDatum,
        scrollLeft,
        verticalOffsetAdjustment,
        isScrolling,
        rowIndex,
        visibleRowIndices,
        cellCache,
        rowExpandableRenderer,
        horizontalOffsetAdjustment,
        canCacheStyle,
        styleCache
      });
    }

    let breakRow = null;
    if (isCurrentRowRenderBreakLabel) {
      breakRow = renderBreakRow({
        parent,
        rowDatum,
        top: rowDatum.offset + verticalOffsetAdjustment,
        scrollLeft,
        verticalOffsetAdjustment,
        isScrolling,
        rowIndex,
        visibleRowIndices,
        cellCache,
        breakRowLabel,
        horizontalOffsetAdjustment,
        canCacheStyle,
        styleCache
      });
    }

    let breakSubtotal = null;
    if (isCurrentRowRenderBreakSubtotal) {
      breakSubtotal = renderBreakSubtotal({
        parent,
        rowDatum,
        top: rowDatum.offset + verticalOffsetAdjustment + (rowDatum.size - MAX_SIZE_BREAK_BOTTOM),
        scrollLeft,
        verticalOffsetAdjustment,
        isScrolling,
        rowIndex,
        visibleRowIndices,
        cellCache,
        breakRowSubtotal,
        horizontalOffsetAdjustment,
        canCacheStyle,
        styleCache
      });
    }

    let rowKey = `${rowIndex}-row`;

    let rowRendered = (
      <Row key={rowKey}>
        {breakRow}
        {columnsRendered}
        {breakSubtotal}
        {renderedExpand}
      </Row>
    );

    // on wrappe chaque colonne dans une `div` pour nous permettre de facilement dynamiser la hauteur de chaque row
    renderedCells.push(rowRendered);
  }

  return renderedCells;
}

function warnAboutMissingStyle(parent: any, renderedCell: any) {
  if (process.env.NODE_ENV !== "production") {
    if (renderedCell) {
      // If the direct child is a CellMeasurer, then we should check its child
      // See issue #611
      if (renderedCell.type && renderedCell.type.__internalCellMeasurerFlag) {
        renderedCell = renderedCell.props.children;
      }

      if (
        renderedCell &&
        renderedCell.props &&
        renderedCell.props.style === undefined &&
        parent.__warnedAboutMissingStyle !== true
      ) {
        parent.__warnedAboutMissingStyle = true;

        console.warn("Rendered cell should include style property for positioning.");
      }
    }
  }
}
