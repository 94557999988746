import React, { SFC } from "react";

import TextArea from "./TextArea";
import { Field } from "../form";
import { ComposantAndLabelProps } from "../common";

export const TextAreaAndLabel: SFC<ComposantAndLabelProps> = ({
  label,
  labelSize,
  wviMessage,
  required,
  ...restProps
}) => {
  return (
    <Field
      isHorizontal
      label={label}
      help={wviMessage}
      helpState={restProps.wviState}
      required={required}
    >
      <TextArea {...restProps} />
    </Field>
  );
};

export default TextAreaAndLabel;
