import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import history from "customHistory";

/************ REDUCER ************/
import { ReducerState } from "reducers";

/************ ACTIONS *************/
import { hideContextMenu, launchProcessFromRightClick } from "actions/contextMenu";
import { launchNavigation, launchProcessFromGenericList } from "actions/processus";
import { launchEdition } from "actions/processus";

/*********** COMPOSANTS ***********/
import ContextMenu from "containers/contextMenu/ContextMenu";
import { Pojo } from "types/Galaxy";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";

const OctalContextMenu = () => {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const onHideContextMenu = () => {
    dispatch(hideContextMenu());
  };

  const onLaunchProcessFromGenericList = (sjipId: string) => {
    if (reduxProps.sjmoCode) {
      dispatch(
        launchProcessFromGenericList(
          reduxProps.sjmoCode,
          sjipId,
          reduxProps.genericEntity ? [reduxProps.genericEntity] : [],
          reduxProps.columnName
        )
      );
    }
  };

  const onLaunchEditionFromRightClick = (processId: string, mode: string) => {
    if (reduxProps.sjmoCode && reduxProps.contextTableName && reduxProps.contextIds) {
      dispatch(
        launchEdition(
          reduxProps.sjmoCode,
          processId,
          mode,
          reduxProps.contextTableName,
          reduxProps.contextIds
        )
      );
    }
  };

  const onLaunchProcessFromRightClick = (processId: string) => {
    dispatch(launchProcessFromRightClick(processId));
  };

  const onLaunchNavigation = (processId: string, newTab: boolean) => {
    dispatch(launchNavigation(processId, newTab));
  };

  const openMiniExpert = () => {
    if (reduxProps.contextTableName && reduxProps.contextIds) {
      const params = new URLSearchParams();
      params.append("miniExpertTableName", reduxProps.contextTableName);
      params.append("miniExpertSjmoCode", reduxProps.sjmoCode ? reduxProps.sjmoCode : "");
      params.append("miniExpertEntityId", reduxProps.contextIds[0]);
      return history.push({ search: params.toString() });
    }
  };

  const showInformations = () => {
    const params = new URLSearchParams(window.location.search);
    params.append("contextColumnName", reduxProps.columnName || "");
    params.append("origineTableName", reduxProps.origineTableName || "");
    params.append("origineTableId", reduxProps.origineTableId || "");

    params.append("origineId", reduxProps.contextIds?.[0] ?? "");
    params.append("contextInfo", "true");
    return history.push({ search: params.toString() });
  };

  if (reduxProps.show) {
    return (
      <ProcessusProvider
        sjmoCode={reduxProps.sjmoCode ?? "defaultSjmoCode"}
        tableName={reduxProps.contextTableName ?? "default"}
        selected={reduxProps.contextIds ?? undefined}
      >
        <ContextMenu
          onShowInformation={showInformations}
          onMiniExpertOpen={openMiniExpert}
          xpos={reduxProps.xpos}
          ypos={reduxProps.ypos}
          definition={reduxProps.definition}
          origineTableName={reduxProps.origineTableName} // table d'origine pour calcul de l'info
          columnName={reduxProps.columnName}
          launchProcessFromRightClick={onLaunchProcessFromRightClick}
          launchEditionFromRightClick={onLaunchEditionFromRightClick}
          launchNavigation={onLaunchNavigation}
          hideContextMenu={onHideContextMenu}
          genericEntity={reduxProps.genericEntity}
          launchProcessFromGenericList={onLaunchProcessFromGenericList}
          forceNewTab={reduxProps.forceNewTab}
        />
      </ProcessusProvider>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state: ReducerState) {
  const show = state.contextMenu.show;
  const xpos = state.contextMenu.xpos;
  const ypos = state.contextMenu.ypos;
  const sjmoCode = state.contextMenu.sjmoCode;
  const contextTableName = state.processus.contextTableName;
  const columnName = state.contextMenu.columnName;
  const definition = state.contextMenu.definition;
  const origineTableName = state.contextMenu.origineTableName;
  const origineTableId = state.contextMenu.origineTableId;
  const contextIds = state.processus.contextIds;
  const genericEntity = state.contextMenu.genericEntity;
  const forceNewTab = state.contextMenu.forceNewTab;

  return {
    show,
    definition,
    xpos,
    ypos,
    sjmoCode,
    contextTableName,
    columnName,
    origineTableName,
    origineTableId,
    contextIds,
    genericEntity,
    forceNewTab
  };
}

export default OctalContextMenu;
